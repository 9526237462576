.item[data-v-e1260a39] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  padding-bottom: 0.9375rem;
}
.dark .item[data-v-e1260a39] {
  background: #3b435e;
}
.header[data-v-e1260a39] {
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 2.0625rem;
  text-align: center;
  color: #f6f7ff;
  padding: 0.5rem 0;
  width: 100%;
  margin-bottom: 0.6875rem;
  text-transform: capitalize;
}
.dark .header[data-v-e1260a39] {
  color: #353c53;
}
.name[data-v-e1260a39],
.query[data-v-e1260a39] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;

  margin-top: 0.6875rem;
}
.name[data-v-e1260a39] {
  margin-top: 0.125rem;
  padding: 0 0.5rem;
}
.query[data-v-e1260a39] {
  margin-bottom: 0;
}
.value[data-v-e1260a39],
.sku[data-v-e1260a39] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
}
.query[data-v-e1260a39] {
  margin-right: 0.625rem;
}
.price[data-v-e1260a39] {
  font-size: 1.25rem;
  line-height: 1.875rem;

  margin-top: 0.25rem;
}
.my-5[data-v-e1260a39] {
  margin-top: 0.3125rem;
}
.incart[data-v-e1260a39] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #e78a47;
  background: #f6f7ff;
  box-shadow: inset 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3125rem;
  width: 9.0625rem;
  text-align: center;
  padding-top: 0.1875rem;
  padding-bottom: 0.25rem;
  margin-top: 0.5rem;
}
.dark .incart[data-v-e1260a39] {
  background: #353c53;
}
.addcart[data-v-e1260a39] {
  margin-top: 0.5rem;
}
.select[data-v-e1260a39] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mt-0[data-v-e1260a39] {
  margin-top: 0 !important;
}
.max[data-v-e1260a39] {
  max-width: 100px;
  width: 100%;
}
.ta-l[data-v-e1260a39] {
  text-align: left !important;
}
.shoeimg[data-v-e1260a39] {
  margin-top: 0.625rem;
  height: 3.9375rem;
  border-radius: 0.4375rem;
  margin-bottom: 0.625rem;
  -webkit-filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  /* width: 7.375rem;
  object-fit: fill; */
}
