.page-container[data-v-c128b475] {
  display: flex;
  justify-content: center;
}
.preview[data-v-c128b475] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.bill-container[data-v-c128b475] {
  max-width: 21.5625rem;
  margin: auto;
  width: 100%;
}
.bill[data-v-c128b475] {
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 1.25rem 1.5625rem;
  padding-bottom: 0.625rem;
}
.dark .bill[data-v-c128b475] {
  background-color: #3b435e;
}
.logo img[data-v-c128b475] {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: none;
}
.logo[data-v-c128b475] {
  display: flex;
  justify-content: center;
  height: 8rem;
  width: 8rem;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  margin-bottom: 0.9375rem;
  background: #4b4e93;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.storename[data-v-c128b475] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  margin-bottom: 0.625rem;
}
.twogrid[data-v-c128b475] {
  display: flex;
  justify-content: space-between;
}
.twogrid > div[data-v-c128b475] {
  max-width: 50%;
}
.right[data-v-c128b475] {
  text-align: right;
}
.staffid[data-v-c128b475] {
  margin-top: 1.875rem;
  margin-bottom: 0.9375rem;
}
.twogrid[data-v-c128b475] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.smheader[data-v-c128b475] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
  margin-bottom: 0.625rem;
}
.border[data-v-c128b475] {
  height: 1px;
  width: 100%;
  margin-bottom: 0.9375rem;
  margin-top: 0.9375rem;
  background-image: linear-gradient(to right, transparent 50%, #474747 50%);
  background-size: 10px 100%;
}
.dark .border[data-v-c128b475] {
  background-image: linear-gradient(to right, transparent 50%, #f6f7ff 50%);
}
.mt-10[data-v-c128b475] {
  margin-top: 0.625rem;
}
.type[data-v-c128b475] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 400;
}
.items .twogrid[data-v-c128b475] {
  margin-bottom: 0.625rem;
  align-items: flex-end;
}
.items .right[data-v-c128b475] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.pricing .twogrid[data-v-c128b475] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.pricing .twogrid[data-v-c128b475]:not(:last-child) {
  margin-bottom: 0.3125rem;
}
.thankyou[data-v-c128b475] {
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  max-width: 145px;
  margin: auto;
  text-align: center;
}
.site[data-v-c128b475],
.link[data-v-c128b475] {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}
.site[data-v-c128b475] {
  margin-bottom: 0.4375rem;
}
.site img[data-v-c128b475],
.link svg[data-v-c128b475] {
  margin-right: 0.3125rem;
  height: 1.125rem;
  width: 1.125rem;
}
.jc-sb[data-v-c128b475] {
  justify-content: space-between;
}
.flex[data-v-c128b475] {
  display: flex;
}
.al-ce[data-v-c128b475] {
  align-self: center;
}
.jc-sb[data-v-c128b475] {
  justify-content: space-between;
}
.custom[data-v-c128b475] {
  max-width: 345px;
}
