.items[data-v-d742d77b] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(23.6875rem, 1fr));
  grid-gap: 2rem;
}
.item[data-v-d742d77b] {
  min-height: 24.5625rem;
}
.items-container[data-v-d742d77b] {
  padding-top: 0.5rem;
  max-height: 85vh;
  overflow-x: auto;
  padding-bottom: 2rem;
}
.threegrid[data-v-d742d77b] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 19.5625rem 26.25rem;
  grid-gap: 1.8125rem;
}
.box[data-v-d742d77b] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.span-2[data-v-d742d77b] {
  grid-column: span 2;
}
.twogrid[data-v-d742d77b] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
