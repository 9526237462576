.twogrid[data-v-2f3fde32] {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
}
.query[data-v-2f3fde32] {
  font-weight: 500;
  margin-right: 0.3125rem;
}
.flex[data-v-2f3fde32] {
  margin-bottom: 0.625rem;
}
.staff-details[data-v-2f3fde32] {
  padding-left: 1.6875rem;
  padding-right: 0.75rem;
  margin-top: 1rem;
}
.value[data-v-2f3fde32] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 300;
}
.grid[data-v-2f3fde32] {
  display: grid;
  grid-template-columns: 6.5rem 1fr;
  grid-gap: 0.5rem;
  margin-bottom: 0.25rem;
}
.work[data-v-2f3fde32] {
  padding-left: 1.6875rem;
  padding-right: 0.75rem;
}
.bold[data-v-2f3fde32] {
  font-weight: 500;
}
.yellow[data-v-2f3fde32] {
  color: #fcbe85;
}
.red[data-v-2f3fde32] {
  color: #fa7676;
}
.three-grid[data-v-2f3fde32] {
  display: grid;
  font-weight: 300 !important;
  grid-template-columns: 2.5rem 3rem 3.125rem;
}
.left-grid[data-v-2f3fde32] {
  display: grid;
  grid-template-columns: 12rem 3rem;
  margin-right: 1rem;
}
.query[data-v-2f3fde32] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
