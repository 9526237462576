th[data-v-a0d26483] {
  background-color: #f6f7ff;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  border: none;
}
.dark th[data-v-a0d26483] {
  background-color: #323a52;
}
table[data-v-a0d26483] {
  border-spacing: 0 0.3125rem;
}
th[data-v-a0d26483] {
  padding: 0;
}
td[data-v-a0d26483] {
  padding: 0.3125rem 0;
  max-width: 10ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-weight: 300;
}
td[data-v-a0d26483]:first-child {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
td[data-v-a0d26483]:last-child {
  padding-right: 0.625rem;
}
td[data-v-a0d26483]:nth-child(3) {
  padding-right: 1.425rem;
}
tr[data-v-a0d26483] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
}
.dark tr[data-v-a0d26483] {
  background-color: #3b435e;
  color: #f6f7ff;
}
.dark td[data-v-a0d26483],
.dark th[data-v-a0d26483] {
  color: #f6f7ff;
}
.shadow-none[data-v-a0d26483] {
  box-shadow: none !important;
}
.dark .shadow-none[data-v-a0d26483] {
  background-color: #323a52;
}
.table[data-v-a0d26483] {
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.625rem;
  margin: 0 0.625remq;
}
.sku img[data-v-a0d26483] {
  max-width: 3.9375rem;
  height: auto;
}
.dark .sku img[data-v-a0d26483] {
  max-width: none;
  width: 100%;
  height: 2.8125rem;
  object-fit: contain;
  background-color: #fff;
  border-radius: 0.3125rem;
}
td[data-v-a0d26483] {
  border-bottom: 0;
}
tr td[data-v-a0d26483]:first-child {
  border-top-left-radius: 0.4375rem;
}
tr td[data-v-a0d26483]:last-child {
  border-top-right-radius: 0.4375rem;
}
tr td[data-v-a0d26483]:first-child {
  border-bottom-left-radius: 0.4375rem;
}
tr td[data-v-a0d26483]:last-child {
  border-bottom-right-radius: 0.4375rem;
}
tr th[data-v-a0d26483]:first-child {
  padding-left: 1rem;
}
.capitalize[data-v-a0d26483] {
  text-transform: capitalize;
}
.green[data-v-a0d26483] {
  color: #8FF093;
}