.preview[data-v-94e904b3] {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}
.header[data-v-94e904b3] {
  padding: 1.25rem 2.25rem 0 1.25rem;
}
.sm_header[data-v-94e904b3] {
  top: 1.25rem;
  font-weight: 400;
  font-size: 1.125rem;
}
.order_id[data-v-94e904b3] {
  font-size: 1rem;
  font-weight: 300;
}
.cart[data-v-94e904b3] {
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex-basis: 49%;
}
.items[data-v-94e904b3] {
  padding: 0 0 0 1.25rem;
  padding-right: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 0;
  overflow-y: auto;
  padding-top: 0.5rem;
}
.item[data-v-94e904b3] {
  display: grid;
  grid-template-columns: 1fr 5rem;
  align-items: center;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 0.625rem 1.25rem 0.625rem 0.625rem;
  margin-bottom: 0.3125rem;
}
.btns svg[data-v-94e904b3] {
  cursor: pointer;
}
.amt[data-v-94e904b3] {
  margin: 0 1.25rem;
}
.dark .item[data-v-94e904b3] {
  background: #353c53;
}
.info[data-v-94e904b3] {
  font-weight: 300;
  font-size: 0.875rem;
}
.sub_total[data-v-94e904b3] {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  padding: 1rem;
  border-top: 1px solid #abafd1;
}
.bold[data-v-94e904b3] {
  font-weight: 600;
}
.total[data-v-94e904b3] {
  font-size: 1.5rem;
}
.details[data-v-94e904b3] {
  padding: 0 0 0 1.25rem;
}
.purple[data-v-94e904b3] {
  color: #767bfa;
  font-weight: 500;
}
.suggestion[data-v-94e904b3] {
  display: grid;
  flex-basis: 49%;
  grid-template-rows: auto auto 1fr;
}
.horizontal_scroll[data-v-94e904b3] {
  display: flex;
  min-width: 0;
  overflow-x: auto;
  flex-grow: 0;
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-bottom: 1rem;
}
.horizontal_scroll[data-v-94e904b3]::-webkit-scrollbar {
  height: 0.1875rem;
  cursor: pointer;
}
.rec_item[data-v-94e904b3] {
  box-shadow: 0rem 0rem 0.4375rem rgba(0, 0, 0, 0.15);
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  height: 15.625rem;
  min-width: 23.75rem;
  justify-content: space-between;
  padding: 1rem;
  padding-left: 0rem;
}
.rec_item[data-v-94e904b3]:not(:first-child) {
  margin-left: 1.25rem;
}
.dark .rec_item[data-v-94e904b3] {
  background: #353c53;
}
.shoeimg[data-v-94e904b3] {
  height: 9.0625rem;
}
.shoename[data-v-94e904b3] {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.625rem;
  line-height: 1.875rem;
}
.svg[data-v-94e904b3] {
  float: right;
  margin-top: 1rem;
}
.model[data-v-94e904b3] {
  font-size: 1.125rem;
  font-weight: 300;
  max-width: 18ch;
}
.more[data-v-94e904b3] {
  padding-right: 1rem;
}
.more_container[data-v-94e904b3] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 1rem;
}
.dark .more_container[data-v-94e904b3] {
  background: #353c53;
}
.showitem[data-v-94e904b3] {
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
  text-align: center;
}
.item_heaeder[data-v-94e904b3] {
  margin-bottom: 0.75rem;
}
.custom[data-v-94e904b3] {
  padding: 0.5rem 0;
}
.pl-0[data-v-94e904b3] {
  padding-left: 0 !important;
}
.border[data-v-94e904b3] {
  width: 100%;
  border: 0.125rem solid #767bfa;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  margin: 0.75rem 0;
}
.w-100[data-v-94e904b3] {
  width: 100%;
  padding: 0 1rem;
}
.jc-fe[data-v-94e904b3] {
  justify-content: flex-end;
}
.text[data-v-94e904b3] {
  margin-left: 0.625rem;
}
