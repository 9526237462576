.items .item[data-v-29f42ef9],
.headersgrid[data-v-29f42ef9] {
  display: grid;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  grid-template-columns: 1fr 1.2fr 1.2fr 1fr 1.2fr 1fr 1fr;
  grid-gap: 0.625rem;
}
.items .item[data-v-29f42ef9] {
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.items .item[data-v-29f42ef9]:hover {
  border-color: #767bfa;
}
.active-item[data-v-29f42ef9] {
  border-color: #767bfa !important;
}
.check-details .item[data-v-29f42ef9],
.check-details .headersgrid[data-v-29f42ef9] {
  grid-template-columns: 1fr 1fr 1fr;
}
.headersgrid[data-v-29f42ef9] {
  padding-left: calc(0.9375rem + 0.75rem);
  padding-right: calc(1.6875rem + 0.75rem);
  font-weight: 500;
}
.items[data-v-29f42ef9] {
  padding-left: 0.9375rem;
  padding-right: 0.6875rem;
  padding-bottom: 0.625rem;
  padding-top: 0.3125rem;
}
.item[data-v-29f42ef9] {
  font-weight: 300;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.8125rem 0.75rem;
  margin-top: 0.3125rem;
}
.dark .item[data-v-29f42ef9] {
  background: #3b435e;
}
.items[data-v-29f42ef9] {
  max-height: 42rem;
  overflow-y: auto;
  margin-right: 1rem;
}
.item div[data-v-29f42ef9],
.headersgrid div[data-v-29f42ef9] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.check-details .items[data-v-29f42ef9] {
  height: 12.5rem;
}
.center[data-v-29f42ef9] {
  text-align: center;
  transform: translateX(-10%);
  -webkit-transform: translateX(-10%);
  -moz-transform: translateX(-10%);
  -ms-transform: translateX(-10%);
  -o-transform: translateX(-10%);
}
