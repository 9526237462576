.flex[data-v-92b92510] {
  display: grid;
  grid-template-columns: 1fr 14.5rem;
}
.row[data-v-92b92510] {
  display: flex;
}
.al-ce[data-v-92b92510] {
  align-items: center;
}
.jc-sb[data-v-92b92510] {
  justify-content: space-between;
}
.smbold[data-v-92b92510] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;

  padding: 1.25rem 1.5rem;
  border-top: 0.0625rem solid #abafd1;
}
.paybtn[data-v-92b92510] {
  background: #76e374;
  border-radius: 0.3125rem;
  width: 10.0625rem;
  height: 3.5625rem;
  margin: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bold[data-v-92b92510] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.check[data-v-92b92510] {
  font-size: 1rem;
  margin-left: 1.2rem;
  cursor: pointer;
}
