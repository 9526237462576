.loader[data-v-e7a3f9fa] {
  display: flex;
  align-items: center;
}
.loader img[data-v-e7a3f9fa] {
  width: 1.25rem;
  height: 1.25rem;
}
.loading[data-v-e7a3f9fa] {
  animation: loading-data-v-e7a3f9fa linear 0.2s infinite;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation: loading-data-v-e7a3f9fa linear 0.2s infinite;
}
@-webkit-keyframes loading-data-v-e7a3f9fa {
0% {
}
100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}
}
@keyframes loading-data-v-e7a3f9fa {
0% {
}
100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}
}
