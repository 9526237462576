.bold[data-v-bde0efcd] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.container[data-v-bde0efcd] {
  padding: 0 1.25rem;
}
.center[data-v-bde0efcd] {
  text-align: center;
  margin-top: 3.375rem;
  margin-bottom: 5rem;
}
.twogrid[data-v-bde0efcd] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
}
.btn[data-v-bde0efcd] {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding: 0.875rem 0;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.dark .btn[data-v-bde0efcd] {
  background: #3b435e;
}
.btn[data-v-bde0efcd]:hover {
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.2);
}
.green[data-v-bde0efcd] {
  color: #12c519;
}
