.headers-grid[data-v-f2ada52d] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid.listed[data-v-f2ada52d] {
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
}
.item-grid[data-v-f2ada52d] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid.listed[data-v-f2ada52d] {
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
}
.dark .item-grid[data-v-f2ada52d] {
  background: #3b435e;
}
.item-grid[data-v-f2ada52d]:hover {
  border-color: #767bfa;
}
.item-grid div[data-v-f2ada52d],
.headers-grid div[data-v-f2ada52d] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-grid svg[data-v-f2ada52d] {
  cursor: pointer;
}
.green[data-v-f2ada52d] {
  color: #12c519;
}
.date[data-v-f2ada52d] {
  white-space: break-spaces !important;
  text-align: right !important;
  color: #767bfa;
}
.items-container[data-v-f2ada52d] {
  max-height: 41.15rem;
  overflow-y: auto;
  margin-right: 0.625rem;
}
.items[data-v-f2ada52d] {
  padding: 0 1rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
}
.capitalize[data-v-f2ada52d] {
  text-transform: capitalize;
}
.red[data-v-f2ada52d] {
  color: rgb(250, 118, 118);
}
.consignee[data-v-f2ada52d] {
  grid-template-columns: 1fr 1fr 2fr 1.8fr 1.5fr 1.5fr 1.5fr 1.25fr 1.2fr 1fr;
  grid-gap: 0.375rem;
}
.consignee-summary[data-v-f2ada52d] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.45fr !important;
  grid-gap: 0.375rem;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}
.expense-grid[data-v-f2ada52d] {
  grid-template-columns: repeat(9, 1fr) !important;
}
.items .btnWrap[data-v-f2ada52d] {
  overflow: visible !important;
}
.purple[data-v-f2ada52d] {
  color: #767bfa;
}
.headers-grid-listed[data-v-f2ada52d] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid-listed.listed[data-v-f2ada52d] {
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
}
.item-grid-listed[data-v-f2ada52d] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid-listed.listed[data-v-f2ada52d] {
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
}
