.header[data-v-3484128c] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-3484128c] {
  cursor: pointer;
}
.input[data-v-3484128c] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-3484128c] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-3484128c] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-3484128c] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-3484128c] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-3484128c] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-3484128c] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-3484128c] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-3484128c] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-3484128c] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-3484128c] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-3484128c] {
  font-size: 1.125rem;
}
.readOnly[data-v-3484128c] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-3484128c] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-3484128c] {
  grid-column: span 2;
}
.breakup[data-v-3484128c] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-3484128c] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-3484128c] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-3484128c] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-3484128c] {
  justify-content: space-evenly;
}
.jc-sb[data-v-3484128c] {
  justify-content: space-between;
}
.btns[data-v-3484128c] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-3484128c] {
  padding: 0 1.25rem;
}
.pt-10[data-v-3484128c] {
  padding-top: 0.625rem;
}
.table-container[data-v-3484128c] {
  margin: 0 0.625rem;
}
.span-2[data-v-3484128c] {
  grid-column: 2/4;
}
.green[data-v-3484128c] {
  color: #12c519;
}
.mb-20[data-v-3484128c] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-3484128c] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-3484128c] {
  padding: 0 1.25rem;
}
.lg-header[data-v-3484128c] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-3484128c] {
  cursor: pointer;
}
.search-input-cm[data-v-3484128c] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-3484128c] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-3484128c],
.sm-item-grid[data-v-3484128c] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-3484128c] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-3484128c] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-3484128c] {
  background: #3b435e;
}
.sm-items[data-v-3484128c] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-3484128c] {
  background: #3b435e;
}
.col-grid[data-v-3484128c] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-3484128c] {
  font-weight: 500;
}
.list-item[data-v-3484128c] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-3484128c] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-3484128c] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-3484128c] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-3484128c] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
