.headers-grid[data-v-2d7be8ba] {
  display: grid;
  grid-template-columns: 0.75fr 0.5fr 0.3fr 0.5fr 1.25fr 0.50fr 0.35fr 0.25fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.item-grid[data-v-2d7be8ba] {
  display: grid;
  grid-template-columns: 0.75fr 0.5fr 0.3fr 0.5fr 1.25fr 0.50fr 0.35fr 0.25fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  height: 3.125rem;
  align-items: center;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.dark .item-grid[data-v-2d7be8ba] {
  background: #3b435e;
}
.item-grid[data-v-2d7be8ba]:hover {
  border-color: #767bfa;
}
.item-grid div[data-v-2d7be8ba],
.headers-grid div[data-v-2d7be8ba] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-grid svg[data-v-2d7be8ba] {
  cursor: pointer;
}
.green[data-v-2d7be8ba] {
  color: #12c519;
}
.items-container[data-v-2d7be8ba] {
  max-height: 41.15rem;
  overflow-y: auto;
  margin-right: 0.625rem;
}
.items[data-v-2d7be8ba] {
  padding: 0 1rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
}
.capitalize[data-v-2d7be8ba] {
  text-transform: capitalize;
}
.red[data-v-2d7be8ba] {
  color: rgb(250, 118, 118);
}
.left[data-v-2d7be8ba] {
  text-align: left !important;
}
