.name[data-v-5dfc8c76] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.twogrid[data-v-5dfc8c76] {
  display: grid;
  grid-template-columns: auto 1fr;
}
.twogrid.equal[data-v-5dfc8c76] {
  grid-template-columns: 1fr 1fr;
}
.shoeimg[data-v-5dfc8c76] {
  width: 10.875rem;
  height: 5.8125rem;
  object-fit: contain;
  margin-right: 1.625rem;
  box-shadow: 0rem 0rem 0.75rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  background-color: #fff;
}
.flex[data-v-5dfc8c76] {
  display: flex;
  align-items: center;
}
.ml-4[data-v-5dfc8c76] {
  margin-left: 3rem;
}
.label[data-v-5dfc8c76] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;
  white-space: nowrap;
  margin-right: 0.625rem;
}
.value[data-v-5dfc8c76] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.px-20[data-v-5dfc8c76] {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.mb-10[data-v-5dfc8c76] {
  margin-bottom: 0.6875rem !important;
}
.consignment .mb-10[data-v-5dfc8c76] {
  margin-bottom: 1.125rem !important;
}
.pt-4[data-v-5dfc8c76] {
  padding-top: 0.5rem;
}
.green[data-v-5dfc8c76] {
  color: #12c519;
}
.red[data-v-5dfc8c76] {
  color: #fa7676;
}
.capit[data-v-5dfc8c76] {
  text-transform: capitalize;
}
