.productinfo[data-v-e86fcc47] {
  text-align: center;
  height: 100%;
  padding: 1.25rem 1.625rem 0 1.25rem;
  border-radius: 0.9375rem;
}
.categories[data-v-e86fcc47] {
  padding: 1.25rem 1.625rem 0 1.25rem;
}
.headersection[data-v-e86fcc47] {
  position: relative;
}
.headersection[data-v-e86fcc47]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: -0.25rem;
  background-image: url("../../assets/dash.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-e86fcc47]::before {
  background-image: url("../../assets/dashdark.png");
}
.blur[data-v-e86fcc47] {
  -webkit-filter: blur(0.375rem);
          filter: blur(0.375rem);
  -webkit-user-select: none;
          user-select: none;
}
.smtext[data-v-e86fcc47] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.shoeimg[data-v-e86fcc47] {
  width: 10.9375rem !important;
  height: 5.9375rem !important;
  object-fit: contain !important;
  border-radius: 0.9375rem !important;
  background-color: #fff;
  margin-top: 0.75rem !important;
  margin-bottom: 0.25rem !important;
  -webkit-border-radius: 0.9375rem !important;
  -moz-border-radius: 0.9375rem !important;
  -ms-border-radius: 0.9375rem !important;
  -o-border-radius: 0.9375rem !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.mb-comparing[data-v-e86fcc47] {
  margin-bottom: 0.5rem;
}
.bold[data-v-e86fcc47] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.mt-10[data-v-e86fcc47] {
  margin-top: 0.625rem;
}
.query[data-v-e86fcc47] {
  margin-right: 0.3125rem;
}
.large .query[data-v-e86fcc47],
.large .value[data-v-e86fcc47] {
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.pricing[data-v-e86fcc47] {
  text-align: center;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.red[data-v-e86fcc47],
.green[data-v-e86fcc47],
.blue[data-v-e86fcc47] {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.brown[data-v-e86fcc47] {
  background-color: #d07979;
}
.light-brown[data-v-e86fcc47] {
  background-color: #d0a879;
}
.light-green[data-v-e86fcc47] {
  background-color: #aad079;
}
.light-blue[data-v-e86fcc47] {
  background-color: #79c1d0;
}
.dark-brown[data-v-e86fcc47] {
  background-color: #b47467;
}
.pink[data-v-e86fcc47] {
  background-color: #d079c8;
}
.transaction-history .red[data-v-e86fcc47],
.transaction-history .green[data-v-e86fcc47] {
  font-weight: 300 !important;
  font-size: 0.9375rem;
}
.site[data-v-e86fcc47] {
  margin-bottom: 2rem;
}
.brand[data-v-e86fcc47] {
  width: 6rem;
  height: 3.25rem;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  object-fit: contain;
  padding: 0.1875rem;
}
.bids[data-v-e86fcc47] {
  margin-top: 1.0625rem;
  margin-bottom: 0.9375rem;
}
.al-st[data-v-e86fcc47] {
  align-items: flex-start;
}
.new[data-v-e86fcc47] {
  -webkit-backdrop-filter: blur(0.5625rem);
          backdrop-filter: blur(0.5625rem);
  position: absolute;
  top: -0.3125rem;
  left: -0.3125rem;
  right: -0.3125rem;
  bottom: -0.3125rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  align-items: center;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.comparing[data-v-e86fcc47] {
  position: relative;
  padding: 1.25rem 1.625rem 0 1.25rem;
  height: 100%;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.b-shadow[data-v-e86fcc47] {
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
}
.value[data-v-e86fcc47] {
  font-weight: 300;
  font-size: 0.9375rem;
}
.large .value[data-v-e86fcc47] {
  font-weight: 400;
}
.categories-grid[data-v-e86fcc47] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.625rem 0.3125rem;
  text-align: center;
}
.blue[data-v-e86fcc47] {
  background: #7983d0;
}
.lightblue[data-v-e86fcc47] {
  background: #79c1d0;
}
.yellow[data-v-e86fcc47] {
  background: #d0bd79;
}
.grey[data-v-e86fcc47] {
  background: #abafd1;
}
.smbox[data-v-e86fcc47] {
  box-shadow: 0rem 0rem 0.375rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  font-weight: 300 !important;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #ffffff !important;
  height: 2.5rem;
}
.text-center[data-v-e86fcc47] {
  text-align: center;
  margin-bottom: 1rem;
}
.categories-section[data-v-e86fcc47] {
  margin-top: 2rem;
}
.swiper[data-v-e86fcc47] {
  width: 100%;
  height: 100%;
}
.swiper-slide[data-v-e86fcc47] {
  text-align: center;
  font-size: 1.125rem;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.6875rem;
}
.swiper-slide img[data-v-e86fcc47] {
  display: block;
  width: 100%;
  cursor: pointer;
  height: auto;
  object-fit: contain;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.trending[data-v-e86fcc47] {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  border-radius: 0.9375rem;
  overflow: hidden;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.pl[data-v-e86fcc47] {
  margin-left: 1.25rem;
  padding-top: 0.8125rem;
  padding-left: 0 !important  ;
}
.priceheader[data-v-e86fcc47] {
  margin-left: 1.25rem;
  padding-right: 1.1875rem;
  padding-top: 0.625rem;
  margin-bottom: 1.25rem;
}
.priceheader.price-history[data-v-e86fcc47] {
  margin-bottom: 2.2rem;
}
.twogrid[data-v-e86fcc47] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 2.125rem;
}
.twogrid.smgrid[data-v-e86fcc47] {
  padding: 0 !important;
  grid-gap: 1.25rem;
  margin-top: 1.25rem;
}
.shadow[data-v-e86fcc47] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding: 0.875rem 1.375rem;
}
.dark .shadow[data-v-e86fcc47] {
  background: #3b435e;
}
.shadow .bold[data-v-e86fcc47] {
  margin-bottom: 0.3125rem;
}
.mt-10[data-v-e86fcc47] {
  margin-top: 0.625rem !important;
}
.details[data-v-e86fcc47] {
  margin-left: 1.5625rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.type[data-v-e86fcc47] {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.cond[data-v-e86fcc47] {
  margin-left: 0.625rem;
}
.headersgrid[data-v-e86fcc47],
.item[data-v-e86fcc47] {
  display: grid;
  grid-template-columns: 1fr 1fr 1.1fr 0.8fr;
  align-items: center;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .item[data-v-e86fcc47] {
  background-color: #3b435e;
}
.headersgrid div[data-v-e86fcc47],
.item div[data-v-e86fcc47] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.visible[data-v-e86fcc47] {
  overflow: visible !important;
}
.transaction-history .item img[data-v-e86fcc47] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.item[data-v-e86fcc47] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 14px 17px;
  margin-bottom: 0.3125rem;
  font-weight: 300;
}
.headersgrid[data-v-e86fcc47] {
  padding: 0 1.0625rem;
  font-weight: 500;
}
.transaction-history .headersgrid[data-v-e86fcc47] {
  padding: 0 2rem;
}
.transaction-history .item[data-v-e86fcc47] {
  padding: 0.375rem 0.625rem;
}
.transaction-history .headersgrid[data-v-e86fcc47],
.transaction-history .item[data-v-e86fcc47] {
  display: grid;
  grid-template-columns: 3.9375rem 1fr 0.4fr 0.6fr !important;
  grid-gap: 0.625rem !important;
}
.dark .transaction-history .item[data-v-e86fcc47] {
  background-color: #3b435e;
}
.pricing .blue[data-v-e86fcc47] {
  background: transparent !important;
}
.items[data-v-e86fcc47] {
  max-height: 18.85rem;
  overflow-y: auto;
  padding: 0.75rem 1rem;
  margin-right: 0.375rem;
  padding-right: 0.375rem;
}
.transaction-history .items[data-v-e86fcc47] {
  max-height: 41.35rem;
}
.transaction-history-owner .items[data-v-e86fcc47] {
  max-height: 20rem !important;
}
.headerscotainer[data-v-e86fcc47] {
  padding: 0 1rem;
}
.mt-15[data-v-e86fcc47] {
  margin-bottom: 0.3rem;
}
.mt-0[data-v-e86fcc47] {
  margin-top: 0 !important;
}
.mt-18[data-v-e86fcc47] {
  margin-top: 1.125rem !important;
}
.home-categories[data-v-e86fcc47] {
  padding: 1rem 1.125rem;
}
.home-categories .query[data-v-e86fcc47],
.home-categories .value[data-v-e86fcc47] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pr-10[data-v-e86fcc47] {
  padding-right: 4rem;
}
.staff-overviews .items[data-v-e86fcc47] {
  max-height: 13rem;
}
.item img[data-v-e86fcc47] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.total[data-v-e86fcc47] {
  margin-left: -3.5rem;
  font-weight: 600;
}
.totaltext[data-v-e86fcc47] {
  color: #abafd1;
}
.dark .totaltext[data-v-e86fcc47] {
  color: #5a5d74;
}
.admin-items .headersgrid[data-v-e86fcc47],
.admin-items .item[data-v-e86fcc47] {
  grid-template-columns: 0.5fr 1fr 0.8fr 0.6fr !important;
}
.admin-items .item img[data-v-e86fcc47] {
  margin-left: 0.3125rem;
}
.customer-details .items[data-v-e86fcc47] {
  max-height: 15rem;
}
.customer-details .item[data-v-e86fcc47] {
  grid-template-columns: 1fr 1fr !important;
}
.twogrid-section[data-v-e86fcc47] {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
