.popupbg[data-v-78fea42f] {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup[data-v-78fea42f] {
  background-color: #fff;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
  padding: 1.25rem 1.5rem;
}
.text[data-v-78fea42f] {
  color: #767bfa;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.btn[data-v-78fea42f] {
  background-color: #767bfa;
  color: #fff;
  border: none;
  border-radius: 0.625rem;
  padding: 0.425rem 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 1.25rem;
  margin-bottom: 0.725rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}
