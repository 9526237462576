.summary[data-v-8a4c4bce] {
  display: grid;
  grid-gap: 1.875rem 2.5rem;
  grid-template-rows: 19.5625rem 26.25rem;
  grid-template-columns: 1fr 1fr 1fr;
}
.headersection[data-v-8a4c4bce] {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  padding: 0.8125rem 1.25rem;
  padding-bottom: 0;
}
.headersection[data-v-8a4c4bce]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url("../../assets/dash.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-8a4c4bce]::before {
  background-image: url("../../assets/dashdark.png");
}
.box[data-v-8a4c4bce] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
}
.receipt-container[data-v-8a4c4bce] {
  padding: 0.8125rem 0;
}
.dark .box[data-v-8a4c4bce] {
  background: #353c53;
}
.cashout[data-v-8a4c4bce] {
  grid-column: 3/4;
  grid-row: 1/3;
}
.bold[data-v-8a4c4bce] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  color: #abafd1;
}
.value[data-v-8a4c4bce] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;

  margin-left: 0.3125rem;
}
.span2[data-v-8a4c4bce] {
  grid-column: 1/3;
}
.total[data-v-8a4c4bce] {
  padding-right: 0.875rem;
}
.summary-table[data-v-8a4c4bce] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgb(0 0 0 / 15%);
  border-radius: 0.9375rem;
  padding: 0.8125rem 0;
}
.dark .summary-table[data-v-8a4c4bce] {
  background: #353c53;
}
.purple[data-v-8a4c4bce] {
  font-weight: 500;
  color: #767bfa;
}
.query[data-v-8a4c4bce]:not(:last-child) {
  margin-right: 2.5rem;
}
.query[data-v-8a4c4bce] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.smtext[data-v-8a4c4bce] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.queryvalue[data-v-8a4c4bce] {
  font-weight: 300;

  margin-left: 0.625rem;
}
.green[data-v-8a4c4bce] {
  color: #12c519;
}
.product-items[data-v-8a4c4bce] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: 0.625rem;
}
.product-container[data-v-8a4c4bce] {
  padding-top: 0.625rem;
  max-height: calc(100vh - 10rem);
  overflow-y: scroll;
  padding-right: 1.25rem !important;
  margin-right: 1.25rem;
  padding-bottom: 1.25rem;
}
.pos[data-v-8a4c4bce] {
  display: grid;
  grid-template-columns: 1fr 42.375rem;
  min-height: 50rem;
}
.cart[data-v-8a4c4bce] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.dark .cart[data-v-8a4c4bce] {
  background: #353c53;
}
.pt-0[data-v-8a4c4bce] {
  padding-top: 0 !important;
}
.note[data-v-8a4c4bce] {
  font-weight: 300;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  text-align: center;
  margin-left: 0.425rem;
  color: #767bfa;
}
.headersection[data-v-8a4c4bce] {
  display: flex;
}
.headersection > span[data-v-8a4c4bce]:first-child {
  white-space: nowrap;
}
.amt[data-v-8a4c4bce] {
  font-weight: 300;
  font-size: 1.875rem;
  line-height: 2.8125rem;
}
.subText[data-v-8a4c4bce] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.btn[data-v-8a4c4bce] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  color: #12c519;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.125rem rgba(0, 0, 0, 0.35);
  border-radius: 0.3125rem;
  width: -webkit-max-content;
  width: max-content;
  max-width: 12.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  margin: auto;
  margin-top: 1.6875rem;
  height: 3.125rem;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.walletDetails[data-v-8a4c4bce] {
  padding: 0 3rem;
  padding-top: 0.625rem;
}
.btn[data-v-8a4c4bce]:hover {
  background: #e5e6f2;
}
@media (max-width: 115.625rem) {
.pos[data-v-8a4c4bce] {
    grid-template-columns: 1fr 35rem !important;
}
}
.black[data-v-8a4c4bce] {
  margin-right: 0.3125rem;
  color: #323a52 !important;
}
.dark .black[data-v-8a4c4bce] {
  color: #f6f7ff !important;
}
.itemssold[data-v-8a4c4bce] {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.35rem;
  margin-left: 0.625rem;
}
.al-fe[data-v-8a4c4bce] {
  align-items: flex-end;
}
.px-20[data-v-8a4c4bce] {
  padding: 0 1.25rem;
}
.headers[data-v-8a4c4bce] {
  margin-bottom: 0.875rem;
}
