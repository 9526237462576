.item .shoeimg[data-v-bbdec309] {
  width: 4.125rem;
  height: 2.3125rem;
  object-fit: contain;
  background-color: #fff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.4375rem;
}
.items[data-v-bbdec309] {
  padding: 0 1rem;
  padding-left: 1.5rem;
  padding-right: 0.625rem;
  padding-top: 0.3125rem;
  max-height: 26vh;
  margin-right: 0.625rem;
  overflow-y: scroll;
}
.headers-grid[data-v-bbdec309] {
  padding-right: 0.8rem;
  padding-left: 1.75rem;
  width: calc(100% - 2.25rem);
  margin: auto;
  margin-top: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.item[data-v-bbdec309] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.375rem 0;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;

  position: relative;
  border: 0.0625rem solid transparent;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.6875rem;
}
.dark .item[data-v-bbdec309] {
  background: #3b435e ;
}
.headers-grid[data-v-bbdec309],
.item[data-v-bbdec309] {
  display: grid;
  grid-template-columns: 1fr 1.5fr 0.85fr 1.5fr 1.2fr 24px;
  align-items: center;
}
.headers-grid div[data-v-bbdec309],
.item div[data-v-bbdec309] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.close[data-v-bbdec309] {
  cursor: pointer;
}
