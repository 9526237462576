.header[data-v-dcc7b0e3] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-dcc7b0e3] {
  cursor: pointer;
}
.row[data-v-dcc7b0e3] {
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.three-grid[data-v-dcc7b0e3] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.two-grid[data-v-dcc7b0e3] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.label[data-v-dcc7b0e3] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.jus-end[data-v-dcc7b0e3] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btns[data-v-dcc7b0e3] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-dcc7b0e3] {
  padding: 0 1.25rem;
}
.table-container[data-v-dcc7b0e3] {
  margin: 0 0.625rem;
}
.img-upload[data-v-dcc7b0e3] {
  background: #f6f7ff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.25rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.dark .img-upload[data-v-dcc7b0e3] {
  background-color: #353c53;
}
.img-upload[data-v-dcc7b0e3] {
  cursor: pointer;
}
.text[data-v-dcc7b0e3] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.grey-text[data-v-dcc7b0e3] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-top: 0.75rem;
}
.ticket[data-v-dcc7b0e3] {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  padding: 0.625rem;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}
.ticket[data-v-dcc7b0e3]:hover {
  background: #dfdfdf;
}
.ticket-img[data-v-dcc7b0e3] {
  max-width: 7rem;
  max-height: 7rem;
  width: 100%;
  overflow: hidden;
  object-fit: fill;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
  margin-right: 0.625rem;
}
.search-results[data-v-dcc7b0e3] {
  margin-top: 0.625rem;
  padding: 0 1.25rem;
  max-height: 40rem;
  overflow-y: auto;
}
.ticket-title[data-v-dcc7b0e3] {
  font-size: 1.125rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-description[data-v-dcc7b0e3] {
  font-size: 0.925rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-date[data-v-dcc7b0e3] {
  font-size: 0.9375rem;
  font-weight: 500;
}
.ticket-container[data-v-dcc7b0e3] {
  height: 100%;
}
.ticket-info[data-v-dcc7b0e3] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
}
