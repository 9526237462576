.summary[data-v-f78890e1] {
  display: grid;
  grid-gap: 1.875rem 2.5rem;
  grid-template-rows: 19.5625rem 26.25rem;
  grid-template-columns: 1fr 1fr 1fr;
}
.headersection[data-v-f78890e1] {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding: 0.8125rem 1.25rem;
  padding-bottom: 0;
}
.headersection[data-v-f78890e1]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url("../../assets/dash.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-f78890e1]::before {
  background-image: url("../../assets/dashdark.png");
}
.box[data-v-f78890e1] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
}
.dark .box[data-v-f78890e1] {
  background: #353c53;
}
.bold[data-v-f78890e1] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  color: #abafd1;
}
.value[data-v-f78890e1] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;

  margin-left: 0.3125rem;
}
.span2[data-v-f78890e1] {
  grid-column: 1/3;
}
.total[data-v-f78890e1] {
  padding-right: 0.875rem;
}
.summary-table[data-v-f78890e1] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgb(0 0 0 / 15%);
  border-radius: 0.9375rem;
  padding: 0.8125rem 0;
}
.dark .summary-table[data-v-f78890e1] {
  background: #353c53;
}
.purple[data-v-f78890e1] {
  font-weight: 500;
  color: #767bfa;
}
.query[data-v-f78890e1]:not(:last-child) {
  margin-right: 2.5rem;
}
.query[data-v-f78890e1] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.smtext[data-v-f78890e1] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.queryvalue[data-v-f78890e1] {
  font-weight: 300;

  margin-left: 0.625rem;
}
.green[data-v-f78890e1] {
  color: #12c519;
}
.black[data-v-f78890e1] {
  margin-right: 0.3125rem;
  color: #323a52 !important;
}
.dark .black[data-v-f78890e1] {
  color: #f6f7ff !important;
}
.itemssold[data-v-f78890e1] {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.35rem;
  margin-left: 0.625rem;
}
.al-fe[data-v-f78890e1] {
  align-items: flex-end;
}
.px-20[data-v-f78890e1] {
  padding: 0 1.25rem;
}
.headers[data-v-f78890e1] {
  margin-bottom: 0.875rem;
}
