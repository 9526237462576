.formControl[data-v-2606e703] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  margin: 0px !important;
}
.dark .formControl[data-v-2606e703] {
  background: #353c53;
}
.menuitem[data-v-2606e703] {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: left;
  justify-content: center;
  color: #abafd1;
}
.menuitem[data-v-2606e703]:hover {
  background-color: rgba(118, 123, 250, 0.15) !important;
}
.dark .menuitem[data-v-2606e703] {
  color: #f6f7ff !important;
}
.menuitem.focusVisible[data-v-2606e703] {
  background-color: rgba(118, 123, 250, 0.15) !important;
}
