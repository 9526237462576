.sidebar-container[data-v-ba81e3f7] {
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.sidebar[data-v-ba81e3f7] {
  background-color: #323a52;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 1.875rem;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
}
.dark .sidebar[data-v-ba81e3f7] {
  background-color: #262c41;
}
.sidebar a[data-v-ba81e3f7],
.sidebar div[data-v-ba81e3f7] {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding: 0 1.375rem;
  color: #abafd1;
  white-space: nowrap;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}
.sidebar a[data-v-ba81e3f7]:hover,
.sidebar div[data-v-ba81e3f7]:hover {
  color: #ffffff;
}
.sidebar a[data-v-ba81e3f7],
.sidebar div[data-v-ba81e3f7] {
  margin-top: 3.125rem;
}
.sidebar svg[data-v-ba81e3f7] {
  margin-right: 1.25rem;
}
.logo[data-v-ba81e3f7] {
  width: 11.0625rem;
  padding-left: 1.375rem;
}
.logo img[data-v-ba81e3f7] {
  width: 100%;
}
.sidebar .active-link[data-v-ba81e3f7] {
  color: #ffffff;
}
.sidebar .active-link[data-v-ba81e3f7]::after {
  position: absolute;
  content: "";
  right: 0rem;
  width: 0.75rem;
  height: 1rem;
  background-image: url("../../assets/polygon.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.dark .sidebar .active-link[data-v-ba81e3f7]::after {
  background-image: url("../../assets/polygon1.svg");
}
.active-link .active-white[data-v-ba81e3f7] {
  fill: #fff;
}
