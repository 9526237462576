.receipt-container[data-v-b20c084b] {
  display: grid;
  grid-template-columns: 26.6875rem 26.6875rem 21.5625rem 1fr;
  grid-gap: 1.875rem;
  padding: 0.625rem 1.25rem;
}
.qr-container[data-v-b20c084b] {
  display: grid;
  grid-template-columns: 0.85fr 1fr 1fr;
  grid-gap: 1.875rem;
  padding: 0.625rem 1.25rem;
}
.wrap[data-v-b20c084b] {
  grid-gap: 1.5625rem 3rem;
  display: grid;
  grid-template-columns: repeat(5, 14.0625rem);
  padding: 0 1.25rem;
}
.label[data-v-b20c084b] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.5625rem;
  margin-bottom: 0.75rem;
}
.btncontainer[data-v-b20c084b] {
  grid-column: span 5;
  margin-top: 1.875rem;
  justify-content: flex-end;
}
.mt-custom[data-v-b20c084b] {
  margin-top: 1.25rem;
}
.mr-20[data-v-b20c084b] {
  margin-right: 1.25rem;
}
.color-container[data-v-b20c084b] {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.2rem;
  padding-right: 0.9375rem;
  max-width: 14.0625rem;
  width: 100%;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border: none;
  outline: none;
  height: 3.1875rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  position: relative;
}
.color-container input[data-v-b20c084b] {
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.dark .color-container[data-v-b20c084b] {
  background-color: #3b435e;
}
