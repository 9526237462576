@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

body {
  overflow-x: auto;
  font-family: "Poppins", sans-serif;
}

.App {
  background-color: #f6f7ff;
  color: #323a52;
  overflow-x: auto;
}

.App.dark {
  background-color: #323a52;
  color: #f6f7ff;
}

.main-container {
  min-height: 100vh;
  display: grid;
  grid-template-columns: minmax(12.875rem, -webkit-min-content) 1fr;
  grid-template-columns: minmax(12.875rem, min-content) 1fr;
}

a {
  text-decoration: none;
  font-family: inherit;
}

.page {
  min-width: 1200px;
  padding-right: 1.0625rem;
}

.px {
  padding-left: 3.125rem !important;
  padding-right: 0.9375rem !important;
}

.pl {
  padding-left: 3.125rem !important;
}

.pr {
  padding-right: 0.9375rem !important;
}

input {
  font-family: inherit;
  outline: none;
}

.css-3cjm13-MuiFormControl-root {
  margin: 0rem !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiSelect-select {
  font-family: "Poppins" !important;
  padding: 0.875rem 1.375rem !important;
}

.smselect .MuiSelect-select {
  padding: 0.1875rem 0.75rem !important;
  padding-right: 0 !important;
}

.modal .MuiBackdrop-root.css-sox5kk-MuiBackdrop-root {
  background: rgba(67, 53, 97, 0.22);
  -webkit-backdrop-filter: blur(0.375rem);
          backdrop-filter: blur(0.375rem);
}

.dark.modal .MuiBackdrop-root.css-sox5kk-MuiBackdrop-root {
  background: rgba(24, 22, 37, 0.35);
}

.mt-10 {
  margin-top: 0.625rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.ml-10 {
  margin-left: 0.625rem;
}

.mr-14 {
  margin-right: 0.875rem;
  max-width: 9.0625rem;
  width: 100%;
}

.center {
  text-align: center;
}

.MuiSelect-select[aria-expanded="true"] > img {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

/* width */
::-webkit-scrollbar {
  width: 0.1875rem;
  border-radius: 0.1875rem;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  -ms-border-radius: 0.1875rem;
  -o-border-radius: 0.1875rem;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: #abafd1;
  cursor: pointer;
  border-radius: 0.1875rem;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  -ms-border-radius: 0.1875rem;
  -o-border-radius: 0.1875rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #767bfa;
  cursor: pointer;
  border-radius: 0.1875rem;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  -ms-border-radius: 0.1875rem;
  -o-border-radius: 0.1875rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4d53fd;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.add-btns {
  display: flex;
  margin-bottom: 0.625rem;
}

.flex {
  display: flex;
}

.jc-sp {
  justify-content: space-between;
}

.jc-ce {
  justify-content: center;
}

.jc-e {
  justify-content: end;
}

.al-ce {
  align-items: center;
}

.col {
  flex-direction: column;
}

.ml-5 {
  margin-left: 0.3125rem;
}

.red {
  color: #fa7676 !important;
}

.green {
  color: #12c519;
}

.purple {
  color: #767bfa;
}

.orange {
  color: #d0a879;
}

.blue {
  color: #78aad8;
}

.MuiOutlinedInput-notchedOutline,
.css-igs3ac {
  border: 0rem !important
  ;
}

.Mui-focusVisible {
  background-color: transparent !important;
}

.Mui-selected {
  background-color: rgba(118, 123, 250, 0.2) !important;
}

.sneakers {
  background-color: #7983d0; /* blue */
}

.streetwear {
  background-color: #79c1d0; /* green */
}

.collectibles {
  background-color: #d07979; /* brown */
}

.arts {
  background-color: #d079c8; /* pink */
}

.electronics {
  background-color: #d0a879; /* orange */
}

.watches {
  background-color: #aad079; /* lightgreen */
}

@media (max-width: 1560px) {
  :root {
    font-size: 12.5px;
  }
}

@media (max-width: 1750px) {
  :root {
    font-size: 13.5px;
  }
}

.css-a2af34-MuiTable-root {
  min-width: none !important;
  width: 100%;
}

.recharts-wrapper {
  margin-left: -0.75rem;
}

.price-graph .recharts-wrapper {
  margin-left: -1.25rem;
}

.swiper-button-next {
  background-image: url(/static/media/Right.6c5bb572.svg) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transform: translateX(0.375rem);
  -webkit-transform: translateX(0.375rem);
  -moz-transform: translateX(0.375rem);
  -ms-transform: translateX(0.375rem);
  -o-transform: translateX(0.375rem);
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url(/static/media/Left.a2f5000d.svg) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transform: translateX(-0.375rem);
  -webkit-transform: translateX(-0.375rem);
  -moz-transform: translateX(-0.375rem);
  -ms-transform: translateX(-0.375rem);
  -o-transform: translateX(-0.375rem);
}

.swiper-button-prev::after {
  display: none;
}

.qrprint {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrprint1 {
  position: absolute;
  top: 50%;
  width: 350px;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.qrprint2 {
  width: 350px;
  margin: auto;
}

.print2 {
  display: block;
  height: -webkit-max-content;
  height: max-content;
  margin: auto;
  align-items: center;
}

.print3 {
  display: block;
  height: -webkit-max-content;
  height: max-content;
  margin: auto;
  align-items: center;
}

.cap {
  text-transform: capitalize;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.al-fe {
  align-items: flex-end !important;
}

.lockedimg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  max-height: 100vh;
  overflow: hidden;
  width: 100%;
  object-fit: cover;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #323a52 !important;
}

.dark
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #5a5d74 !important;
}

.dark .menuitem {
  color: #f6f7ff !important;
}

.dark.modal .btn {
  background-color: #3b435e !important;
}

.css-2lkrky-MuiPaper-root-MuiTableContainer-root {
  overflow-x: visible !important;
}

.css-11c9tec-MuiTableCell-root {
  z-index: 0 !important;
}

.sidebar-container[data-v-ba81e3f7] {
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.sidebar[data-v-ba81e3f7] {
  background-color: #323a52;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 1.875rem;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
}
.dark .sidebar[data-v-ba81e3f7] {
  background-color: #262c41;
}
.sidebar a[data-v-ba81e3f7],
.sidebar div[data-v-ba81e3f7] {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding: 0 1.375rem;
  color: #abafd1;
  white-space: nowrap;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}
.sidebar a[data-v-ba81e3f7]:hover,
.sidebar div[data-v-ba81e3f7]:hover {
  color: #ffffff;
}
.sidebar a[data-v-ba81e3f7],
.sidebar div[data-v-ba81e3f7] {
  margin-top: 3.125rem;
}
.sidebar svg[data-v-ba81e3f7] {
  margin-right: 1.25rem;
}
.logo[data-v-ba81e3f7] {
  width: 11.0625rem;
  padding-left: 1.375rem;
}
.logo img[data-v-ba81e3f7] {
  width: 100%;
}
.sidebar .active-link[data-v-ba81e3f7] {
  color: #ffffff;
}
.sidebar .active-link[data-v-ba81e3f7]::after {
  position: absolute;
  content: "";
  right: 0rem;
  width: 0.75rem;
  height: 1rem;
  background-image: url(/static/media/polygon.751adddb.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.dark .sidebar .active-link[data-v-ba81e3f7]::after {
  background-image: url(/static/media/polygon1.c92ca53f.svg);
}
.active-link .active-white[data-v-ba81e3f7] {
  fill: #fff;
}

.header[data-v-84d0301c] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-84d0301c] {
  cursor: pointer;
}
.row[data-v-84d0301c] {
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.three-grid[data-v-84d0301c] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.two-grid[data-v-84d0301c] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.label[data-v-84d0301c] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.jus-end[data-v-84d0301c] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btns[data-v-84d0301c] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-84d0301c] {
  padding: 0 1.25rem;
}
.table-container[data-v-84d0301c] {
  margin: 0 0.625rem;
}
.img-upload[data-v-84d0301c] {
  background: #f6f7ff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.25rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.dark .img-upload[data-v-84d0301c] {
  background-color: #353c53;
}
.img-upload[data-v-84d0301c] {
  cursor: pointer;
}
.text[data-v-84d0301c] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.grey-text[data-v-84d0301c] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-top: 0.75rem;
}
.ticket[data-v-84d0301c] {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  padding: 0.625rem;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}
.ticket[data-v-84d0301c]:hover {
  background: #dfdfdf;
}
.ticket-img[data-v-84d0301c] {
  max-width: 7rem;
  max-height: 7rem;
  width: 100%;
  overflow: hidden;
  object-fit: fill;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
  margin-right: 0.625rem;
}
.search-results[data-v-84d0301c] {
  margin-top: 0.625rem;
  padding: 0 1.25rem;
  max-height: 40rem;
  overflow-y: auto;
}
.ticket-title[data-v-84d0301c] {
  font-size: 1.125rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-description[data-v-84d0301c] {
  font-size: 0.925rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-date[data-v-84d0301c] {
  font-size: 0.9375rem;
  font-weight: 500;
}
.ticket-container[data-v-84d0301c] {
  height: 100%;
}
.ticket-info[data-v-84d0301c] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
}

.btn[data-v-bcda9b75] {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 9.0625rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  width: -webkit-max-content;
  width: max-content;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  min-height: 1.75rem;
  cursor: pointer;
  color: #abafd1;
}
.pad[data-v-bcda9b75] {
  padding: 0 0.9375rem;
  margin-left: auto;
}
.padding-side[data-v-bcda9b75] {
  padding: 0 0.2rem;
}
.dark .btn[data-v-bcda9b75] {
  background-color: #353c53;
}
.text[data-v-bcda9b75] {
  margin-left: 0.625rem;
  font-weight: 400 !important;
}
.mr-14[data-v-bcda9b75] {
  margin-right: 0.875rem;
}
.dark .text[data-v-bcda9b75] {
  color: #5a5d74;
}
.dark .lightbtn[data-v-bcda9b75] {
  background-color: #3b435e !important;
}
.btn[data-v-bcda9b75]:active {
  box-shadow: inset 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.15);
}
.disabled[data-v-bcda9b75] {
  pointer-events: none;
  cursor: not-allowed !important;
}
.mx-auto[data-v-bcda9b75] {
  margin: 0 auto;
}
.dark .lightish[data-v-bcda9b75] {
  background-color: #464f6d;
  color: #5a5d74;
}
.px-1[data-v-bcda9b75] {
  padding: 0 1rem;
}
.priceUpdate-btn[data-v-bcda9b75] {
  min-width: 5.0625rem;
  margin-left: 0.325rem;
}
.priceUpdate-btn span.text[data-v-bcda9b75] {
  margin: 0px !important;
  color: #8ff093;
}
.openPanel-btn[data-v-bcda9b75] {
  max-height: 1.75rem;
}
.openPanel-btn span.text[data-v-bcda9b75] {
  margin: 0px !important;
}
.ask-btn span.text[data-v-bcda9b75] {
  margin: 0px !important;
  color: #767bfa;
}
.ask-btn.asked span.text[data-v-bcda9b75] {
  margin: 0px !important;
  color: #fa7676 !important;
}
.ask-btn[data-v-bcda9b75] {
  min-width: 4.3rem !important;
}
.qr-btn[data-v-bcda9b75] {
  min-width: 3rem;
  padding-right: 0.5rem;
}
.conf-cashout[data-v-bcda9b75] {
  min-width: 11.875rem !important;
}
.cashout[data-v-bcda9b75] {
  box-shadow: 0px 0px 2px rgb(0 0 0 / 35%);
  border-radius: 5px;
  padding: 0.625rem;
}
.cashout span.text[data-v-bcda9b75] {
  margin: 0px !important;
  color: #12c519;
}

/* .asked {
  box-shadow: inset 0rem 0rem 0.5625rem rgb(0 0 0 / 15%) !important;
} */

.input-field[data-v-e19aac1f] {
  background: #f6f7ff;
  box-shadow: inset 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .input-field[data-v-e19aac1f] {
  background: #3b435e;
  color: #f6f7ff;
}
.input-field[data-v-e19aac1f]::-webkit-input-placeholder {
  font-family: inherit;
  font-weight: 300 !important;
  line-height: 1.375rem;
  font-size: 0.9375rem;
  color: #abafd1;
  opacity: 1 !important;
}
.input-field[data-v-e19aac1f]::placeholder {
  font-family: inherit;
  font-weight: 300 !important;
  line-height: 1.375rem;
  font-size: 0.9375rem;
  color: #abafd1;
  opacity: 1 !important;
}
.dark .input-field[data-v-e19aac1f]::-webkit-input-placeholder {
  color: #5a5d74;
}
.dark .input-field[data-v-e19aac1f]::placeholder {
  color: #5a5d74;
}
.sm[data-v-e19aac1f] {
  max-width: 2.8125rem;
  padding: 0;
  width: 100%;
  height: 2.125rem;
  text-align: center;
}
.smtext[data-v-e19aac1f] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding: 0.5625rem 0.75rem !important;
}
.small[data-v-e19aac1f] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 9.0625rem;
  width: 100%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5625rem rgb(0 0 0 / 15%);
  border-radius: 0.3125rem;
  height: 1.75rem;
  padding: 0 0.625rem 0 0.75rem;
}
.dark .small[data-v-e19aac1f] {
  background: #353c53;
}
.large[data-v-e19aac1f] {
  height: 5.0625rem;
  font-weight: 300;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.large[data-v-e19aac1f]::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.large[data-v-e19aac1f]::placeholder {
  font-weight: 300;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.prefix-input[data-v-e19aac1f] {
  padding: 0 !important;
  width: 100%;
  box-shadow: none;
  height: 100%;
}
.prefix[data-v-e19aac1f] {
  font-size: 0.75rem;
  white-space: nowrap;
  margin-right: 0.125rem;
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dark .sm[data-v-e19aac1f],
.dark .lightinput[data-v-e19aac1f] {
  background-color: #464f6d !important;
}
.ask-input-wrap[data-v-e19aac1f] {
  border-radius: 0.4375rem;
  /* padding: 0.875rem 1.375rem; */
  border: 1px solid rgba(50, 58, 82, 0.35);
  width: 100%;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.ask-input-currency[data-v-e19aac1f] {
  font-size: 0.9375rem;
  margin-right: 0.125rem;
  font-weight: 500;
  border-right: 1px solid rgba(50, 58, 82, 0.35);
  padding: 0.625rem 1rem;
}
.dark .ask-input-wrap[data-v-e19aac1f], .dark .ask-input-currency[data-v-e19aac1f] {
  border-color: #F6F7FF59;
  color: #f6f7ff;
}
.ask-input[data-v-e19aac1f] {
  padding-left: 0.625rem;
  font-family: inherit;
  font-weight: 500 !important;
  line-height: 1.375rem;
  font-size: 0.9375rem;
  background-color: inherit;
  border: none;
  width: 100%;
}
.dark .ask-input[data-v-e19aac1f] {
  color: #f6f7ff;
}
.discount[data-v-e19aac1f] {
  max-width: 9.5125rem;
  padding: 0;
  width: 100%;
  height: 1.825rem;
  text-align: center;
}
.loader[data-v-e7a3f9fa] {
  display: flex;
  align-items: center;
}
.loader img[data-v-e7a3f9fa] {
  width: 1.25rem;
  height: 1.25rem;
}
.loading[data-v-e7a3f9fa] {
  animation: loading-data-v-e7a3f9fa linear 0.2s infinite;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation: loading-data-v-e7a3f9fa linear 0.2s infinite;
}
@-webkit-keyframes loading-data-v-e7a3f9fa {
0% {
}
100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}
}
@keyframes loading-data-v-e7a3f9fa {
0% {
}
100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}
}

.label[data-v-f4e0812c] {
  font-weight: 600;
  font-family: "Poppins";
  line-height: 1.125rem;
}
.msg[data-v-f4e0812c] {
  font-size: 0.875rem;
  font-family: "Poppins";
  line-height: 1.125rem;
  margin-top: 0.125rem;
}

.headers-grid[data-v-5019fa27] {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.2fr 0.9fr 1.15fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid.listed[data-v-5019fa27] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr  1.25fr 1.25fr 1.1fr 1.1fr 2.8fr 0.6fr 1fr 0.9fr;
}
.item-grid[data-v-5019fa27] {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.2fr 0.9fr 1.15fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  height: 3.125rem;
  align-items: center;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid.listed[data-v-5019fa27] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr  1.25fr 1.25fr 1.1fr 1.1fr 2.8fr 0.6fr 1fr 0.9fr;
}
.dark .item-grid[data-v-5019fa27] {
  background: #3b435e;
}
.item-grid[data-v-5019fa27]:hover {
  border-color: #767bfa;
}
.item-grid div[data-v-5019fa27],
.headers-grid div[data-v-5019fa27] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-grid svg[data-v-5019fa27] {
  cursor: pointer;
}
.green[data-v-5019fa27] {
  color: #12c519;
}
.items-container[data-v-5019fa27] {
  max-height: 41.15rem;
  overflow-y: auto;
  margin-right: 0.625rem;
}
.items[data-v-5019fa27] {
  padding: 0 1rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
}
.capitalize[data-v-5019fa27] {
  text-transform: capitalize;
}
.red[data-v-5019fa27] {
  color: rgb(250, 118, 118);
}
.consignee[data-v-5019fa27] {
  grid-template-columns: 1fr 1fr 2fr 1.8fr 1.5fr 1.5fr 1.5fr 1.25fr 1.2fr 1fr;
  grid-gap: 0.375rem;
}
.consignee-summary[data-v-5019fa27] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.45fr !important;
  grid-gap: 0.375rem;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}
.expense-grid[data-v-5019fa27] {
  grid-template-columns: repeat(9, 1fr) !important;
}
.items .btnWrap[data-v-5019fa27] {
  overflow: visible !important;
}
.purple[data-v-5019fa27] {
  color: #767BFA;
}
.bold[data-v-bde0efcd] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.container[data-v-bde0efcd] {
  padding: 0 1.25rem;
}
.center[data-v-bde0efcd] {
  text-align: center;
  margin-top: 3.375rem;
  margin-bottom: 5rem;
}
.twogrid[data-v-bde0efcd] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
}
.btn[data-v-bde0efcd] {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding: 0.875rem 0;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.dark .btn[data-v-bde0efcd] {
  background: #3b435e;
}
.btn[data-v-bde0efcd]:hover {
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.2);
}
.green[data-v-bde0efcd] {
  color: #12c519;
}

.header[data-v-2b4dfe74] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-2b4dfe74] {
  cursor: pointer;
}
.row[data-v-2b4dfe74] {
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.three-grid[data-v-2b4dfe74] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.two-grid[data-v-2b4dfe74] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.label[data-v-2b4dfe74] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.jus-end[data-v-2b4dfe74] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btns[data-v-2b4dfe74] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-2b4dfe74] {
  padding: 0 1.25rem;
}
.table-container[data-v-2b4dfe74] {
  margin: 0 0.625rem;
}
.img-upload[data-v-2b4dfe74] {
  background: #f6f7ff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.25rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.dark .img-upload[data-v-2b4dfe74] {
  background-color: #353c53;
}
.img-upload[data-v-2b4dfe74] {
  cursor: pointer;
}
.text[data-v-2b4dfe74] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.grey-text[data-v-2b4dfe74] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-top: 0.75rem;
}
.ticket[data-v-2b4dfe74] {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  padding: 0.625rem;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}
.ticket[data-v-2b4dfe74]:hover {
  background: #dfdfdf;
}
.ticket-img[data-v-2b4dfe74] {
  max-width: 7rem;
  max-height: 7rem;
  width: 100%;
  overflow: hidden;
  object-fit: fill;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
  margin-right: 0.625rem;
}
.search-results[data-v-2b4dfe74] {
  margin-top: 0.625rem;
  padding: 0 1.25rem;
  max-height: 40rem;
  overflow-y: auto;
}
.ticket-title[data-v-2b4dfe74] {
  font-size: 1.125rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-description[data-v-2b4dfe74] {
  font-size: 0.925rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-date[data-v-2b4dfe74] {
  font-size: 0.9375rem;
  font-weight: 500;
}
.ticket-container[data-v-2b4dfe74] {
  height: 100%;
}
.ticket-info[data-v-2b4dfe74] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
}

.header[data-v-a7ccacf7] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-a7ccacf7] {
  cursor: pointer;
}
.input[data-v-a7ccacf7] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-a7ccacf7] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-a7ccacf7] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-a7ccacf7] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-a7ccacf7] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-a7ccacf7] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-a7ccacf7] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-a7ccacf7] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-a7ccacf7] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-a7ccacf7] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-a7ccacf7] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-a7ccacf7] {
  font-size: 1.125rem;
}
.readOnly[data-v-a7ccacf7] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-a7ccacf7] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-a7ccacf7] {
  grid-column: span 2;
}
.breakup[data-v-a7ccacf7] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-a7ccacf7] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-a7ccacf7] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-a7ccacf7] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-a7ccacf7] {
  justify-content: space-evenly;
}
.jc-sb[data-v-a7ccacf7] {
  justify-content: space-between;
}
.btns[data-v-a7ccacf7] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-a7ccacf7] {
  padding: 0 1.25rem;
}
.pt-10[data-v-a7ccacf7] {
  padding-top: 0.625rem;
}
.table-container[data-v-a7ccacf7] {
  margin: 0 0.625rem;
}
.span-2[data-v-a7ccacf7] {
  grid-column: 2/4;
}
.green[data-v-a7ccacf7] {
  color: #12c519;
}
.mb-20[data-v-a7ccacf7] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-a7ccacf7] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-a7ccacf7] {
  padding: 0 1.25rem;
}
.lg-header[data-v-a7ccacf7] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-a7ccacf7] {
  cursor: pointer;
}
.search-input-cm[data-v-a7ccacf7] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-a7ccacf7] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-a7ccacf7],
.sm-item-grid[data-v-a7ccacf7] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-a7ccacf7] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-a7ccacf7] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-a7ccacf7] {
  background: #3b435e;
}
.sm-items[data-v-a7ccacf7] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-a7ccacf7] {
  background: #3b435e;
}
.col-grid[data-v-a7ccacf7] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-a7ccacf7] {
  font-weight: 500;
}
.list-item[data-v-a7ccacf7] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-a7ccacf7] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-a7ccacf7] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-a7ccacf7] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-a7ccacf7] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

.formControl[data-v-005c1d4c] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  margin: 0px !important;
}
.dark .formControl[data-v-005c1d4c] {
  background: #3b435e;
}
.menuitem[data-v-005c1d4c] {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
  justify-content: center;
  color: #abafd1;
}
.dark .menuitem[data-v-005c1d4c] {
  color: #f6f7ff;
}
.menuitem[data-v-005c1d4c]:hover {
  background-color: rgba(118, 123, 250, 0.15) !important;
}
.menuitem.focusVisible[data-v-005c1d4c] {
  background-color: rgba(118, 123, 250, 0.15) !important;
}

th[data-v-ac524a2d] {
  background-color: #f6f7ff;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  border: none;
}
.dark th[data-v-ac524a2d] {
  background-color: #323a52;
}
table[data-v-ac524a2d] {
  border-spacing: 0 0.3125rem;
}
th[data-v-ac524a2d] {
  padding: 0;
}
td[data-v-ac524a2d] {
  padding: 0.3125rem 0;
  max-width: 10ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td[data-v-ac524a2d]:first-child,
td[data-v-ac524a2d]:last-child {
  padding: 0.3125rem 0.625rem;
}
tr[data-v-ac524a2d] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
}
.dark tr[data-v-ac524a2d] {
  background-color: #3b435e;
  color: #f6f7ff;
}
.dark td[data-v-ac524a2d],
.dark th[data-v-ac524a2d] {
  color: #f6f7ff;
}
.shadow-none[data-v-ac524a2d] {
  box-shadow: none !important;
}
.dark .shadow-none[data-v-ac524a2d] {
  background-color: #323a52;
}
.table[data-v-ac524a2d] {
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.625rem;
  margin: 0 0.625remq;
}
.sku img[data-v-ac524a2d] {
  max-width: 3.9375rem;
  height: auto;
}
.dark .sku img[data-v-ac524a2d] {
  max-width: none;
  width: 100%;
  height: 2.8125rem;
  object-fit: contain;
  background-color: #fff;
  border-radius: 0.3125rem;
}
td[data-v-ac524a2d] {
  border-bottom: 0;
}
tr td[data-v-ac524a2d]:first-child {
  border-top-left-radius: 0.4375rem;
}
tr td[data-v-ac524a2d]:last-child {
  border-top-right-radius: 0.4375rem;
}
tr td[data-v-ac524a2d]:first-child {
  border-bottom-left-radius: 0.4375rem;
}
tr td[data-v-ac524a2d]:last-child {
  border-bottom-right-radius: 0.4375rem;
}
tr th[data-v-ac524a2d]:first-child {
  padding-left: 1rem;
}
.capitalize[data-v-ac524a2d] {
  text-transform: capitalize;
}


.headers-grid[data-v-7d056319] {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.2fr 0.9fr 1.15fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid.listed[data-v-7d056319] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr  1.25fr 1.25fr 1.1fr 1.1fr 2.8fr 0.6fr 1fr 0.9fr;
}
.item-grid[data-v-7d056319] {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.2fr 0.9fr 1.15fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  height: 3.125rem;
  align-items: center;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid.listed[data-v-7d056319] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr  1.25fr 1.25fr 1.1fr 1.1fr 2.8fr 0.6fr 1fr 0.9fr;
}
.dark .item-grid[data-v-7d056319] {
  background: #3b435e;
}
.item-grid[data-v-7d056319]:hover {
  border-color: #767bfa;
}
.item-grid div[data-v-7d056319],
.headers-grid div[data-v-7d056319] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-grid svg[data-v-7d056319] {
  cursor: pointer;
}
.green[data-v-7d056319] {
  color: #12c519;
}
.items-container[data-v-7d056319] {
  max-height: 41.15rem;
  overflow-y: auto;
  margin-right: 0.625rem;
}
.items[data-v-7d056319] {
  padding: 0 1rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
}
.capitalize[data-v-7d056319] {
  text-transform: capitalize;
}
.red[data-v-7d056319] {
  color: rgb(250, 118, 118);
}
.consignee[data-v-7d056319] {
  grid-template-columns: 1fr 1fr 2fr 1.8fr 1.5fr 1.5fr 1.5fr 1.25fr 1.2fr 1fr;
  grid-gap: 0.375rem;
}
.consignee-summary[data-v-7d056319] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.45fr !important;
  grid-gap: 0.375rem;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}
.expense-grid[data-v-7d056319] {
  grid-template-columns: repeat(9, 1fr) !important;
}
.items .btnWrap[data-v-7d056319] {
  overflow: visible !important;
}
.purple[data-v-7d056319] {
  color: #767BFA;
}
.header[data-v-ea275e44] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-ea275e44] {
  cursor: pointer;
}
.input[data-v-ea275e44] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-ea275e44] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-ea275e44] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-ea275e44] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-ea275e44] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-ea275e44] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-ea275e44] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-ea275e44] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-ea275e44] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-ea275e44] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-ea275e44] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-ea275e44] {
  font-size: 1.125rem;
}
.readOnly[data-v-ea275e44] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-ea275e44] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-ea275e44] {
  grid-column: span 2;
}
.breakup[data-v-ea275e44] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-ea275e44] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-ea275e44] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-ea275e44] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-ea275e44] {
  justify-content: space-evenly;
}
.jc-sb[data-v-ea275e44] {
  justify-content: space-between;
}
.btns[data-v-ea275e44] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-ea275e44] {
  padding: 0 1.25rem;
}
.pt-10[data-v-ea275e44] {
  padding-top: 0.625rem;
}
.table-container[data-v-ea275e44] {
  margin: 0 0.625rem;
}
.span-2[data-v-ea275e44] {
  grid-column: 2/4;
}
.green[data-v-ea275e44] {
  color: #12c519;
}
.mb-20[data-v-ea275e44] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-ea275e44] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-ea275e44] {
  padding: 0 1.25rem;
}
.lg-header[data-v-ea275e44] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-ea275e44] {
  cursor: pointer;
}
.search-input-cm[data-v-ea275e44] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-ea275e44] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-ea275e44],
.sm-item-grid[data-v-ea275e44] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-ea275e44] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-ea275e44] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-ea275e44] {
  background: #3b435e;
}
.sm-items[data-v-ea275e44] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-ea275e44] {
  background: #3b435e;
}
.col-grid[data-v-ea275e44] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-ea275e44] {
  font-weight: 500;
}
.list-item[data-v-ea275e44] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-ea275e44] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-ea275e44] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-ea275e44] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-ea275e44] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

th[data-v-58f2cc73] {
  background-color: #f6f7ff;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  border: none;
}
.dark th[data-v-58f2cc73] {
  background-color: #323a52;
}
table[data-v-58f2cc73] {
  border-spacing: 0 0.3125rem;
}
th[data-v-58f2cc73] {
  padding: 0;
}
td[data-v-58f2cc73] {
  padding: 0.3125rem 0;
  max-width: 10ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td[data-v-58f2cc73]:first-child,
td[data-v-58f2cc73]:last-child {
  padding: 0.3125rem 0.625rem;
}
tr[data-v-58f2cc73] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
}
.dark tr[data-v-58f2cc73] {
  background-color: #3b435e;
  color: #f6f7ff;
}
.dark td[data-v-58f2cc73],
.dark th[data-v-58f2cc73] {
  color: #f6f7ff;
}
.shadow-none[data-v-58f2cc73] {
  box-shadow: none !important;
}
.dark .shadow-none[data-v-58f2cc73] {
  background-color: #323a52;
}
.table[data-v-58f2cc73] {
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.625rem;
  margin: 0 0.625remq;
}
.sku img[data-v-58f2cc73] {
  max-width: 3.9375rem;
  height: auto;
}
.dark .sku img[data-v-58f2cc73] {
  max-width: none;
  width: 100%;
  height: 2.8125rem;
  object-fit: contain;
  background-color: #fff;
  border-radius: 0.3125rem;
}
td[data-v-58f2cc73] {
  border-bottom: 0;
}
tr td[data-v-58f2cc73]:first-child {
  border-top-left-radius: 0.4375rem;
}
tr td[data-v-58f2cc73]:last-child {
  border-top-right-radius: 0.4375rem;
}
tr td[data-v-58f2cc73]:first-child {
  border-bottom-left-radius: 0.4375rem;
}
tr td[data-v-58f2cc73]:last-child {
  border-bottom-right-radius: 0.4375rem;
}
tr th[data-v-58f2cc73]:first-child {
  padding-left: 1rem;
}
.capitalize[data-v-58f2cc73] {
  text-transform: capitalize;
}


.items[data-v-90a9005c] {
  padding: 0 1rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
}
.items-container[data-v-90a9005c] {
  overflow-y: scroll;
  margin-right: 0.1875rem;
  max-height: 14rem;
  padding-bottom: 0.5rem;
}
.headersgrid[data-v-90a9005c],
.item[data-v-90a9005c] {
  display: grid;
  grid-template-columns: 1fr 1.2fr 2fr;
  align-items: center;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-90a9005c] {
  display: grid;
  grid-template-columns: 1fr 1.2fr 2.05fr;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-90a9005c] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.item[data-v-90a9005c] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding: 0.8125rem 0;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
}
.dark .item[data-v-90a9005c] {
  background: #3b435e;
}
.item[data-v-90a9005c]:hover {
  border-color: #767bfa;
}
.item div[data-v-90a9005c],
.headersgrid div[data-v-90a9005c] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.consignment[data-v-90a9005c] {
  grid-template-columns: 1.5fr 1fr 1fr !important;
  grid-gap: 0.375rem;
}
.headersgrid.consignment[data-v-90a9005c] {
  grid-template-columns: 1.5fr 1fr 1.1fr !important;
  grid-gap: 0.375rem;
}
.type[data-v-90a9005c]::after {
  position: absolute;
  border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  content: "";
  top: 0%;
  left: 0%;
  height: 100%;
  display: grid;
  max-width: 8.125rem;
  width: 100%;
  z-index: -1;
  -webkit-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  -moz-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  -ms-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  -o-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
}
.type[data-v-90a9005c] {
  position: relative;
  color: #fff;
  z-index: 1;
  height: 100%;
  align-items: center;
  display: flex;
  max-width: 8.125rem;
  text-align: center;
}
.bold[data-v-90a9005c] {
  font-weight: 600;
}
.consignment.item[data-v-90a9005c] {
  padding: 0 0.625rem 0 0rem !important;
  height: 3.125rem;
  margin-bottom: 0;
}
.item span[data-v-90a9005c] {
  width: 100%;
  text-align: center;
}
.blue.type[data-v-90a9005c]::after {
  background-color: #7983d0;
}
.green.type[data-v-90a9005c]::after {
  background-color: #79c1d0;
}
.orange.type[data-v-90a9005c]::after {
  background-color: #d0a879;
}
.pink.type[data-v-90a9005c]::after {
  background-color: #d079c8;
}
.lightbrown[data-v-90a9005c]::after {
  background-color: #d0bd79;
}
.brown[data-v-90a9005c]::after {
  background-color: #d07979;
}
.lightblue[data-v-90a9005c]::after {
  background-color: #79c1d0;
}
.lightgreen[data-v-90a9005c]::after {
  background-color: #aad079;
}
.headersgrid.consignment.categories[data-v-90a9005c] {
  grid-template-columns: 1.35fr 1fr 1.1fr !important;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}

.name[data-v-3a839d2e] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.twogrid[data-v-3a839d2e] {
  display: grid;
  grid-template-columns: auto 1fr;
}
.twogrid.equal[data-v-3a839d2e] {
  grid-template-columns: 1fr 1fr;
}
.shoeimg[data-v-3a839d2e] {
  width: 10.875rem;
  height: 5.8125rem;
  object-fit: contain;
  margin-right: 1.625rem;
  box-shadow: 0rem 0rem 0.75rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  background-color: #fff;
}
.flex[data-v-3a839d2e] {
  display: flex;
  align-items: center;
}
.ml-4[data-v-3a839d2e] {
  margin-left: 3rem;
}
.label[data-v-3a839d2e] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;
  white-space: nowrap;
  margin-right: 0.625rem;
}
.value[data-v-3a839d2e] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.px-20[data-v-3a839d2e] {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.mb-10[data-v-3a839d2e] {
  margin-bottom: 0.6875rem !important;
}
.consignment .mb-10[data-v-3a839d2e] {
  margin-bottom: 1.125rem !important;
}
.pt-4[data-v-3a839d2e] {
  padding-top: 0.5rem;
}
.green[data-v-3a839d2e] {
  color: #12c519;
}
.red[data-v-3a839d2e] {
  color: #fa7676;
}
.capit[data-v-3a839d2e] {
  text-transform: capitalize;
}

.items[data-v-1693ec90] {
  padding: 0 1rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
}
.items-container[data-v-1693ec90] {
  overflow-y: auto;
  margin-right: 0.1875rem;
  max-height: 21rem;
  padding-bottom: 0.5rem;
}
.headersgrid[data-v-1693ec90],
.item[data-v-1693ec90] {
  display: grid;
  grid-template-columns: 3.9375rem 2.25fr 1.2fr 1fr 1.7fr 1.3fr 1.5fr 1.2fr;
  align-items: center;
  grid-gap: 1.5rem;
}
.headersgrid[data-v-1693ec90] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.4375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-right: 0.625rem;
}
.item[data-v-1693ec90] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding-top: 0.3125rem;
  padding-bottom: 0.5rem;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  border: 0.0625rem solid transparent;
  position: relative;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  cursor: pointer;
}
.dark .item[data-v-1693ec90] {
  background: #3b435e;
}
.item.item[data-v-1693ec90]:hover {
  border-color: #767bfa;
}
.item div[data-v-1693ec90],
.headersgrid div[data-v-1693ec90] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item img[data-v-1693ec90] {
  height: 2.3125rem;
  width: 100%;
}
.bgwhite[data-v-1693ec90] {
  background-color: #fff;
}
.green[data-v-1693ec90],
.Active[data-v-1693ec90] {
  color: #12c519;
}
.red[data-v-1693ec90],
.Expired[data-v-1693ec90] {
  color: #fa7676;
}
.Scheduled[data-v-1693ec90] {
  color: #d0a879;
}
.shoeimg[data-v-1693ec90] {
  width: 3.9375rem;
  object-fit: contain;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.discount-summary[data-v-1693ec90] {
  max-height: 19rem;
}
.discount-summary-header[data-v-1693ec90] {
  margin-top: 1.5rem !important;
}
.discount-summary-header[data-v-1693ec90],
.discount-summary .item[data-v-1693ec90] {
  grid-template-columns: 0.65fr 0.9fr 1.4fr 0.9fr 1fr 0.3fr 0.5fr;
  grid-gap: 1.1875rem;
}
.discount-summary .item[data-v-1693ec90] {
  padding-top: 0.8125rem;
  padding-bottom: 0.875rem;
}
.cap[data-v-1693ec90] {
  text-transform: uppercase;
}
.del-icon[data-v-1693ec90] {
  width: 1.25rem;
  height: 1.25rem !important;
}
.del-items .item[data-v-1693ec90],
.del-headers[data-v-1693ec90] {
  grid-template-columns: 0.65fr 0.9fr 1.4fr 0.9fr 1fr 0.3fr 0.5fr 1rem 1.25rem !important;
}
.t-headers[data-v-1693ec90],
.t-history .item[data-v-1693ec90] {
  grid-template-columns: 3.2rem 1.8fr 1fr 1fr 1fr 1fr 0.75fr;
}
.yellow[data-v-1693ec90] {
  color: #e7a33e;
}

.items[data-v-6684b176] {
  padding: 0 1rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
}
.items-container[data-v-6684b176] {
  overflow-y: scroll;
  margin-right: 0.1875rem;
  max-height: 14rem;
  padding-bottom: 0.5rem;
}
.headersgrid[data-v-6684b176],
.item[data-v-6684b176] {
  display: grid;
  grid-template-columns: 1fr 1.2fr 2fr;
  align-items: center;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-6684b176] {
  display: grid;
  grid-template-columns: 1fr 1.2fr 2.05fr;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-6684b176] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.item[data-v-6684b176] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding: 0.8125rem 0;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
}
.dark .item[data-v-6684b176] {
  background: #3b435e;
}
.item[data-v-6684b176]:hover {
  border-color: #767bfa;
}
.item div[data-v-6684b176],
.headersgrid div[data-v-6684b176] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.consignment[data-v-6684b176] {
  grid-template-columns: 1.5fr 1fr 1fr !important;
  grid-gap: 0.375rem;
}
.headersgrid.consignment[data-v-6684b176] {
  grid-template-columns: 1.5fr 1fr 1.1fr !important;
  grid-gap: 0.375rem;
}
.type[data-v-6684b176]::after {
  position: absolute;
  border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  content: "";
  top: 0%;
  left: 0%;
  height: 100%;
  display: grid;
  max-width: 8.125rem;
  width: 100%;
  z-index: -1;
  -webkit-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  -moz-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  -ms-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  -o-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
}
.type[data-v-6684b176] {
  position: relative;
  color: #fff;
  z-index: 1;
  height: 100%;
  align-items: center;
  display: flex;
  max-width: 8.125rem;
  text-align: center;
}
.bold[data-v-6684b176] {
  font-weight: 600;
}
.consignment.item[data-v-6684b176] {
  padding: 0 0.625rem 0 0rem !important;
  height: 3.125rem;
  margin-bottom: 0;
}
.item span[data-v-6684b176] {
  width: 100%;
  text-align: center;
}
.blue.type[data-v-6684b176]::after {
  background-color: #7983d0;
}
.green.type[data-v-6684b176]::after {
  background-color: #79c1d0;
}
.orange.type[data-v-6684b176]::after {
  background-color: #d0a879;
}
.pink.type[data-v-6684b176]::after {
  background-color: #d079c8;
}
.lightbrown[data-v-6684b176]::after {
  background-color: #d0bd79;
}
.brown[data-v-6684b176]::after {
  background-color: #d07979;
}
.lightblue[data-v-6684b176]::after {
  background-color: #79c1d0;
}
.lightgreen[data-v-6684b176]::after {
  background-color: #aad079;
}
.headersgrid.consignment.categories[data-v-6684b176] {
  grid-template-columns: 1.35fr 1fr 1.1fr !important;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}

.tooltip[data-v-4d80ed12] {
  border-radius: 0.25rem;
  background: #fff;
  padding: 1rem;
  box-shadow: 0.9375rem 1.875rem 2.5rem 0.3125rem rgba(255, 255, 255, 0.25);
  text-align: center;
  color: #26313c;
}
.dark .tooltip[data-v-4d80ed12] {
  background-color: #26313c;
  color: #fff;
  box-shadow: 0.9375rem 1.875rem 2.5rem 0.3125rem rgba(0, 0, 0, 0.25);
}

.search-btn[data-v-557a52d5] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 9.0625rem;
  width: 100%;
  background: #f6f7ff;
  box-shadow: inset 0rem 0rem 0.3125rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  height: 1.75rem;
  padding: 0 0.625rem 0 0.75rem;
}
.dark .search-btn[data-v-557a52d5] {
  background: #353c53;
  box-shadow: 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.15);
}
svg[data-v-557a52d5] {
  cursor: pointer;
}
input[data-v-557a52d5] {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-right: 0.3125rem;
}
.dark input[data-v-557a52d5] {
  color: #f6f7ff;
}
.search-btn input[data-v-557a52d5]::-webkit-input-placeholder {
  color: #abafd1;
}
.search-btn input[data-v-557a52d5]::placeholder {
  color: #abafd1;
}
.dark .search-btn input[data-v-557a52d5]::-webkit-input-placeholder {
  color: #5a5d74 !important;
}
.dark .search-btn input[data-v-557a52d5]::placeholder {
  color: #5a5d74 !important;
}

.topbar[data-v-cc629abd] {
  display: flex;
  justify-content: space-between;
  padding: 1.4375rem 0;
  position: relative;
}
.pagename[data-v-cc629abd] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  display: flex;
  align-items: center;
}
.user[data-v-cc629abd] {
  display: flex;
  align-items: center;
}
.userimg[data-v-cc629abd] {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.userimg img[data-v-cc629abd] {
  width: 100%;
  height: 100%;
}
.username[data-v-cc629abd] {
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-left: 0.625rem;
}
.sm-link[data-v-cc629abd] {
  font-weight: 600;
  font-size: 0.9375rem;
  display: inline-block;
  cursor: pointer;
  line-height: 1.375rem;
  color: #abafd1;
}
.dark .sm-link[data-v-cc629abd] {
  color: #5a5d74;
}
.sm-link[data-v-cc629abd]:not(:last-child) {
  margin-right: 1.25rem;
}
.sm-links[data-v-cc629abd] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.active-link[data-v-cc629abd] {
  color: #323a52;
}
.dark .active-link[data-v-cc629abd] {
  color: #f6f7ff;
}
.logoutbtn[data-v-cc629abd] {
  /* padding: 1.5625rem 2.525rem; */
}
.circle[data-v-cc629abd] {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #4b4e93;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.summary[data-v-f78890e1] {
  display: grid;
  grid-gap: 1.875rem 2.5rem;
  grid-template-rows: 19.5625rem 26.25rem;
  grid-template-columns: 1fr 1fr 1fr;
}
.headersection[data-v-f78890e1] {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding: 0.8125rem 1.25rem;
  padding-bottom: 0;
}
.headersection[data-v-f78890e1]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-f78890e1]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.box[data-v-f78890e1] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
}
.dark .box[data-v-f78890e1] {
  background: #353c53;
}
.bold[data-v-f78890e1] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  color: #abafd1;
}
.value[data-v-f78890e1] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;

  margin-left: 0.3125rem;
}
.span2[data-v-f78890e1] {
  grid-column: 1/3;
}
.total[data-v-f78890e1] {
  padding-right: 0.875rem;
}
.summary-table[data-v-f78890e1] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgb(0 0 0 / 15%);
  border-radius: 0.9375rem;
  padding: 0.8125rem 0;
}
.dark .summary-table[data-v-f78890e1] {
  background: #353c53;
}
.purple[data-v-f78890e1] {
  font-weight: 500;
  color: #767bfa;
}
.query[data-v-f78890e1]:not(:last-child) {
  margin-right: 2.5rem;
}
.query[data-v-f78890e1] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.smtext[data-v-f78890e1] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.queryvalue[data-v-f78890e1] {
  font-weight: 300;

  margin-left: 0.625rem;
}
.green[data-v-f78890e1] {
  color: #12c519;
}
.black[data-v-f78890e1] {
  margin-right: 0.3125rem;
  color: #323a52 !important;
}
.dark .black[data-v-f78890e1] {
  color: #f6f7ff !important;
}
.itemssold[data-v-f78890e1] {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.35rem;
  margin-left: 0.625rem;
}
.al-fe[data-v-f78890e1] {
  align-items: flex-end;
}
.px-20[data-v-f78890e1] {
  padding: 0 1.25rem;
}
.headers[data-v-f78890e1] {
  margin-bottom: 0.875rem;
}

.formControl[data-v-2606e703] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  margin: 0px !important;
}
.dark .formControl[data-v-2606e703] {
  background: #353c53;
}
.menuitem[data-v-2606e703] {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: left;
  justify-content: center;
  color: #abafd1;
}
.menuitem[data-v-2606e703]:hover {
  background-color: rgba(118, 123, 250, 0.15) !important;
}
.dark .menuitem[data-v-2606e703] {
  color: #f6f7ff !important;
}
.menuitem.focusVisible[data-v-2606e703] {
  background-color: rgba(118, 123, 250, 0.15) !important;
}

.header[data-v-c70878f6] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-c70878f6] {
  cursor: pointer;
}
.input[data-v-c70878f6] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-c70878f6] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-c70878f6] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-c70878f6] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-c70878f6] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-c70878f6] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-c70878f6] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-c70878f6] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-c70878f6] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-c70878f6] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-c70878f6] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-c70878f6] {
  font-size: 1.125rem;
}
.readOnly[data-v-c70878f6] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-c70878f6] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-c70878f6] {
  grid-column: span 2;
}
.breakup[data-v-c70878f6] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-c70878f6] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-c70878f6] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-c70878f6] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-c70878f6] {
  justify-content: space-evenly;
}
.jc-sb[data-v-c70878f6] {
  justify-content: space-between;
}
.btns[data-v-c70878f6] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-c70878f6] {
  padding: 0 1.25rem;
}
.pt-10[data-v-c70878f6] {
  padding-top: 0.625rem;
}
.table-container[data-v-c70878f6] {
  margin: 0 0.625rem;
}
.span-2[data-v-c70878f6] {
  grid-column: 2/4;
}
.green[data-v-c70878f6] {
  color: #12c519;
}
.mb-20[data-v-c70878f6] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-c70878f6] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-c70878f6] {
  padding: 0 1.25rem;
}
.lg-header[data-v-c70878f6] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-c70878f6] {
  cursor: pointer;
}
.search-input-cm[data-v-c70878f6] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-c70878f6] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-c70878f6],
.sm-item-grid[data-v-c70878f6] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-c70878f6] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-c70878f6] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-c70878f6] {
  background: #3b435e;
}
.sm-items[data-v-c70878f6] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-c70878f6] {
  background: #3b435e;
}
.col-grid[data-v-c70878f6] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-c70878f6] {
  font-weight: 500;
}
.list-item[data-v-c70878f6] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-c70878f6] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-c70878f6] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-c70878f6] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-c70878f6] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

.inventory-container[data-v-e9d8e0da] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding: 0.8125rem 0;
}
.dark .inventory-container[data-v-e9d8e0da] {
  background: #353c53;
}
.headersection[data-v-e9d8e0da] {
  position: relative;
  padding: 0 1rem;
}
.headersection[data-v-e9d8e0da]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-e9d8e0da]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.purple[data-v-e9d8e0da] {
  font-weight: 500;
  color: #767bfa;
}
.query[data-v-e9d8e0da]:not(:last-child) {
  margin-right: 2.5rem;
}
.query[data-v-e9d8e0da] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.smtext[data-v-e9d8e0da] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.queryvalue[data-v-e9d8e0da] {
  font-weight: 300;

  margin-left: 0.625rem;
}
.green[data-v-e9d8e0da] {
  color: #12c519;
}

.headers-grid[data-v-3389baea] {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.2fr 0.9fr 1.15fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid.listed[data-v-3389baea] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr  1.25fr 1.25fr 1.1fr 1.1fr 2.8fr 0.6fr 1fr 0.9fr;
}
.item-grid[data-v-3389baea] {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.2fr 0.9fr 1.15fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  height: 3.125rem;
  align-items: center;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid.listed[data-v-3389baea] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr  1.25fr 1.25fr 1.1fr 1.1fr 2.8fr 0.6fr 1fr 0.9fr;
}
.dark .item-grid[data-v-3389baea] {
  background: #3b435e;
}
.item-grid[data-v-3389baea]:hover {
  border-color: #767bfa;
}
.item-grid div[data-v-3389baea],
.headers-grid div[data-v-3389baea] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-grid svg[data-v-3389baea] {
  cursor: pointer;
}
.green[data-v-3389baea] {
  color: #12c519;
}
.items-container[data-v-3389baea] {
  max-height: 41.15rem;
  overflow-y: auto;
  margin-right: 0.625rem;
}
.items[data-v-3389baea] {
  padding: 0 1rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
}
.capitalize[data-v-3389baea] {
  text-transform: capitalize;
}
.red[data-v-3389baea] {
  color: rgb(250, 118, 118);
}
.consignee[data-v-3389baea] {
  grid-template-columns: 1fr 1fr 2fr 1.8fr 1.5fr 1.5fr 1.5fr 1.25fr 1.2fr 1fr;
  grid-gap: 0.375rem;
}
.consignee-summary[data-v-3389baea] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.45fr !important;
  grid-gap: 0.375rem;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}
.expense-grid[data-v-3389baea] {
  grid-template-columns: repeat(9, 1fr) !important;
}
.items .btnWrap[data-v-3389baea] {
  overflow: visible !important;
}
.purple[data-v-3389baea] {
  color: #767BFA;
}
.header[data-v-406c5922] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-406c5922] {
  cursor: pointer;
}
.input[data-v-406c5922] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-406c5922] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-406c5922] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-406c5922] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-406c5922] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-406c5922] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-406c5922] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-406c5922] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-406c5922] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-406c5922] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-406c5922] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-406c5922] {
  font-size: 1.125rem;
}
.readOnly[data-v-406c5922] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-406c5922] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-406c5922] {
  grid-column: span 2;
}
.breakup[data-v-406c5922] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-406c5922] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-406c5922] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-406c5922] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-406c5922] {
  justify-content: space-evenly;
}
.jc-sb[data-v-406c5922] {
  justify-content: space-between;
}
.btns[data-v-406c5922] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-406c5922] {
  padding: 0 1.25rem;
}
.pt-10[data-v-406c5922] {
  padding-top: 0.625rem;
}
.table-container[data-v-406c5922] {
  margin: 0 0.625rem;
}
.span-2[data-v-406c5922] {
  grid-column: 2/4;
}
.green[data-v-406c5922] {
  color: #12c519;
}
.mb-20[data-v-406c5922] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-406c5922] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-406c5922] {
  padding: 0 1.25rem;
}
.lg-header[data-v-406c5922] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-406c5922] {
  cursor: pointer;
}
.search-input-cm[data-v-406c5922] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-406c5922] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-406c5922],
.sm-item-grid[data-v-406c5922] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-406c5922] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-406c5922] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-406c5922] {
  background: #3b435e;
}
.sm-items[data-v-406c5922] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-406c5922] {
  background: #3b435e;
}
.col-grid[data-v-406c5922] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-406c5922] {
  font-weight: 500;
}
.list-item[data-v-406c5922] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-406c5922] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-406c5922] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-406c5922] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-406c5922] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

.productinfo[data-v-d51aa358] {
  text-align: center;
  height: 100%;
  padding: 1.25rem 1.625rem 0 1.25rem;
  border-radius: 0.9375rem;
}
.categories[data-v-d51aa358] {
  padding: 1.25rem 1.625rem 0 1.25rem;
}
.headersection[data-v-d51aa358] {
  position: relative;
}
.headersection[data-v-d51aa358]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: -0.25rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-d51aa358]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.blur[data-v-d51aa358] {
  -webkit-filter: blur(0.375rem);
          filter: blur(0.375rem);
  -webkit-user-select: none;
          user-select: none;
}
.smtext[data-v-d51aa358] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.shoeimg[data-v-d51aa358] {
  width: 10.9375rem !important;
  height: 5.9375rem !important;
  object-fit: contain !important;
  border-radius: 0.9375rem !important;
  background-color: #fff;
  margin-top: 0.75rem !important;
  margin-bottom: 0.25rem !important;
  -webkit-border-radius: 0.9375rem !important;
  -moz-border-radius: 0.9375rem !important;
  -ms-border-radius: 0.9375rem !important;
  -o-border-radius: 0.9375rem !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.mb-comparing[data-v-d51aa358] {
  margin-bottom: 0.5rem;
}
.bold[data-v-d51aa358] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.mt-10[data-v-d51aa358] {
  margin-top: 0.625rem;
}
.query[data-v-d51aa358] {
  margin-right: 0.3125rem;
}
.large .query[data-v-d51aa358],
.large .value[data-v-d51aa358] {
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.pricing[data-v-d51aa358] {
  text-align: center;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.red[data-v-d51aa358],
.green[data-v-d51aa358],
.blue[data-v-d51aa358] {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.brown[data-v-d51aa358] {
  background-color: #d07979;
}
.light-brown[data-v-d51aa358] {
  background-color: #d0a879;
}
.light-green[data-v-d51aa358] {
  background-color: #aad079;
}
.light-blue[data-v-d51aa358] {
  background-color: #79c1d0;
}
.dark-brown[data-v-d51aa358] {
  background-color: #b47467;
}
.pink[data-v-d51aa358] {
  background-color: #d079c8;
}
.transaction-history .red[data-v-d51aa358],
.transaction-history .green[data-v-d51aa358] {
  font-weight: 300 !important;
  font-size: 0.9375rem;
}
.site[data-v-d51aa358] {
  margin-bottom: 2rem;
}
.brand[data-v-d51aa358] {
  width: 6rem;
  height: 3.25rem;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  object-fit: contain;
  padding: 0.1875rem;
}
.bids[data-v-d51aa358] {
  margin-top: 1.0625rem;
  margin-bottom: 0.9375rem;
}
.al-st[data-v-d51aa358] {
  align-items: flex-start;
}
.new[data-v-d51aa358] {
  -webkit-backdrop-filter: blur(0.5625rem);
          backdrop-filter: blur(0.5625rem);
  position: absolute;
  top: -0.3125rem;
  left: -0.3125rem;
  right: -0.3125rem;
  bottom: -0.3125rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  align-items: center;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.comparing[data-v-d51aa358] {
  position: relative;
  padding: 1.25rem 1.625rem 0 1.25rem;
  height: 100%;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.b-shadow[data-v-d51aa358] {
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
}
.value[data-v-d51aa358] {
  font-weight: 300;
  font-size: 0.9375rem;
}
.large .value[data-v-d51aa358] {
  font-weight: 400;
}
.categories-grid[data-v-d51aa358] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.625rem 0.3125rem;
  text-align: center;
}
.blue[data-v-d51aa358] {
  background: #7983d0;
}
.lightblue[data-v-d51aa358] {
  background: #79c1d0;
}
.yellow[data-v-d51aa358] {
  background: #d0bd79;
}
.grey[data-v-d51aa358] {
  background: #abafd1;
}
.smbox[data-v-d51aa358] {
  box-shadow: 0rem 0rem 0.375rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  font-weight: 300 !important;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #ffffff !important;
  height: 2.5rem;
}
.text-center[data-v-d51aa358] {
  text-align: center;
  margin-bottom: 1rem;
}
.categories-section[data-v-d51aa358] {
  margin-top: 2rem;
}
.swiper[data-v-d51aa358] {
  width: 100%;
  height: 100%;
}
.swiper-slide[data-v-d51aa358] {
  text-align: center;
  font-size: 1.125rem;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.6875rem;
}
.swiper-slide img[data-v-d51aa358] {
  display: block;
  width: 100%;
  cursor: pointer;
  height: auto;
  object-fit: contain;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.trending[data-v-d51aa358] {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  border-radius: 0.9375rem;
  overflow: hidden;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.pl[data-v-d51aa358] {
  margin-left: 1.25rem;
  padding-top: 0.8125rem;
  padding-left: 0 !important  ;
}
.priceheader[data-v-d51aa358] {
  margin-left: 1.25rem;
  padding-right: 1.1875rem;
  padding-top: 0.625rem;
  margin-bottom: 1.25rem;
}
.priceheader.price-history[data-v-d51aa358] {
  margin-bottom: 2.2rem;
}
.twogrid[data-v-d51aa358] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 2.125rem;
}
.twogrid.smgrid[data-v-d51aa358] {
  padding: 0 !important;
  grid-gap: 1.25rem;
  margin-top: 1.25rem;
}
.shadow[data-v-d51aa358] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding: 0.875rem 1.375rem;
}
.dark .shadow[data-v-d51aa358] {
  background: #3b435e;
}
.shadow .bold[data-v-d51aa358] {
  margin-bottom: 0.3125rem;
}
.mt-10[data-v-d51aa358] {
  margin-top: 0.625rem !important;
}
.details[data-v-d51aa358] {
  margin-left: 1.5625rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.type[data-v-d51aa358] {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.cond[data-v-d51aa358] {
  margin-left: 0.625rem;
}
.headersgrid[data-v-d51aa358],
.item[data-v-d51aa358] {
  display: grid;
  grid-template-columns: 1fr 1fr 1.1fr 0.8fr;
  align-items: center;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .item[data-v-d51aa358] {
  background-color: #3b435e;
}
.headersgrid div[data-v-d51aa358],
.item div[data-v-d51aa358] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.visible[data-v-d51aa358] {
  overflow: visible !important;
}
.transaction-history .item img[data-v-d51aa358] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.item[data-v-d51aa358] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 14px 17px;
  margin-bottom: 0.3125rem;
  font-weight: 300;
}
.headersgrid[data-v-d51aa358] {
  padding: 0 1.0625rem;
  font-weight: 500;
}
.transaction-history .headersgrid[data-v-d51aa358] {
  padding: 0 2rem;
}
.transaction-history .item[data-v-d51aa358] {
  padding: 0.375rem 0.625rem;
}
.transaction-history .headersgrid[data-v-d51aa358],
.transaction-history .item[data-v-d51aa358] {
  display: grid;
  grid-template-columns: 3.9375rem 1fr 0.4fr 0.6fr !important;
  grid-gap: 0.625rem !important;
}
.dark .transaction-history .item[data-v-d51aa358] {
  background-color: #3b435e;
}
.pricing .blue[data-v-d51aa358] {
  background: transparent !important;
}
.items[data-v-d51aa358] {
  max-height: 18.85rem;
  overflow-y: auto;
  padding: 0.75rem 1rem;
  margin-right: 0.375rem;
  padding-right: 0.375rem;
}
.transaction-history .items[data-v-d51aa358] {
  max-height: 41.35rem;
}
.transaction-history-owner .items[data-v-d51aa358] {
  max-height: 20rem !important;
}
.headerscotainer[data-v-d51aa358] {
  padding: 0 1rem;
}
.mt-15[data-v-d51aa358] {
  margin-bottom: 0.3rem;
}
.mt-0[data-v-d51aa358] {
  margin-top: 0 !important;
}
.mt-18[data-v-d51aa358] {
  margin-top: 1.125rem !important;
}
.home-categories[data-v-d51aa358] {
  padding: 1rem 1.125rem;
}
.home-categories .query[data-v-d51aa358],
.home-categories .value[data-v-d51aa358] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pr-10[data-v-d51aa358] {
  padding-right: 4rem;
}
.staff-overviews .items[data-v-d51aa358] {
  max-height: 13rem;
}
.item img[data-v-d51aa358] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.total[data-v-d51aa358] {
  margin-left: -3.5rem;
  font-weight: 600;
}
.totaltext[data-v-d51aa358] {
  color: #abafd1;
}
.dark .totaltext[data-v-d51aa358] {
  color: #5a5d74;
}
.admin-items .headersgrid[data-v-d51aa358],
.admin-items .item[data-v-d51aa358] {
  grid-template-columns: 0.5fr 1fr 0.8fr 0.6fr !important;
}
.admin-items .item img[data-v-d51aa358] {
  margin-left: 0.3125rem;
}
.customer-details .items[data-v-d51aa358] {
  max-height: 15rem;
}
.customer-details .item[data-v-d51aa358] {
  grid-template-columns: 1fr 1fr !important;
}
.twogrid-section[data-v-d51aa358] {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.header[data-v-8c016ee7] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-8c016ee7] {
  cursor: pointer;
}
.input[data-v-8c016ee7] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-8c016ee7] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-8c016ee7] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-8c016ee7] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-8c016ee7] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-8c016ee7] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-8c016ee7] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-8c016ee7] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-8c016ee7] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-8c016ee7] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-8c016ee7] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-8c016ee7] {
  font-size: 1.125rem;
}
.readOnly[data-v-8c016ee7] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-8c016ee7] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-8c016ee7] {
  grid-column: span 2;
}
.breakup[data-v-8c016ee7] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-8c016ee7] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-8c016ee7] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-8c016ee7] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-8c016ee7] {
  justify-content: space-evenly;
}
.jc-sb[data-v-8c016ee7] {
  justify-content: space-between;
}
.btns[data-v-8c016ee7] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-8c016ee7] {
  padding: 0 1.25rem;
}
.pt-10[data-v-8c016ee7] {
  padding-top: 0.625rem;
}
.table-container[data-v-8c016ee7] {
  margin: 0 0.625rem;
}
.span-2[data-v-8c016ee7] {
  grid-column: 2/4;
}
.green[data-v-8c016ee7] {
  color: #12c519;
}
.mb-20[data-v-8c016ee7] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-8c016ee7] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-8c016ee7] {
  padding: 0 1.25rem;
}
.lg-header[data-v-8c016ee7] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-8c016ee7] {
  cursor: pointer;
}
.search-input-cm[data-v-8c016ee7] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-8c016ee7] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-8c016ee7],
.sm-item-grid[data-v-8c016ee7] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-8c016ee7] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-8c016ee7] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-8c016ee7] {
  background: #3b435e;
}
.sm-items[data-v-8c016ee7] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-8c016ee7] {
  background: #3b435e;
}
.col-grid[data-v-8c016ee7] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-8c016ee7] {
  font-weight: 500;
}
.list-item[data-v-8c016ee7] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-8c016ee7] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-8c016ee7] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-8c016ee7] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-8c016ee7] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

.header[data-v-bd840af3] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-bd840af3] {
  cursor: pointer;
}
.input[data-v-bd840af3] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-bd840af3] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-bd840af3] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-bd840af3] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-bd840af3] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-bd840af3] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-bd840af3] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-bd840af3] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-bd840af3] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-bd840af3] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-bd840af3] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-bd840af3] {
  font-size: 1.125rem;
}
.readOnly[data-v-bd840af3] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-bd840af3] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-bd840af3] {
  grid-column: span 2;
}
.breakup[data-v-bd840af3] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-bd840af3] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-bd840af3] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-bd840af3] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-bd840af3] {
  justify-content: space-evenly;
}
.jc-sb[data-v-bd840af3] {
  justify-content: space-between;
}
.btns[data-v-bd840af3] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-bd840af3] {
  padding: 0 1.25rem;
}
.pt-10[data-v-bd840af3] {
  padding-top: 0.625rem;
}
.table-container[data-v-bd840af3] {
  margin: 0 0.625rem;
}
.span-2[data-v-bd840af3] {
  grid-column: 2/4;
}
.green[data-v-bd840af3] {
  color: #12c519;
}
.mb-20[data-v-bd840af3] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-bd840af3] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-bd840af3] {
  padding: 0 1.25rem;
}
.lg-header[data-v-bd840af3] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-bd840af3] {
  cursor: pointer;
}
.search-input-cm[data-v-bd840af3] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-bd840af3] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-bd840af3],
.sm-item-grid[data-v-bd840af3] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-bd840af3] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-bd840af3] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-bd840af3] {
  background: #3b435e;
}
.sm-items[data-v-bd840af3] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-bd840af3] {
  background: #3b435e;
}
.col-grid[data-v-bd840af3] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-bd840af3] {
  font-weight: 500;
}
.list-item[data-v-bd840af3] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-bd840af3] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-bd840af3] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-bd840af3] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-bd840af3] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

.items[data-v-6b772e79] {
  padding: 0 1rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
}
.items-container[data-v-6b772e79] {
  overflow-y: auto;
  margin-right: 0.1875rem;
  max-height: 21rem;
  padding-bottom: 0.5rem;
}
.headersgrid[data-v-6b772e79],
.item[data-v-6b772e79] {
  display: grid;
  grid-template-columns: 3.9375rem 2.25fr 1.2fr 1fr 1.7fr 1.3fr 1.5fr 1.2fr;
  align-items: center;
  grid-gap: 1.5rem;
}
.headersgrid[data-v-6b772e79] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.4375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-right: 0.625rem;
}
.item[data-v-6b772e79] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding-top: 0.3125rem;
  padding-bottom: 0.5rem;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  border: 0.0625rem solid transparent;
  position: relative;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  cursor: pointer;
}
.dark .item[data-v-6b772e79] {
  background: #3b435e;
}
.item.item[data-v-6b772e79]:hover {
  border-color: #767bfa;
}
.item div[data-v-6b772e79],
.headersgrid div[data-v-6b772e79] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item img[data-v-6b772e79] {
  height: 2.3125rem;
  width: 100%;
}
.bgwhite[data-v-6b772e79] {
  background-color: #fff;
}
.green[data-v-6b772e79],
.Active[data-v-6b772e79] {
  color: #12c519;
}
.red[data-v-6b772e79],
.Expired[data-v-6b772e79] {
  color: #fa7676;
}
.Scheduled[data-v-6b772e79] {
  color: #d0a879;
}
.shoeimg[data-v-6b772e79] {
  width: 3.9375rem;
  object-fit: contain;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.discount-summary[data-v-6b772e79] {
  max-height: 19rem;
}
.discount-summary-header[data-v-6b772e79] {
  margin-top: 1.5rem !important;
}
.discount-summary-header[data-v-6b772e79],
.discount-summary .item[data-v-6b772e79] {
  grid-template-columns: 0.65fr 0.9fr 1.4fr 0.9fr 1fr 0.3fr 0.5fr;
  grid-gap: 1.1875rem;
}
.discount-summary .item[data-v-6b772e79] {
  padding-top: 0.8125rem;
  padding-bottom: 0.875rem;
}
.cap[data-v-6b772e79] {
  text-transform: uppercase;
}
.del-icon[data-v-6b772e79] {
  width: 1.25rem;
  height: 1.25rem !important;
}
.del-items .item[data-v-6b772e79],
.del-headers[data-v-6b772e79] {
  grid-template-columns: 0.65fr 0.9fr 1.4fr 0.9fr 1fr 0.3fr 0.5fr 1rem 1.25rem !important;
}
.t-headers[data-v-6b772e79],
.t-history .item[data-v-6b772e79] {
  grid-template-columns: 3.2rem 1.8fr 1fr 1fr 1fr 1fr 0.75fr;
}
.yellow[data-v-6b772e79] {
  color: #e7a33e;
}

.header[data-v-8c604a3f] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-8c604a3f] {
  cursor: pointer;
}
.row[data-v-8c604a3f] {
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.three-grid[data-v-8c604a3f] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.two-grid[data-v-8c604a3f] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.label[data-v-8c604a3f] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.jus-end[data-v-8c604a3f] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btns[data-v-8c604a3f] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-8c604a3f] {
  padding: 0 1.25rem;
}
.table-container[data-v-8c604a3f] {
  margin: 0 0.625rem;
}
.img-upload[data-v-8c604a3f] {
  background: #f6f7ff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.25rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.dark .img-upload[data-v-8c604a3f] {
  background-color: #353c53;
}
.img-upload[data-v-8c604a3f] {
  cursor: pointer;
}
.text[data-v-8c604a3f] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.grey-text[data-v-8c604a3f] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-top: 0.75rem;
}
.ticket[data-v-8c604a3f] {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  padding: 0.625rem;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}
.ticket[data-v-8c604a3f]:hover {
  background: #dfdfdf;
}
.ticket-img[data-v-8c604a3f] {
  max-width: 7rem;
  max-height: 7rem;
  width: 100%;
  overflow: hidden;
  object-fit: fill;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
  margin-right: 0.625rem;
}
.search-results[data-v-8c604a3f] {
  margin-top: 0.625rem;
  padding: 0 1.25rem;
  max-height: 40rem;
  overflow-y: auto;
}
.ticket-title[data-v-8c604a3f] {
  font-size: 1.125rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-description[data-v-8c604a3f] {
  font-size: 0.925rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-date[data-v-8c604a3f] {
  font-size: 0.9375rem;
  font-weight: 500;
}
.ticket-container[data-v-8c604a3f] {
  height: 100%;
}
.ticket-info[data-v-8c604a3f] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
}

.tooltip[data-v-db54c66f] {
  border-radius: 0.25rem;
  background: #fff;
  padding: 1rem;
  box-shadow: 0.9375rem 1.875rem 2.5rem 0.3125rem rgba(255, 255, 255, 0.25);
  text-align: center;
  color: #26313c;
}
.dark .tooltip[data-v-db54c66f] {
  background-color: #26313c;
  color: #fff;
  box-shadow: 0.9375rem 1.875rem 2.5rem 0.3125rem rgba(0, 0, 0, 0.25);
}

.productinfo[data-v-db54c66f] {
  text-align: center;
  height: 100%;
  padding: 1.25rem 1.625rem 0 1.25rem;
  border-radius: 0.9375rem;
}
.categories[data-v-db54c66f] {
  padding: 1.25rem 1.625rem 0 1.25rem;
}
.headersection[data-v-db54c66f] {
  position: relative;
}
.headersection[data-v-db54c66f]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: -0.25rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-db54c66f]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.blur[data-v-db54c66f] {
  -webkit-filter: blur(0.375rem);
          filter: blur(0.375rem);
  -webkit-user-select: none;
          user-select: none;
}
.smtext[data-v-db54c66f] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.shoeimg[data-v-db54c66f] {
  width: 10.9375rem !important;
  height: 5.9375rem !important;
  object-fit: contain !important;
  border-radius: 0.9375rem !important;
  background-color: #fff;
  margin-top: 0.75rem !important;
  margin-bottom: 0.25rem !important;
  -webkit-border-radius: 0.9375rem !important;
  -moz-border-radius: 0.9375rem !important;
  -ms-border-radius: 0.9375rem !important;
  -o-border-radius: 0.9375rem !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.mb-comparing[data-v-db54c66f] {
  margin-bottom: 0.5rem;
}
.bold[data-v-db54c66f] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.mt-10[data-v-db54c66f] {
  margin-top: 0.625rem;
}
.query[data-v-db54c66f] {
  margin-right: 0.3125rem;
}
.large .query[data-v-db54c66f],
.large .value[data-v-db54c66f] {
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.pricing[data-v-db54c66f] {
  text-align: center;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.red[data-v-db54c66f],
.green[data-v-db54c66f],
.blue[data-v-db54c66f] {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.brown[data-v-db54c66f] {
  background-color: #d07979;
}
.light-brown[data-v-db54c66f] {
  background-color: #d0a879;
}
.light-green[data-v-db54c66f] {
  background-color: #aad079;
}
.light-blue[data-v-db54c66f] {
  background-color: #79c1d0;
}
.dark-brown[data-v-db54c66f] {
  background-color: #b47467;
}
.pink[data-v-db54c66f] {
  background-color: #d079c8;
}
.transaction-history .red[data-v-db54c66f],
.transaction-history .green[data-v-db54c66f] {
  font-weight: 300 !important;
  font-size: 0.9375rem;
}
.site[data-v-db54c66f] {
  margin-bottom: 2rem;
}
.brand[data-v-db54c66f] {
  width: 6rem;
  height: 3.25rem;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  object-fit: contain;
  padding: 0.1875rem;
}
.bids[data-v-db54c66f] {
  margin-top: 1.0625rem;
  margin-bottom: 0.9375rem;
}
.al-st[data-v-db54c66f] {
  align-items: flex-start;
}
.new[data-v-db54c66f] {
  -webkit-backdrop-filter: blur(0.5625rem);
          backdrop-filter: blur(0.5625rem);
  position: absolute;
  top: -0.3125rem;
  left: -0.3125rem;
  right: -0.3125rem;
  bottom: -0.3125rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  align-items: center;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.comparing[data-v-db54c66f] {
  position: relative;
  padding: 1.25rem 1.625rem 0 1.25rem;
  height: 100%;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.b-shadow[data-v-db54c66f] {
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
}
.value[data-v-db54c66f] {
  font-weight: 300;
  font-size: 0.9375rem;
}
.large .value[data-v-db54c66f] {
  font-weight: 400;
}
.categories-grid[data-v-db54c66f] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.625rem 0.3125rem;
  text-align: center;
}
.blue[data-v-db54c66f] {
  background: #7983d0;
}
.lightblue[data-v-db54c66f] {
  background: #79c1d0;
}
.yellow[data-v-db54c66f] {
  background: #d0bd79;
}
.grey[data-v-db54c66f] {
  background: #abafd1;
}
.smbox[data-v-db54c66f] {
  box-shadow: 0rem 0rem 0.375rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  font-weight: 300 !important;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #ffffff !important;
  height: 2.5rem;
}
.text-center[data-v-db54c66f] {
  text-align: center;
  margin-bottom: 1rem;
}
.categories-section[data-v-db54c66f] {
  margin-top: 2rem;
}
.swiper[data-v-db54c66f] {
  width: 100%;
  height: 100%;
}
.swiper-slide[data-v-db54c66f] {
  text-align: center;
  font-size: 1.125rem;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.6875rem;
}
.swiper-slide img[data-v-db54c66f] {
  display: block;
  width: 100%;
  cursor: pointer;
  height: auto;
  object-fit: contain;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.trending[data-v-db54c66f] {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  border-radius: 0.9375rem;
  overflow: hidden;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.pl[data-v-db54c66f] {
  margin-left: 1.25rem;
  padding-top: 0.8125rem;
  padding-left: 0 !important  ;
}
.priceheader[data-v-db54c66f] {
  margin-left: 1.25rem;
  padding-right: 1.1875rem;
  padding-top: 0.625rem;
  margin-bottom: 1.25rem;
}
.priceheader.price-history[data-v-db54c66f] {
  margin-bottom: 2.2rem;
}
.twogrid[data-v-db54c66f] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 2.125rem;
}
.twogrid.smgrid[data-v-db54c66f] {
  padding: 0 !important;
  grid-gap: 1.25rem;
  margin-top: 1.25rem;
}
.shadow[data-v-db54c66f] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding: 0.875rem 1.375rem;
}
.dark .shadow[data-v-db54c66f] {
  background: #3b435e;
}
.shadow .bold[data-v-db54c66f] {
  margin-bottom: 0.3125rem;
}
.mt-10[data-v-db54c66f] {
  margin-top: 0.625rem !important;
}
.details[data-v-db54c66f] {
  margin-left: 1.5625rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.type[data-v-db54c66f] {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.cond[data-v-db54c66f] {
  margin-left: 0.625rem;
}
.headersgrid[data-v-db54c66f],
.item[data-v-db54c66f] {
  display: grid;
  grid-template-columns: 1fr 1fr 1.1fr 0.8fr;
  align-items: center;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .item[data-v-db54c66f] {
  background-color: #3b435e;
}
.headersgrid div[data-v-db54c66f],
.item div[data-v-db54c66f] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.visible[data-v-db54c66f] {
  overflow: visible !important;
}
.transaction-history .item img[data-v-db54c66f] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.item[data-v-db54c66f] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 14px 17px;
  margin-bottom: 0.3125rem;
  font-weight: 300;
}
.headersgrid[data-v-db54c66f] {
  padding: 0 1.0625rem;
  font-weight: 500;
}
.transaction-history .headersgrid[data-v-db54c66f] {
  padding: 0 2rem;
}
.transaction-history .item[data-v-db54c66f] {
  padding: 0.375rem 0.625rem;
}
.transaction-history .headersgrid[data-v-db54c66f],
.transaction-history .item[data-v-db54c66f] {
  display: grid;
  grid-template-columns: 3.9375rem 1fr 0.4fr 0.6fr !important;
  grid-gap: 0.625rem !important;
}
.dark .transaction-history .item[data-v-db54c66f] {
  background-color: #3b435e;
}
.pricing .blue[data-v-db54c66f] {
  background: transparent !important;
}
.items[data-v-db54c66f] {
  max-height: 18.85rem;
  overflow-y: auto;
  padding: 0.75rem 1rem;
  margin-right: 0.375rem;
  padding-right: 0.375rem;
}
.transaction-history .items[data-v-db54c66f] {
  max-height: 41.35rem;
}
.transaction-history-owner .items[data-v-db54c66f] {
  max-height: 20rem !important;
}
.headerscotainer[data-v-db54c66f] {
  padding: 0 1rem;
}
.mt-15[data-v-db54c66f] {
  margin-bottom: 0.3rem;
}
.mt-0[data-v-db54c66f] {
  margin-top: 0 !important;
}
.mt-18[data-v-db54c66f] {
  margin-top: 1.125rem !important;
}
.home-categories[data-v-db54c66f] {
  padding: 1rem 1.125rem;
}
.home-categories .query[data-v-db54c66f],
.home-categories .value[data-v-db54c66f] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pr-10[data-v-db54c66f] {
  padding-right: 4rem;
}
.staff-overviews .items[data-v-db54c66f] {
  max-height: 13rem;
}
.item img[data-v-db54c66f] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.total[data-v-db54c66f] {
  margin-left: -3.5rem;
  font-weight: 600;
}
.totaltext[data-v-db54c66f] {
  color: #abafd1;
}
.dark .totaltext[data-v-db54c66f] {
  color: #5a5d74;
}
.admin-items .headersgrid[data-v-db54c66f],
.admin-items .item[data-v-db54c66f] {
  grid-template-columns: 0.5fr 1fr 0.8fr 0.6fr !important;
}
.admin-items .item img[data-v-db54c66f] {
  margin-left: 0.3125rem;
}
.customer-details .items[data-v-db54c66f] {
  max-height: 15rem;
}
.customer-details .item[data-v-db54c66f] {
  grid-template-columns: 1fr 1fr !important;
}
.twogrid-section[data-v-db54c66f] {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.headersection[data-v-d742d77b] {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  padding: 0.8125rem 1.25rem;
  padding-bottom: 0;
}
.headersection[data-v-d742d77b]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-d742d77b]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.green[data-v-d742d77b] {
  color: #12c519;
}
.black[data-v-d742d77b] {
  margin-right: 0.3125rem;
  color: #323a52 !important;
}
.dark .black[data-v-d742d77b] {
  color: #f6f7ff !important;
}
.itemssold[data-v-d742d77b] {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.35rem;
  margin-left: 0.625rem;
}
.al-fe[data-v-d742d77b] {
  align-items: flex-end;
}
.px-20[data-v-d742d77b] {
  padding: 0 1.25rem;
}
.headers[data-v-d742d77b] {
  margin-bottom: 0.875rem;
}
.bold[data-v-d742d77b] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
}
.transaction-history[data-v-d742d77b] {
  grid-row: span 2;
}
.type[data-v-d742d77b]:not(:last-child) {
  margin-right: 1.5625rem;
}
.type[data-v-d742d77b] {
  color: #abafd1;
  cursor: pointer;
}
.type.active[data-v-d742d77b] {
  color: #323a52;
}
.dark .type[data-v-d742d77b] {
  color: #5a5d74;
}
.dark .type.active[data-v-d742d77b] {
  color: #f6f7ff;
}

.items[data-v-d742d77b] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(23.6875rem, 1fr));
  grid-gap: 2rem;
}
.item[data-v-d742d77b] {
  min-height: 24.5625rem;
}
.items-container[data-v-d742d77b] {
  padding-top: 0.5rem;
  max-height: 85vh;
  overflow-x: auto;
  padding-bottom: 2rem;
}
.threegrid[data-v-d742d77b] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 19.5625rem 26.25rem;
  grid-gap: 1.8125rem;
}
.box[data-v-d742d77b] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.span-2[data-v-d742d77b] {
  grid-column: span 2;
}
.twogrid[data-v-d742d77b] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.inventory-container[data-v-3360f0cd] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding: 0.8125rem 0;
}
.dark .inventory-container[data-v-3360f0cd] {
  background: #353c53;
}
.headersection[data-v-3360f0cd] {
  position: relative;
  padding: 0 1rem;
}
.headersection[data-v-3360f0cd]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-3360f0cd]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.purple[data-v-3360f0cd] {
  font-weight: 500;
  color: #767bfa;
}
.query[data-v-3360f0cd]:not(:last-child) {
  margin-right: 2.5rem;
}
.query[data-v-3360f0cd] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.smtext[data-v-3360f0cd] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.queryvalue[data-v-3360f0cd] {
  font-weight: 300;

  margin-left: 0.625rem;
}
.green[data-v-3360f0cd] {
  color: #12c519;
}

.header[data-v-98ac83b3] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-98ac83b3] {
  cursor: pointer;
}
.input[data-v-98ac83b3] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-98ac83b3] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-98ac83b3] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-98ac83b3] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-98ac83b3] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-98ac83b3] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-98ac83b3] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-98ac83b3] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-98ac83b3] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-98ac83b3] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-98ac83b3] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-98ac83b3] {
  font-size: 1.125rem;
}
.readOnly[data-v-98ac83b3] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-98ac83b3] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-98ac83b3] {
  grid-column: span 2;
}
.breakup[data-v-98ac83b3] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-98ac83b3] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-98ac83b3] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-98ac83b3] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-98ac83b3] {
  justify-content: space-evenly;
}
.jc-sb[data-v-98ac83b3] {
  justify-content: space-between;
}
.btns[data-v-98ac83b3] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-98ac83b3] {
  padding: 0 1.25rem;
}
.pt-10[data-v-98ac83b3] {
  padding-top: 0.625rem;
}
.table-container[data-v-98ac83b3] {
  margin: 0 0.625rem;
}
.span-2[data-v-98ac83b3] {
  grid-column: 2/4;
}
.green[data-v-98ac83b3] {
  color: #12c519;
}
.mb-20[data-v-98ac83b3] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-98ac83b3] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-98ac83b3] {
  padding: 0 1.25rem;
}
.lg-header[data-v-98ac83b3] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-98ac83b3] {
  cursor: pointer;
}
.search-input-cm[data-v-98ac83b3] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-98ac83b3] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-98ac83b3],
.sm-item-grid[data-v-98ac83b3] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-98ac83b3] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-98ac83b3] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-98ac83b3] {
  background: #3b435e;
}
.sm-items[data-v-98ac83b3] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-98ac83b3] {
  background: #3b435e;
}
.col-grid[data-v-98ac83b3] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-98ac83b3] {
  font-weight: 500;
}
.list-item[data-v-98ac83b3] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-98ac83b3] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-98ac83b3] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-98ac83b3] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-98ac83b3] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

th[data-v-a0d26483] {
  background-color: #f6f7ff;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  border: none;
}
.dark th[data-v-a0d26483] {
  background-color: #323a52;
}
table[data-v-a0d26483] {
  border-spacing: 0 0.3125rem;
}
th[data-v-a0d26483] {
  padding: 0;
}
td[data-v-a0d26483] {
  padding: 0.3125rem 0;
  max-width: 10ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-weight: 300;
}
td[data-v-a0d26483]:first-child {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
td[data-v-a0d26483]:last-child {
  padding-right: 0.625rem;
}
td[data-v-a0d26483]:nth-child(3) {
  padding-right: 1.425rem;
}
tr[data-v-a0d26483] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
}
.dark tr[data-v-a0d26483] {
  background-color: #3b435e;
  color: #f6f7ff;
}
.dark td[data-v-a0d26483],
.dark th[data-v-a0d26483] {
  color: #f6f7ff;
}
.shadow-none[data-v-a0d26483] {
  box-shadow: none !important;
}
.dark .shadow-none[data-v-a0d26483] {
  background-color: #323a52;
}
.table[data-v-a0d26483] {
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.625rem;
  margin: 0 0.625remq;
}
.sku img[data-v-a0d26483] {
  max-width: 3.9375rem;
  height: auto;
}
.dark .sku img[data-v-a0d26483] {
  max-width: none;
  width: 100%;
  height: 2.8125rem;
  object-fit: contain;
  background-color: #fff;
  border-radius: 0.3125rem;
}
td[data-v-a0d26483] {
  border-bottom: 0;
}
tr td[data-v-a0d26483]:first-child {
  border-top-left-radius: 0.4375rem;
}
tr td[data-v-a0d26483]:last-child {
  border-top-right-radius: 0.4375rem;
}
tr td[data-v-a0d26483]:first-child {
  border-bottom-left-radius: 0.4375rem;
}
tr td[data-v-a0d26483]:last-child {
  border-bottom-right-radius: 0.4375rem;
}
tr th[data-v-a0d26483]:first-child {
  padding-left: 1rem;
}
.capitalize[data-v-a0d26483] {
  text-transform: capitalize;
}
.green[data-v-a0d26483] {
  color: #8FF093;
}
.headers-grid[data-v-20b380fb] {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.2fr 0.9fr 1.15fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid.listed[data-v-20b380fb] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr  1.25fr 1.25fr 1.1fr 1.1fr 2.8fr 0.6fr 1fr 0.9fr;
}
.item-grid[data-v-20b380fb] {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.2fr 0.9fr 1.15fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  height: 3.125rem;
  align-items: center;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid.listed[data-v-20b380fb] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr  1.25fr 1.25fr 1.1fr 1.1fr 2.8fr 0.6fr 1fr 0.9fr;
}
.dark .item-grid[data-v-20b380fb] {
  background: #3b435e;
}
.item-grid[data-v-20b380fb]:hover {
  border-color: #767bfa;
}
.item-grid div[data-v-20b380fb],
.headers-grid div[data-v-20b380fb] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-grid svg[data-v-20b380fb] {
  cursor: pointer;
}
.green[data-v-20b380fb] {
  color: #12c519;
}
.items-container[data-v-20b380fb] {
  max-height: 41.15rem;
  overflow-y: auto;
  margin-right: 0.625rem;
}
.items[data-v-20b380fb] {
  padding: 0 1rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
}
.capitalize[data-v-20b380fb] {
  text-transform: capitalize;
}
.red[data-v-20b380fb] {
  color: rgb(250, 118, 118);
}
.consignee[data-v-20b380fb] {
  grid-template-columns: 1fr 1fr 2fr 1.8fr 1.5fr 1.5fr 1.5fr 1.25fr 1.2fr 1fr;
  grid-gap: 0.375rem;
}
.consignee-summary[data-v-20b380fb] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.45fr !important;
  grid-gap: 0.375rem;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}
.expense-grid[data-v-20b380fb] {
  grid-template-columns: repeat(9, 1fr) !important;
}
.items .btnWrap[data-v-20b380fb] {
  overflow: visible !important;
}
.purple[data-v-20b380fb] {
  color: #767BFA;
}
.header[data-v-b1023abf] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-b1023abf] {
  cursor: pointer;
}
.input[data-v-b1023abf] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-b1023abf] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-b1023abf] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-b1023abf] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-b1023abf] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-b1023abf] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-b1023abf] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-b1023abf] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-b1023abf] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-b1023abf] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-b1023abf] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-b1023abf] {
  font-size: 1.125rem;
}
.readOnly[data-v-b1023abf] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-b1023abf] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-b1023abf] {
  grid-column: span 2;
}
.breakup[data-v-b1023abf] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-b1023abf] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-b1023abf] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-b1023abf] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-b1023abf] {
  justify-content: space-evenly;
}
.jc-sb[data-v-b1023abf] {
  justify-content: space-between;
}
.btns[data-v-b1023abf] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-b1023abf] {
  padding: 0 1.25rem;
}
.pt-10[data-v-b1023abf] {
  padding-top: 0.625rem;
}
.table-container[data-v-b1023abf] {
  margin: 0 0.625rem;
}
.span-2[data-v-b1023abf] {
  grid-column: 2/4;
}
.green[data-v-b1023abf] {
  color: #12c519;
}
.mb-20[data-v-b1023abf] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-b1023abf] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-b1023abf] {
  padding: 0 1.25rem;
}
.lg-header[data-v-b1023abf] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-b1023abf] {
  cursor: pointer;
}
.search-input-cm[data-v-b1023abf] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-b1023abf] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-b1023abf],
.sm-item-grid[data-v-b1023abf] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-b1023abf] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-b1023abf] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-b1023abf] {
  background: #3b435e;
}
.sm-items[data-v-b1023abf] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-b1023abf] {
  background: #3b435e;
}
.col-grid[data-v-b1023abf] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-b1023abf] {
  font-weight: 500;
}
.list-item[data-v-b1023abf] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-b1023abf] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-b1023abf] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-b1023abf] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-b1023abf] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

th[data-v-282ed2c5] {
  background-color: #f6f7ff;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  border: none;
}
.dark th[data-v-282ed2c5] {
  background-color: #323a52;
}
table[data-v-282ed2c5] {
  border-spacing: 0 0.3125rem;
}
th[data-v-282ed2c5] {
  padding: 0;
}
td[data-v-282ed2c5] {
  padding: 0.3125rem 0;
  max-width: 10ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td[data-v-282ed2c5]:first-child,
td[data-v-282ed2c5]:last-child {
  padding: 0.3125rem 0.625rem;
}
tr[data-v-282ed2c5] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
}
.dark tr[data-v-282ed2c5] {
  background-color: #3b435e;
  color: #f6f7ff;
}
.dark td[data-v-282ed2c5],
.dark th[data-v-282ed2c5] {
  color: #f6f7ff;
}
.shadow-none[data-v-282ed2c5] {
  box-shadow: none !important;
}
.dark .shadow-none[data-v-282ed2c5] {
  background-color: #323a52;
}
.table[data-v-282ed2c5] {
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.625rem;
  margin: 0 0.625remq;
}
.sku img[data-v-282ed2c5] {
  max-width: 3.9375rem;
  height: auto;
}
.dark .sku img[data-v-282ed2c5] {
  max-width: none;
  width: 100%;
  height: 2.8125rem;
  object-fit: contain;
  background-color: #fff;
  border-radius: 0.3125rem;
}
td[data-v-282ed2c5] {
  border-bottom: 0;
}
tr td[data-v-282ed2c5]:first-child {
  border-top-left-radius: 0.4375rem;
}
tr td[data-v-282ed2c5]:last-child {
  border-top-right-radius: 0.4375rem;
}
tr td[data-v-282ed2c5]:first-child {
  border-bottom-left-radius: 0.4375rem;
}
tr td[data-v-282ed2c5]:last-child {
  border-bottom-right-radius: 0.4375rem;
}
tr th[data-v-282ed2c5]:first-child {
  padding-left: 1rem;
}
.capitalize[data-v-282ed2c5] {
  text-transform: capitalize;
}


.header[data-v-1ee26c62] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-1ee26c62] {
  cursor: pointer;
}
.input[data-v-1ee26c62] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-1ee26c62] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-1ee26c62] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-1ee26c62] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-1ee26c62] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-1ee26c62] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-1ee26c62] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-1ee26c62] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-1ee26c62] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-1ee26c62] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-1ee26c62] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-1ee26c62] {
  font-size: 1.125rem;
}
.readOnly[data-v-1ee26c62] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-1ee26c62] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-1ee26c62] {
  grid-column: span 2;
}
.breakup[data-v-1ee26c62] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-1ee26c62] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-1ee26c62] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-1ee26c62] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-1ee26c62] {
  justify-content: space-evenly;
}
.jc-sb[data-v-1ee26c62] {
  justify-content: space-between;
}
.btns[data-v-1ee26c62] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-1ee26c62] {
  padding: 0 1.25rem;
}
.pt-10[data-v-1ee26c62] {
  padding-top: 0.625rem;
}
.table-container[data-v-1ee26c62] {
  margin: 0 0.625rem;
}
.span-2[data-v-1ee26c62] {
  grid-column: 2/4;
}
.green[data-v-1ee26c62] {
  color: #12c519;
}
.mb-20[data-v-1ee26c62] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-1ee26c62] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-1ee26c62] {
  padding: 0 1.25rem;
}
.lg-header[data-v-1ee26c62] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-1ee26c62] {
  cursor: pointer;
}
.search-input-cm[data-v-1ee26c62] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-1ee26c62] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-1ee26c62],
.sm-item-grid[data-v-1ee26c62] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-1ee26c62] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-1ee26c62] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-1ee26c62] {
  background: #3b435e;
}
.sm-items[data-v-1ee26c62] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-1ee26c62] {
  background: #3b435e;
}
.col-grid[data-v-1ee26c62] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-1ee26c62] {
  font-weight: 500;
}
.list-item[data-v-1ee26c62] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-1ee26c62] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-1ee26c62] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-1ee26c62] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-1ee26c62] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

th[data-v-c3c3fc61] {
  background-color: #f6f7ff;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  border: none;
}
.dark th[data-v-c3c3fc61] {
  background-color: #323a52;
}
table[data-v-c3c3fc61] {
  border-spacing: 0 0.3125rem;
}
th[data-v-c3c3fc61] {
  padding: 0;
}
td[data-v-c3c3fc61] {
  padding: 0.3125rem 0;
  max-width: 10ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td[data-v-c3c3fc61]:first-child,
td[data-v-c3c3fc61]:last-child {
  padding: 0.3125rem 0.625rem;
}
tr[data-v-c3c3fc61] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
}
.dark tr[data-v-c3c3fc61] {
  background-color: #3b435e;
  color: #f6f7ff;
}
.dark td[data-v-c3c3fc61],
.dark th[data-v-c3c3fc61] {
  color: #f6f7ff;
}
.shadow-none[data-v-c3c3fc61] {
  box-shadow: none !important;
}
.dark .shadow-none[data-v-c3c3fc61] {
  background-color: #323a52;
}
.table[data-v-c3c3fc61] {
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.625rem;
  margin: 0 0.625remq;
}
.sku img[data-v-c3c3fc61] {
  max-width: 3.9375rem;
  height: auto;
}
.dark .sku img[data-v-c3c3fc61] {
  max-width: none;
  width: 100%;
  height: 2.8125rem;
  object-fit: contain;
  background-color: #fff;
  border-radius: 0.3125rem;
}
td[data-v-c3c3fc61] {
  border-bottom: 0;
}
tr td[data-v-c3c3fc61]:first-child {
  border-top-left-radius: 0.4375rem;
}
tr td[data-v-c3c3fc61]:last-child {
  border-top-right-radius: 0.4375rem;
}
tr td[data-v-c3c3fc61]:first-child {
  border-bottom-left-radius: 0.4375rem;
}
tr td[data-v-c3c3fc61]:last-child {
  border-bottom-right-radius: 0.4375rem;
}
tr th[data-v-c3c3fc61]:first-child {
  padding-left: 1rem;
}
.capitalize[data-v-c3c3fc61] {
  text-transform: capitalize;
}


.header[data-v-fe09bc05] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-fe09bc05] {
  cursor: pointer;
}
.input[data-v-fe09bc05] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-fe09bc05] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-fe09bc05] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-fe09bc05] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-fe09bc05] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-fe09bc05] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-fe09bc05] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-fe09bc05] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-fe09bc05] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-fe09bc05] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-fe09bc05] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-fe09bc05] {
  font-size: 1.125rem;
}
.readOnly[data-v-fe09bc05] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-fe09bc05] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-fe09bc05] {
  grid-column: span 2;
}
.breakup[data-v-fe09bc05] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-fe09bc05] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-fe09bc05] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-fe09bc05] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-fe09bc05] {
  justify-content: space-evenly;
}
.jc-sb[data-v-fe09bc05] {
  justify-content: space-between;
}
.btns[data-v-fe09bc05] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-fe09bc05] {
  padding: 0 1.25rem;
}
.pt-10[data-v-fe09bc05] {
  padding-top: 0.625rem;
}
.table-container[data-v-fe09bc05] {
  margin: 0 0.625rem;
}
.span-2[data-v-fe09bc05] {
  grid-column: 2/4;
}
.green[data-v-fe09bc05] {
  color: #12c519;
}
.mb-20[data-v-fe09bc05] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-fe09bc05] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-fe09bc05] {
  padding: 0 1.25rem;
}
.lg-header[data-v-fe09bc05] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-fe09bc05] {
  cursor: pointer;
}
.search-input-cm[data-v-fe09bc05] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-fe09bc05] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-fe09bc05],
.sm-item-grid[data-v-fe09bc05] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-fe09bc05] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-fe09bc05] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-fe09bc05] {
  background: #3b435e;
}
.sm-items[data-v-fe09bc05] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-fe09bc05] {
  background: #3b435e;
}
.col-grid[data-v-fe09bc05] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-fe09bc05] {
  font-weight: 500;
}
.list-item[data-v-fe09bc05] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-fe09bc05] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-fe09bc05] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-fe09bc05] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-fe09bc05] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

.header[data-v-eb6aec64] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-eb6aec64] {
  cursor: pointer;
}
.input[data-v-eb6aec64] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-eb6aec64] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-eb6aec64] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-eb6aec64] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-eb6aec64] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-eb6aec64] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-eb6aec64] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-eb6aec64] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-eb6aec64] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-eb6aec64] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-eb6aec64] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-eb6aec64] {
  font-size: 1.125rem;
}
.readOnly[data-v-eb6aec64] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-eb6aec64] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-eb6aec64] {
  grid-column: span 2;
}
.breakup[data-v-eb6aec64] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-eb6aec64] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-eb6aec64] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-eb6aec64] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-eb6aec64] {
  justify-content: space-evenly;
}
.jc-sb[data-v-eb6aec64] {
  justify-content: space-between;
}
.btns[data-v-eb6aec64] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-eb6aec64] {
  padding: 0 1.25rem;
}
.pt-10[data-v-eb6aec64] {
  padding-top: 0.625rem;
}
.table-container[data-v-eb6aec64] {
  margin: 0 0.625rem;
}
.span-2[data-v-eb6aec64] {
  grid-column: 2/4;
}
.green[data-v-eb6aec64] {
  color: #12c519;
}
.mb-20[data-v-eb6aec64] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-eb6aec64] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-eb6aec64] {
  padding: 0 1.25rem;
}
.lg-header[data-v-eb6aec64] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-eb6aec64] {
  cursor: pointer;
}
.search-input-cm[data-v-eb6aec64] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-eb6aec64] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-eb6aec64],
.sm-item-grid[data-v-eb6aec64] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-eb6aec64] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-eb6aec64] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-eb6aec64] {
  background: #3b435e;
}
.sm-items[data-v-eb6aec64] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-eb6aec64] {
  background: #3b435e;
}
.col-grid[data-v-eb6aec64] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-eb6aec64] {
  font-weight: 500;
}
.list-item[data-v-eb6aec64] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-eb6aec64] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-eb6aec64] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-eb6aec64] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-eb6aec64] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

.inventory-container[data-v-4afc7f9a] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding: 0.8125rem 0;
}
.dark .inventory-container[data-v-4afc7f9a] {
  background: #353c53;
}
.headersection[data-v-4afc7f9a] {
  position: relative;
  padding: 0 1rem;
}
.headersection[data-v-4afc7f9a]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-4afc7f9a]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.purple[data-v-4afc7f9a] {
  font-weight: 500;
  color: #767bfa;
}
.query[data-v-4afc7f9a]:not(:last-child) {
  margin-right: 2.5rem;
}
.query[data-v-4afc7f9a] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.smtext[data-v-4afc7f9a] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.queryvalue[data-v-4afc7f9a] {
  font-weight: 300;

  margin-left: 0.625rem;
}
.green[data-v-4afc7f9a] {
  color: #12c519;
}

.headers-grid[data-v-2f3405c6] {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.2fr 0.9fr 1.15fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid.listed[data-v-2f3405c6] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr  1.25fr 1.25fr 1.1fr 1.1fr 2.8fr 0.6fr 1fr 0.9fr;
}
.item-grid[data-v-2f3405c6] {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.2fr 0.9fr 1.15fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  height: 3.125rem;
  align-items: center;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid.listed[data-v-2f3405c6] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr  1.25fr 1.25fr 1.1fr 1.1fr 2.8fr 0.6fr 1fr 0.9fr;
}
.dark .item-grid[data-v-2f3405c6] {
  background: #3b435e;
}
.item-grid[data-v-2f3405c6]:hover {
  border-color: #767bfa;
}
.item-grid div[data-v-2f3405c6],
.headers-grid div[data-v-2f3405c6] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-grid svg[data-v-2f3405c6] {
  cursor: pointer;
}
.green[data-v-2f3405c6] {
  color: #12c519;
}
.items-container[data-v-2f3405c6] {
  max-height: 41.15rem;
  overflow-y: auto;
  margin-right: 0.625rem;
}
.items[data-v-2f3405c6] {
  padding: 0 1rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
}
.capitalize[data-v-2f3405c6] {
  text-transform: capitalize;
}
.red[data-v-2f3405c6] {
  color: rgb(250, 118, 118);
}
.consignee[data-v-2f3405c6] {
  grid-template-columns: 1fr 1fr 2fr 1.8fr 1.5fr 1.5fr 1.5fr 1.25fr 1.2fr 1fr;
  grid-gap: 0.375rem;
}
.consignee-summary[data-v-2f3405c6] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.45fr !important;
  grid-gap: 0.375rem;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}
.expense-grid[data-v-2f3405c6] {
  grid-template-columns: repeat(9, 1fr) !important;
}
.items .btnWrap[data-v-2f3405c6] {
  overflow: visible !important;
}
.purple[data-v-2f3405c6] {
  color: #767BFA;
}
.inventory-container[data-v-d13225d2] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding: 0.8125rem 0;
}
.dark .inventory-container[data-v-d13225d2] {
  background: #353c53;
}
.headersection[data-v-d13225d2] {
  position: relative;
  padding: 0 1rem;
}
.headersection[data-v-d13225d2]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-d13225d2]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.purple[data-v-d13225d2] {
  font-weight: 500;
  color: #767bfa;
}
.query[data-v-d13225d2]:not(:last-child) {
  margin-right: 2.5rem;
}
.query[data-v-d13225d2] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.smtext[data-v-d13225d2] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.queryvalue[data-v-d13225d2] {
  font-weight: 300;

  margin-left: 0.625rem;
}
.green[data-v-d13225d2] {
  color: #12c519;
}

.headers-grid[data-v-67ad6364] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid.listed[data-v-67ad6364] {
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
}
.item-grid[data-v-67ad6364] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid.listed[data-v-67ad6364] {
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
}
.dark .item-grid[data-v-67ad6364] {
  background: #3b435e;
}
.item-grid[data-v-67ad6364]:hover {
  border-color: #767bfa;
}
.item-grid div[data-v-67ad6364],
.headers-grid div[data-v-67ad6364] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-grid svg[data-v-67ad6364] {
  cursor: pointer;
}
.green[data-v-67ad6364] {
  color: #12c519;
}
.date[data-v-67ad6364] {
  white-space: break-spaces !important;
  text-align: right !important;
  color: #767bfa;
}
.items-container[data-v-67ad6364] {
  max-height: 41.15rem;
  overflow-y: auto;
  margin-right: 0.625rem;
}
.items[data-v-67ad6364] {
  padding: 0 1rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
}
.capitalize[data-v-67ad6364] {
  text-transform: capitalize;
}
.red[data-v-67ad6364] {
  color: rgb(250, 118, 118);
}
.consignee[data-v-67ad6364] {
  grid-template-columns: 1fr 1fr 2fr 1.8fr 1.5fr 1.5fr 1.5fr 1.25fr 1.2fr 1fr;
  grid-gap: 0.375rem;
}
.consignee-summary[data-v-67ad6364] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.45fr !important;
  grid-gap: 0.375rem;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}
.expense-grid[data-v-67ad6364] {
  grid-template-columns: repeat(9, 1fr) !important;
}
.items .btnWrap[data-v-67ad6364] {
  overflow: visible !important;
}
.purple[data-v-67ad6364] {
  color: #767bfa;
}
.headers-grid-listed[data-v-67ad6364] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid-listed.listed[data-v-67ad6364] {
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
}
.item-grid-listed[data-v-67ad6364] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid-listed.listed[data-v-67ad6364] {
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
}

.header[data-v-e95880ab] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-e95880ab] {
  cursor: pointer;
}
.row[data-v-e95880ab] {
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.three-grid[data-v-e95880ab] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.two-grid[data-v-e95880ab] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.label[data-v-e95880ab] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.jus-end[data-v-e95880ab] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btns[data-v-e95880ab] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-e95880ab] {
  padding: 0 1.25rem;
}
.table-container[data-v-e95880ab] {
  margin: 0 0.625rem;
}
.img-upload[data-v-e95880ab] {
  background: #f6f7ff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.25rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.dark .img-upload[data-v-e95880ab] {
  background-color: #353c53;
}
.img-upload[data-v-e95880ab] {
  cursor: pointer;
}
.text[data-v-e95880ab] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.grey-text[data-v-e95880ab] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-top: 0.75rem;
}
.ticket[data-v-e95880ab] {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  padding: 0.625rem;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}
.ticket[data-v-e95880ab]:hover {
  background: #dfdfdf;
}
.ticket-img[data-v-e95880ab] {
  max-width: 7rem;
  max-height: 7rem;
  width: 100%;
  overflow: hidden;
  object-fit: fill;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
  margin-right: 0.625rem;
}
.search-results[data-v-e95880ab] {
  margin-top: 0.625rem;
  padding: 0 1.25rem;
  max-height: 40rem;
  overflow-y: auto;
}
.ticket-title[data-v-e95880ab] {
  font-size: 1.125rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-description[data-v-e95880ab] {
  font-size: 0.925rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-date[data-v-e95880ab] {
  font-size: 0.9375rem;
  font-weight: 500;
}
.ticket-container[data-v-e95880ab] {
  height: 100%;
}
.ticket-info[data-v-e95880ab] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
}

.inventory-container[data-v-f5c61770] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding: 0.8125rem 0;
}
.dark .inventory-container[data-v-f5c61770] {
  background: #353c53;
}
.headersection[data-v-f5c61770] {
  position: relative;
  padding: 0 1rem;
}
.headersection[data-v-f5c61770]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-f5c61770]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.purple[data-v-f5c61770] {
  font-weight: 500;
  color: #767bfa;
}
.query[data-v-f5c61770]:not(:last-child) {
  margin-right: 2.5rem;
}
.query[data-v-f5c61770] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.smtext[data-v-f5c61770] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.queryvalue[data-v-f5c61770] {
  font-weight: 300;

  margin-left: 0.625rem;
}
.green[data-v-f5c61770] {
  color: #12c519;
}

.headers-grid[data-v-f2ada52d] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid.listed[data-v-f2ada52d] {
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
}
.item-grid[data-v-f2ada52d] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid.listed[data-v-f2ada52d] {
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
}
.dark .item-grid[data-v-f2ada52d] {
  background: #3b435e;
}
.item-grid[data-v-f2ada52d]:hover {
  border-color: #767bfa;
}
.item-grid div[data-v-f2ada52d],
.headers-grid div[data-v-f2ada52d] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-grid svg[data-v-f2ada52d] {
  cursor: pointer;
}
.green[data-v-f2ada52d] {
  color: #12c519;
}
.date[data-v-f2ada52d] {
  white-space: break-spaces !important;
  text-align: right !important;
  color: #767bfa;
}
.items-container[data-v-f2ada52d] {
  max-height: 41.15rem;
  overflow-y: auto;
  margin-right: 0.625rem;
}
.items[data-v-f2ada52d] {
  padding: 0 1rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
}
.capitalize[data-v-f2ada52d] {
  text-transform: capitalize;
}
.red[data-v-f2ada52d] {
  color: rgb(250, 118, 118);
}
.consignee[data-v-f2ada52d] {
  grid-template-columns: 1fr 1fr 2fr 1.8fr 1.5fr 1.5fr 1.5fr 1.25fr 1.2fr 1fr;
  grid-gap: 0.375rem;
}
.consignee-summary[data-v-f2ada52d] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.45fr !important;
  grid-gap: 0.375rem;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}
.expense-grid[data-v-f2ada52d] {
  grid-template-columns: repeat(9, 1fr) !important;
}
.items .btnWrap[data-v-f2ada52d] {
  overflow: visible !important;
}
.purple[data-v-f2ada52d] {
  color: #767bfa;
}
.headers-grid-listed[data-v-f2ada52d] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid-listed.listed[data-v-f2ada52d] {
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
}
.item-grid-listed[data-v-f2ada52d] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid-listed.listed[data-v-f2ada52d] {
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
}

.header[data-v-d9c938bf] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-d9c938bf] {
  cursor: pointer;
}
.input[data-v-d9c938bf] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-d9c938bf] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-d9c938bf] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-d9c938bf] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-d9c938bf] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-d9c938bf] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-d9c938bf] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-d9c938bf] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-d9c938bf] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-d9c938bf] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-d9c938bf] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-d9c938bf] {
  font-size: 1.125rem;
}
.readOnly[data-v-d9c938bf] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-d9c938bf] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-d9c938bf] {
  grid-column: span 2;
}
.breakup[data-v-d9c938bf] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-d9c938bf] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-d9c938bf] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-d9c938bf] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-d9c938bf] {
  justify-content: space-evenly;
}
.jc-sb[data-v-d9c938bf] {
  justify-content: space-between;
}
.btns[data-v-d9c938bf] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-d9c938bf] {
  padding: 0 1.25rem;
}
.pt-10[data-v-d9c938bf] {
  padding-top: 0.625rem;
}
.table-container[data-v-d9c938bf] {
  margin: 0 0.625rem;
}
.span-2[data-v-d9c938bf] {
  grid-column: 2/4;
}
.green[data-v-d9c938bf] {
  color: #12c519;
}
.mb-20[data-v-d9c938bf] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-d9c938bf] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-d9c938bf] {
  padding: 0 1.25rem;
}
.lg-header[data-v-d9c938bf] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-d9c938bf] {
  cursor: pointer;
}
.search-input-cm[data-v-d9c938bf] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-d9c938bf] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-d9c938bf],
.sm-item-grid[data-v-d9c938bf] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-d9c938bf] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-d9c938bf] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-d9c938bf] {
  background: #3b435e;
}
.sm-items[data-v-d9c938bf] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-d9c938bf] {
  background: #3b435e;
}
.col-grid[data-v-d9c938bf] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-d9c938bf] {
  font-weight: 500;
}
.list-item[data-v-d9c938bf] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-d9c938bf] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-d9c938bf] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-d9c938bf] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-d9c938bf] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

.productWrapper[data-v-8c625b49] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  border-radius: 0.625rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding-bottom: 1.0625rem;
  height: 100%;
}
.dark .productWrapper[data-v-8c625b49] {
  background: #353c53;
}
.categoryBox[data-v-8c625b49] {
  background-color: #7983d0;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 2.0625rem;
  text-align: center;
  color: #f6f7ff;
}
.dark .categoryBox[data-v-8c625b49] {
  color: #353c53;
}
.shoeImg[data-v-8c625b49] {
  margin-top: 0.625rem;
  height: 3.9375rem;
  border-radius: 0.4375rem;
  margin-bottom: 0.625rem;
  filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  -webkit-filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
}
.prodTitle[data-v-8c625b49] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
}
.model[data-v-8c625b49] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;

  margin: 0.3125rem 0;
}
.style[data-v-8c625b49] {
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-top: 0.3125rem;
}
.style span[data-v-8c625b49] {
  font-weight: 600;
}
.whiteBoxWrap[data-v-8c625b49] {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 1.25rem;
}
.qr[data-v-8c625b49] {
  border-radius: 0.4375rem;
  margin-top: 0.625rem;
  margin-bottom: 0.875rem;
  padding: 0.75rem;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.25);
  border-radius: 0.4375rem;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
}

/* .title {
  position: absolute;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.75);
  top: 50%;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0 0.25rem;
  font-size: 0.625rem;
  opacity: 0.5;
  color: #fff;
  pointer-events: none;
  text-align: center;
  width: max-content;
  height: max-content;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
} */
.qrcontainer[data-v-8c625b49] {
  display: flex;
  align-items: center;
}
.qr-print[data-v-8c625b49] {
  width: 30px;
  height: 30px;
}
.sneakers[data-v-8c625b49] {
  background-color: #7983d0;  /* blue */
}
.streetwear[data-v-8c625b49] {
  background-color: #79c1d0;  /* green */
}
.collectibles[data-v-8c625b49] {
  background-color: #d07979;  /* brown */
}
.arts[data-v-8c625b49] {
  background-color: #d079c8;  /* pink */
}
.electronics[data-v-8c625b49] {
  background-color: #d0a879;  /* orange */
}
.watches[data-v-8c625b49] {
  background-color: #aad079;  /* lightgreen */
}

.productWrapper[data-v-e41b2608] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  border-radius: 0.625rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding-bottom: 1.0625rem;
  height: 100%;
}
.dark .productWrapper[data-v-e41b2608] {
  background: #353c53;
}
.categoryBox[data-v-e41b2608] {
  background-color: #7983d0;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 2.0625rem;
  text-align: center;
  color: #f6f7ff;
}
.dark .item[data-v-e41b2608] {
  color: #3b435e;
}
.shoeImgnew[data-v-e41b2608] {
  margin-top: 0.625rem;
  height: 3.9375rem;
  border-radius: 0.4375rem;
  margin-bottom: 0.625rem;
  -webkit-filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  /* width: 7.375rem;
  object-fit: fill; */
}
.prodTitle1[data-v-e41b2608],
.heading[data-v-e41b2608] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
}
.prodTitle1[data-v-e41b2608] {
  margin-bottom: 0.3125rem;
}
.heading[data-v-e41b2608] {
  font-weight: 400;
  margin-bottom: 0.625rem;
  text-transform: capitalize;
}
.sideDiv[data-v-e41b2608] {
  text-align: start;
  width: 100%;

  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  padding: 0.625rem 1.25rem;
  margin-bottom: 2.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.newstyle span[data-v-e41b2608],
.size span[data-v-e41b2608],
.consignment span[data-v-e41b2608] {
  font-weight: 600;
  margin-right: 0.625rem;
}
.newstyle[data-v-e41b2608],
.size[data-v-e41b2608],
.consignment[data-v-e41b2608] {
  margin-bottom: 0.3pc;
}
.whiteBoxWrapnew[data-v-e41b2608] {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 1.25rem;
}
.barcode[data-v-e41b2608] {
  width: 100%;
}
.sneakers[data-v-e41b2608] {
  background-color: #7983d0;  /* blue */
}
.streetwear[data-v-e41b2608] {
  background-color: #79c1d0;  /* green */
}
.collectibles[data-v-e41b2608] {
  background-color: #d07979;  /* brown */
}
.arts[data-v-e41b2608] {
  background-color: #d079c8;  /* pink */
}
.electronics[data-v-e41b2608] {
  background-color: #d0a879;  /* orange */
}
.watches[data-v-e41b2608] {
  background-color: #aad079;  /* lightgreen */
}

.productCardsWrapper[data-v-3c66549a] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(21.6875rem, 1fr));
  grid-gap: 2rem;
  max-height: 80vh;
  overflow-y: auto;
  padding-bottom: 2rem;
}
.productWrapperNormal[data-v-3c66549a] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(21.6875rem, 1fr));
  grid-gap: 2rem;
  max-height: 80vh;
  overflow-y: auto;
  padding-bottom: 2rem;
}
.box[data-v-3c66549a] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
}
.receipt-container[data-v-3c66549a] {
  padding: 0.8125rem 0;
  padding-bottom: 2rem;
}
.dark .box[data-v-3c66549a] {
  background: #353c53;
}
.headersection[data-v-3c66549a] {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  padding: 0rem 1.25rem;
  padding-bottom: 0;
}
.headersection[data-v-3c66549a]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-3c66549a]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.btn-container[data-v-3c66549a] {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 16.25rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5625rem rgb(0 0 0 / 15%);
  border-radius: 0.3125rem;
  width: -webkit-max-content;
  width: max-content;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  min-height: 1.75rem;
  cursor: pointer;
  color: #abafd1;
}
.mr-6 svg[data-v-3c66549a] {
  margin-right: 0.625rem;
}
.dark .btn-container[data-v-3c66549a] {
  background-color: #353c53;
  color: #5a5d74;
}
.scan-barcode[data-v-3c66549a] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.receipt-container[data-v-3f070859] {
  display: grid;
  grid-template-columns: 26.6875rem 26.6875rem 21.5625rem 1fr;
  grid-gap: 1.875rem;
  padding: 0.625rem 1.25rem;
}
.qr-container[data-v-3f070859] {
  display: grid;
  grid-template-columns: 0.85fr 1fr 1fr;
  grid-gap: 1.875rem;
  padding: 0.625rem 1.25rem;
}
.wrap[data-v-3f070859] {
  grid-gap: 1.5625rem 3rem;
  display: grid;
  grid-template-columns: repeat(5, 14.0625rem);
  padding: 0 1.25rem;
}
.label[data-v-3f070859] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.5625rem;
  margin-bottom: 0.75rem;
}
.btncontainer[data-v-3f070859] {
  grid-column: span 5;
  margin-top: 1.875rem;
  justify-content: flex-end;
}
.mt-custom[data-v-3f070859] {
  margin-top: 1.25rem;
}
.mr-20[data-v-3f070859] {
  margin-right: 1.25rem;
}
.color-container[data-v-3f070859] {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.2rem;
  padding-right: 0.9375rem;
  max-width: 14.0625rem;
  width: 100%;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border: none;
  outline: none;
  height: 3.1875rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  position: relative;
}
.color-container input[data-v-3f070859] {
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.dark .color-container[data-v-3f070859] {
  background-color: #3b435e;
}

.box[data-v-e59f250e] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  padding: 1.25rem;
}
.dark .box[data-v-e59f250e] {
  background-color: #3b435e;
}
.smbox[data-v-e59f250e] {
  max-height: 13.9375rem;
  height: 100%;
}
.label[data-v-e59f250e] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.5625rem;
  margin-bottom: 0.75rem;
}
.lighttext[data-v-e59f250e] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-bottom: 1rem;
}
.twogrid[data-v-e59f250e] {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.0625rem;
}
.upload[data-v-e59f250e] {
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 3.4375rem;
  overflow: hidden;
}
.dark .upload[data-v-e59f250e] {
  background-color: #464f6d;
}
.upload img[data-v-e59f250e] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.smlabel[data-v-e59f250e] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.4375rem;
  padding-left: 0.5rem;
}
.smlabel svg[data-v-e59f250e],
.smlabel img[data-v-e59f250e] {
  margin-right: 0.625rem;
}
.smlabel img[data-v-e59f250e] {
  height: 1.125rem;
  width: 1.125rem;
}
.mb-13[data-v-e59f250e] {
  margin-bottom: 0.8125rem;
}
.rect[data-v-e59f250e] {
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  width: 100%;
  height: 100%;
  margin-bottom: 1.25rem;
  max-height: 8.4375rem;
}
.rect img[data-v-e59f250e] {
  object-fit: cover;
}
.equal[data-v-e59f250e] {
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.box[data-v-fcb9019e] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  padding: 1.25rem;
}
.dark .box[data-v-fcb9019e] {
  background-color: #3b435e;
}
.smbox[data-v-fcb9019e] {
  max-height: 13.9375rem;
  height: 100%;
}
.label[data-v-fcb9019e] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.5625rem;
  margin-bottom: 0.75rem;
}
.lighttext[data-v-fcb9019e] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-bottom: 1rem;
}
.twogrid[data-v-fcb9019e] {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.0625rem;
}
.upload[data-v-fcb9019e] {
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 3.4375rem;
  overflow: hidden;
}
.dark .upload[data-v-fcb9019e] {
  background-color: #464f6d;
}
.upload img[data-v-fcb9019e] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.smlabel[data-v-fcb9019e] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.4375rem;
  padding-left: 0.5rem;
}
.smlabel svg[data-v-fcb9019e],
.smlabel img[data-v-fcb9019e] {
  margin-right: 0.625rem;
}
.smlabel img[data-v-fcb9019e] {
  height: 1.125rem;
  width: 1.125rem;
}
.mb-13[data-v-fcb9019e] {
  margin-bottom: 0.8125rem;
}
.rect[data-v-fcb9019e] {
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  width: 100%;
  height: 100%;
  margin-bottom: 1.25rem;
  max-height: 8.4375rem;
}
.rect img[data-v-fcb9019e] {
  object-fit: cover;
}
.equal[data-v-fcb9019e] {
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.box[data-v-1de33d5b] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  padding: 1.25rem;
}
.dark .box[data-v-1de33d5b] {
  background-color: #3b435e;
}
.smbox[data-v-1de33d5b] {
  max-height: 13.9375rem;
  height: 100%;
}
.label[data-v-1de33d5b] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.5625rem;
  margin-bottom: 0.75rem;
}
.lighttext[data-v-1de33d5b] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-bottom: 1rem;
}
.twogrid[data-v-1de33d5b] {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.0625rem;
}
.upload[data-v-1de33d5b] {
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 3.4375rem;
  overflow: hidden;
}
.dark .upload[data-v-1de33d5b] {
  background-color: #464f6d;
}
.upload img[data-v-1de33d5b] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.smlabel[data-v-1de33d5b] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.4375rem;
  padding-left: 0.5rem;
}
.smlabel svg[data-v-1de33d5b],
.smlabel img[data-v-1de33d5b] {
  margin-right: 0.625rem;
}
.smlabel img[data-v-1de33d5b] {
  height: 1.125rem;
  width: 1.125rem;
}
.mb-13[data-v-1de33d5b] {
  margin-bottom: 0.8125rem;
}
.rect[data-v-1de33d5b] {
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  width: 100%;
  height: 100%;
  margin-bottom: 1.25rem;
  max-height: 8.4375rem;
}
.rect img[data-v-1de33d5b] {
  object-fit: cover;
}
.equal[data-v-1de33d5b] {
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.box[data-v-48c8a742] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  padding: 1.25rem;
}
.dark .box[data-v-48c8a742] {
  background-color: #3b435e;
}
.smbox[data-v-48c8a742] {
  max-height: 13.9375rem;
  height: 100%;
}
.label[data-v-48c8a742] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.5625rem;
  margin-bottom: 0.75rem;
}
.lighttext[data-v-48c8a742] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-bottom: 1rem;
}
.twogrid[data-v-48c8a742] {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.0625rem;
}
.upload[data-v-48c8a742] {
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 3.4375rem;
  overflow: hidden;
}
.dark .upload[data-v-48c8a742] {
  background-color: #464f6d;
}
.upload img[data-v-48c8a742] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.smlabel[data-v-48c8a742] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.4375rem;
  padding-left: 0.5rem;
}
.smlabel svg[data-v-48c8a742],
.smlabel img[data-v-48c8a742] {
  margin-right: 0.625rem;
}
.smlabel img[data-v-48c8a742] {
  height: 1.125rem;
  width: 1.125rem;
}
.mb-13[data-v-48c8a742] {
  margin-bottom: 0.8125rem;
}
.rect[data-v-48c8a742] {
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  width: 100%;
  height: 100%;
  margin-bottom: 1.25rem;
  max-height: 8.4375rem;
}
.rect img[data-v-48c8a742] {
  object-fit: cover;
}
.equal[data-v-48c8a742] {
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.headers-grid[data-v-2d7be8ba] {
  display: grid;
  grid-template-columns: 0.75fr 0.5fr 0.3fr 0.5fr 1.25fr 0.50fr 0.35fr 0.25fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.item-grid[data-v-2d7be8ba] {
  display: grid;
  grid-template-columns: 0.75fr 0.5fr 0.3fr 0.5fr 1.25fr 0.50fr 0.35fr 0.25fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  height: 3.125rem;
  align-items: center;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.dark .item-grid[data-v-2d7be8ba] {
  background: #3b435e;
}
.item-grid[data-v-2d7be8ba]:hover {
  border-color: #767bfa;
}
.item-grid div[data-v-2d7be8ba],
.headers-grid div[data-v-2d7be8ba] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-grid svg[data-v-2d7be8ba] {
  cursor: pointer;
}
.green[data-v-2d7be8ba] {
  color: #12c519;
}
.items-container[data-v-2d7be8ba] {
  max-height: 41.15rem;
  overflow-y: auto;
  margin-right: 0.625rem;
}
.items[data-v-2d7be8ba] {
  padding: 0 1rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
}
.capitalize[data-v-2d7be8ba] {
  text-transform: capitalize;
}
.red[data-v-2d7be8ba] {
  color: rgb(250, 118, 118);
}
.left[data-v-2d7be8ba] {
  text-align: left !important;
}

.header[data-v-64e4bc74] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-64e4bc74] {
  cursor: pointer;
}
.input[data-v-64e4bc74] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-64e4bc74] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-64e4bc74] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-64e4bc74] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-64e4bc74] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-64e4bc74] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-64e4bc74] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-64e4bc74] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-64e4bc74] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-64e4bc74] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-64e4bc74] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-64e4bc74] {
  font-size: 1.125rem;
}
.readOnly[data-v-64e4bc74] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-64e4bc74] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-64e4bc74] {
  grid-column: span 2;
}
.breakup[data-v-64e4bc74] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-64e4bc74] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-64e4bc74] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-64e4bc74] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-64e4bc74] {
  justify-content: space-evenly;
}
.jc-sb[data-v-64e4bc74] {
  justify-content: space-between;
}
.btns[data-v-64e4bc74] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-64e4bc74] {
  padding: 0 1.25rem;
}
.pt-10[data-v-64e4bc74] {
  padding-top: 0.625rem;
}
.table-container[data-v-64e4bc74] {
  margin: 0 0.625rem;
}
.span-2[data-v-64e4bc74] {
  grid-column: 2/4;
}
.green[data-v-64e4bc74] {
  color: #12c519;
}
.mb-20[data-v-64e4bc74] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-64e4bc74] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-64e4bc74] {
  padding: 0 1.25rem;
}
.lg-header[data-v-64e4bc74] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-64e4bc74] {
  cursor: pointer;
}
.search-input-cm[data-v-64e4bc74] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-64e4bc74] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-64e4bc74],
.sm-item-grid[data-v-64e4bc74] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-64e4bc74] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-64e4bc74] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-64e4bc74] {
  background: #3b435e;
}
.sm-items[data-v-64e4bc74] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-64e4bc74] {
  background: #3b435e;
}
.col-grid[data-v-64e4bc74] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-64e4bc74] {
  font-weight: 500;
}
.list-item[data-v-64e4bc74] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-64e4bc74] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-64e4bc74] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-64e4bc74] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-64e4bc74] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

.flex[data-v-92b92510] {
  display: grid;
  grid-template-columns: 1fr 14.5rem;
}
.row[data-v-92b92510] {
  display: flex;
}
.al-ce[data-v-92b92510] {
  align-items: center;
}
.jc-sb[data-v-92b92510] {
  justify-content: space-between;
}
.smbold[data-v-92b92510] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;

  padding: 1.25rem 1.5rem;
  border-top: 0.0625rem solid #abafd1;
}
.paybtn[data-v-92b92510] {
  background: #76e374;
  border-radius: 0.3125rem;
  width: 10.0625rem;
  height: 3.5625rem;
  margin: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bold[data-v-92b92510] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.check[data-v-92b92510] {
  font-size: 1rem;
  margin-left: 1.2rem;
  cursor: pointer;
}

.item .shoeimg[data-v-bbdec309] {
  width: 4.125rem;
  height: 2.3125rem;
  object-fit: contain;
  background-color: #fff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.4375rem;
}
.items[data-v-bbdec309] {
  padding: 0 1rem;
  padding-left: 1.5rem;
  padding-right: 0.625rem;
  padding-top: 0.3125rem;
  max-height: 26vh;
  margin-right: 0.625rem;
  overflow-y: scroll;
}
.headers-grid[data-v-bbdec309] {
  padding-right: 0.8rem;
  padding-left: 1.75rem;
  width: calc(100% - 2.25rem);
  margin: auto;
  margin-top: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.item[data-v-bbdec309] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.375rem 0;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;

  position: relative;
  border: 0.0625rem solid transparent;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.6875rem;
}
.dark .item[data-v-bbdec309] {
  background: #3b435e ;
}
.headers-grid[data-v-bbdec309],
.item[data-v-bbdec309] {
  display: grid;
  grid-template-columns: 1fr 1.5fr 0.85fr 1.5fr 1.2fr 24px;
  align-items: center;
}
.headers-grid div[data-v-bbdec309],
.item div[data-v-bbdec309] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.close[data-v-bbdec309] {
  cursor: pointer;
}

.items[data-v-b002efe3] {
  padding: 0 1rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
}
.items-container[data-v-b002efe3] {
  overflow-y: scroll;
  margin-right: 0.1875rem;
  max-height: 14rem;
  padding-bottom: 0.5rem;
}
.headersgrid[data-v-b002efe3],
.item[data-v-b002efe3] {
  display: grid;
  grid-template-columns: 1fr 1.2fr 2fr;
  align-items: center;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-b002efe3] {
  display: grid;
  grid-template-columns: 1fr 1.2fr 2.05fr;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-b002efe3] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.item[data-v-b002efe3] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding: 0.8125rem 0;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
}
.dark .item[data-v-b002efe3] {
  background: #3b435e;
}
.item[data-v-b002efe3]:hover {
  border-color: #767bfa;
}
.item div[data-v-b002efe3],
.headersgrid div[data-v-b002efe3] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.consignment[data-v-b002efe3] {
  grid-template-columns: 1.5fr 1fr 1fr !important;
  grid-gap: 0.375rem;
}
.headersgrid.consignment[data-v-b002efe3] {
  grid-template-columns: 1.5fr 1fr 1.1fr !important;
  grid-gap: 0.375rem;
}
.type[data-v-b002efe3]::after {
  position: absolute;
  border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  content: "";
  top: 0%;
  left: 0%;
  height: 100%;
  display: grid;
  max-width: 8.125rem;
  width: 100%;
  z-index: -1;
  -webkit-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  -moz-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  -ms-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  -o-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
}
.type[data-v-b002efe3] {
  position: relative;
  color: #fff;
  z-index: 1;
  height: 100%;
  align-items: center;
  display: flex;
  max-width: 8.125rem;
  text-align: center;
}
.bold[data-v-b002efe3] {
  font-weight: 600;
}
.consignment.item[data-v-b002efe3] {
  padding: 0 0.625rem 0 0rem !important;
  height: 3.125rem;
  margin-bottom: 0;
}
.item span[data-v-b002efe3] {
  width: 100%;
  text-align: center;
}
.blue.type[data-v-b002efe3]::after {
  background-color: #7983d0;
}
.green.type[data-v-b002efe3]::after {
  background-color: #79c1d0;
}
.orange.type[data-v-b002efe3]::after {
  background-color: #d0a879;
}
.pink.type[data-v-b002efe3]::after {
  background-color: #d079c8;
}
.lightbrown[data-v-b002efe3]::after {
  background-color: #d0bd79;
}
.brown[data-v-b002efe3]::after {
  background-color: #d07979;
}
.lightblue[data-v-b002efe3]::after {
  background-color: #79c1d0;
}
.lightgreen[data-v-b002efe3]::after {
  background-color: #aad079;
}
.headersgrid.consignment.categories[data-v-b002efe3] {
  grid-template-columns: 1.35fr 1fr 1.1fr !important;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}

.header[data-v-234db188] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-234db188] {
  cursor: pointer;
}
.input[data-v-234db188] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-234db188] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-234db188] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-234db188] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-234db188] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-234db188] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-234db188] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-234db188] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-234db188] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-234db188] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-234db188] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-234db188] {
  font-size: 1.125rem;
}
.readOnly[data-v-234db188] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-234db188] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-234db188] {
  grid-column: span 2;
}
.breakup[data-v-234db188] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-234db188] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-234db188] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-234db188] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-234db188] {
  justify-content: space-evenly;
}
.jc-sb[data-v-234db188] {
  justify-content: space-between;
}
.btns[data-v-234db188] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-234db188] {
  padding: 0 1.25rem;
}
.pt-10[data-v-234db188] {
  padding-top: 0.625rem;
}
.table-container[data-v-234db188] {
  margin: 0 0.625rem;
}
.span-2[data-v-234db188] {
  grid-column: 2/4;
}
.green[data-v-234db188] {
  color: #12c519;
}
.mb-20[data-v-234db188] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-234db188] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-234db188] {
  padding: 0 1.25rem;
}
.lg-header[data-v-234db188] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-234db188] {
  cursor: pointer;
}
.search-input-cm[data-v-234db188] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-234db188] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-234db188],
.sm-item-grid[data-v-234db188] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-234db188] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-234db188] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-234db188] {
  background: #3b435e;
}
.sm-items[data-v-234db188] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-234db188] {
  background: #3b435e;
}
.col-grid[data-v-234db188] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-234db188] {
  font-weight: 500;
}
.list-item[data-v-234db188] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-234db188] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-234db188] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-234db188] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-234db188] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

.info-div[data-v-0ea221b1] {
  display: flex;
  align-items: center;
  height: 3.1875rem;
  width: 100%;
  padding: 1.375rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  background: #f6f7ff;
  border-radius: 0.4375rem;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
}
.dark .info-div[data-v-0ea221b1] {
  background: #3b435e;
  color: #5a5d74;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .whitetext[data-v-0ea221b1] {
  color: #f6f7ff;
}
.settings[data-v-0ea221b1] {
  padding: 0 0.9375rem 0 1.375rem;
}

.item[data-v-e1260a39] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  padding-bottom: 0.9375rem;
}
.dark .item[data-v-e1260a39] {
  background: #3b435e;
}
.header[data-v-e1260a39] {
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 2.0625rem;
  text-align: center;
  color: #f6f7ff;
  padding: 0.5rem 0;
  width: 100%;
  margin-bottom: 0.6875rem;
  text-transform: capitalize;
}
.dark .header[data-v-e1260a39] {
  color: #353c53;
}
.name[data-v-e1260a39],
.query[data-v-e1260a39] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;

  margin-top: 0.6875rem;
}
.name[data-v-e1260a39] {
  margin-top: 0.125rem;
  padding: 0 0.5rem;
}
.query[data-v-e1260a39] {
  margin-bottom: 0;
}
.value[data-v-e1260a39],
.sku[data-v-e1260a39] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
}
.query[data-v-e1260a39] {
  margin-right: 0.625rem;
}
.price[data-v-e1260a39] {
  font-size: 1.25rem;
  line-height: 1.875rem;

  margin-top: 0.25rem;
}
.my-5[data-v-e1260a39] {
  margin-top: 0.3125rem;
}
.incart[data-v-e1260a39] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #e78a47;
  background: #f6f7ff;
  box-shadow: inset 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3125rem;
  width: 9.0625rem;
  text-align: center;
  padding-top: 0.1875rem;
  padding-bottom: 0.25rem;
  margin-top: 0.5rem;
}
.dark .incart[data-v-e1260a39] {
  background: #353c53;
}
.addcart[data-v-e1260a39] {
  margin-top: 0.5rem;
}
.select[data-v-e1260a39] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mt-0[data-v-e1260a39] {
  margin-top: 0 !important;
}
.max[data-v-e1260a39] {
  max-width: 100px;
  width: 100%;
}
.ta-l[data-v-e1260a39] {
  text-align: left !important;
}
.shoeimg[data-v-e1260a39] {
  margin-top: 0.625rem;
  height: 3.9375rem;
  border-radius: 0.4375rem;
  margin-bottom: 0.625rem;
  -webkit-filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  /* width: 7.375rem;
  object-fit: fill; */
}

.name[data-v-5dfc8c76] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.twogrid[data-v-5dfc8c76] {
  display: grid;
  grid-template-columns: auto 1fr;
}
.twogrid.equal[data-v-5dfc8c76] {
  grid-template-columns: 1fr 1fr;
}
.shoeimg[data-v-5dfc8c76] {
  width: 10.875rem;
  height: 5.8125rem;
  object-fit: contain;
  margin-right: 1.625rem;
  box-shadow: 0rem 0rem 0.75rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  background-color: #fff;
}
.flex[data-v-5dfc8c76] {
  display: flex;
  align-items: center;
}
.ml-4[data-v-5dfc8c76] {
  margin-left: 3rem;
}
.label[data-v-5dfc8c76] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;
  white-space: nowrap;
  margin-right: 0.625rem;
}
.value[data-v-5dfc8c76] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.px-20[data-v-5dfc8c76] {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.mb-10[data-v-5dfc8c76] {
  margin-bottom: 0.6875rem !important;
}
.consignment .mb-10[data-v-5dfc8c76] {
  margin-bottom: 1.125rem !important;
}
.pt-4[data-v-5dfc8c76] {
  padding-top: 0.5rem;
}
.green[data-v-5dfc8c76] {
  color: #12c519;
}
.red[data-v-5dfc8c76] {
  color: #fa7676;
}
.capit[data-v-5dfc8c76] {
  text-transform: capitalize;
}

.items[data-v-ca22ccf1] {
  padding: 0 1rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
}
.items-container[data-v-ca22ccf1] {
  overflow-y: auto;
  margin-right: 0.1875rem;
  max-height: 21rem;
  padding-bottom: 0.5rem;
}
.headersgrid[data-v-ca22ccf1],
.item[data-v-ca22ccf1] {
  display: grid;
  grid-template-columns: 3.9375rem 2.25fr 1.2fr 1fr 1.7fr 1.3fr 1.5fr 1.2fr;
  align-items: center;
  grid-gap: 1.5rem;
}
.headersgrid[data-v-ca22ccf1] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.4375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-right: 0.625rem;
}
.item[data-v-ca22ccf1] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding-top: 0.3125rem;
  padding-bottom: 0.5rem;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  border: 0.0625rem solid transparent;
  position: relative;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  cursor: pointer;
}
.dark .item[data-v-ca22ccf1] {
  background: #3b435e;
}
.item.item[data-v-ca22ccf1]:hover {
  border-color: #767bfa;
}
.item div[data-v-ca22ccf1],
.headersgrid div[data-v-ca22ccf1] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item img[data-v-ca22ccf1] {
  height: 2.3125rem;
  width: 100%;
}
.bgwhite[data-v-ca22ccf1] {
  background-color: #fff;
}
.green[data-v-ca22ccf1],
.Active[data-v-ca22ccf1] {
  color: #12c519;
}
.red[data-v-ca22ccf1],
.Expired[data-v-ca22ccf1] {
  color: #fa7676;
}
.Scheduled[data-v-ca22ccf1] {
  color: #d0a879;
}
.shoeimg[data-v-ca22ccf1] {
  width: 3.9375rem;
  object-fit: contain;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.discount-summary[data-v-ca22ccf1] {
  max-height: 19rem;
}
.discount-summary-header[data-v-ca22ccf1] {
  margin-top: 1.5rem !important;
}
.discount-summary-header[data-v-ca22ccf1],
.discount-summary .item[data-v-ca22ccf1] {
  grid-template-columns: 0.65fr 0.9fr 1.4fr 0.9fr 1fr 0.3fr 0.5fr;
  grid-gap: 1.1875rem;
}
.discount-summary .item[data-v-ca22ccf1] {
  padding-top: 0.8125rem;
  padding-bottom: 0.875rem;
}
.cap[data-v-ca22ccf1] {
  text-transform: uppercase;
}
.del-icon[data-v-ca22ccf1] {
  width: 1.25rem;
  height: 1.25rem !important;
}
.del-items .item[data-v-ca22ccf1],
.del-headers[data-v-ca22ccf1] {
  grid-template-columns: 0.65fr 0.9fr 1.4fr 0.9fr 1fr 0.3fr 0.5fr 1rem 1.25rem !important;
}
.t-headers[data-v-ca22ccf1],
.t-history .item[data-v-ca22ccf1] {
  grid-template-columns: 3.2rem 1.8fr 1fr 1fr 1fr 1fr 0.75fr;
}
.yellow[data-v-ca22ccf1] {
  color: #e7a33e;
}

.summary[data-v-e025aa7c] {
  display: grid;
  grid-gap: 1.875rem 2.5rem;
  grid-template-rows: 19.5625rem 26.25rem;
  grid-template-columns: 1fr 1fr 1fr;
}
.headersection[data-v-e025aa7c] {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  padding: 0.8125rem 1.25rem;
  padding-bottom: 0;
}
.headersection[data-v-e025aa7c]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-e025aa7c]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.box[data-v-e025aa7c] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
}
.receipt-container[data-v-e025aa7c] {
  padding: 0.8125rem 0;
}
.dark .box[data-v-e025aa7c] {
  background: #353c53;
}
.cashout[data-v-e025aa7c] {
  grid-column: 3/4;
  grid-row: 1/3;
}
.bold[data-v-e025aa7c] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  color: #abafd1;
}
.value[data-v-e025aa7c] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;

  margin-left: 0.3125rem;
}
.span2[data-v-e025aa7c] {
  grid-column: 1/3;
}
.total[data-v-e025aa7c] {
  padding-right: 0.875rem;
}
.summary-table[data-v-e025aa7c] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgb(0 0 0 / 15%);
  border-radius: 0.9375rem;
  padding: 0.8125rem 0;
}
.dark .summary-table[data-v-e025aa7c] {
  background: #353c53;
}
.purple[data-v-e025aa7c] {
  font-weight: 500;
  color: #767bfa;
}
.query[data-v-e025aa7c]:not(:last-child) {
  margin-right: 2.5rem;
}
.query[data-v-e025aa7c] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.smtext[data-v-e025aa7c] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.queryvalue[data-v-e025aa7c] {
  font-weight: 300;

  margin-left: 0.625rem;
}
.green[data-v-e025aa7c] {
  color: #12c519;
}
.product-items[data-v-e025aa7c] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: 0.625rem;
}
.product-container[data-v-e025aa7c] {
  padding-top: 0.625rem;
  max-height: calc(100vh - 10rem);
  overflow-y: scroll;
  padding-right: 1.25rem !important;
  margin-right: 1.25rem;
  padding-bottom: 1.25rem;
}
.pos[data-v-e025aa7c] {
  display: grid;
  grid-template-columns: 1fr 42.375rem;
  min-height: 50rem;
}
.cart[data-v-e025aa7c] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.dark .cart[data-v-e025aa7c] {
  background: #353c53;
}
.pt-0[data-v-e025aa7c] {
  padding-top: 0 !important;
}
.note[data-v-e025aa7c] {
  font-weight: 300;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  text-align: center;
  margin-left: 0.425rem;
  color: #767bfa;
}
.headersection[data-v-e025aa7c] {
  display: flex;
}
.headersection > span[data-v-e025aa7c]:first-child {
  white-space: nowrap;
}
.amt[data-v-e025aa7c] {
  font-weight: 300;
  font-size: 1.875rem;
  line-height: 2.8125rem;
}
.subText[data-v-e025aa7c] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.btn[data-v-e025aa7c] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  color: #12c519;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.125rem rgba(0, 0, 0, 0.35);
  border-radius: 0.3125rem;
  width: -webkit-max-content;
  width: max-content;
  max-width: 12.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  margin: auto;
  margin-top: 1.6875rem;
  height: 3.125rem;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.walletDetails[data-v-e025aa7c] {
  padding: 0 3rem;
  padding-top: 0.625rem;
}
.btn[data-v-e025aa7c]:hover {
  background: #e5e6f2;
}
@media (max-width: 115.625rem) {
.pos[data-v-e025aa7c] {
    grid-template-columns: 1fr 35rem !important;
}
}
.black[data-v-e025aa7c] {
  margin-right: 0.3125rem;
  color: #323a52 !important;
}
.dark .black[data-v-e025aa7c] {
  color: #f6f7ff !important;
}
.itemssold[data-v-e025aa7c] {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.35rem;
  margin-left: 0.625rem;
}
.al-fe[data-v-e025aa7c] {
  align-items: flex-end;
}
.px-20[data-v-e025aa7c] {
  padding: 0 1.25rem;
}
.headers[data-v-e025aa7c] {
  margin-bottom: 0.875rem;
}

.box[data-v-61995be9] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  padding: 1.25rem;
}
.dark .box[data-v-61995be9] {
  background-color: #3b435e;
}
.smbox[data-v-61995be9] {
  max-height: 13.9375rem;
  height: 100%;
}
.label[data-v-61995be9] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.5625rem;
  margin-bottom: 0.75rem;
}
.lighttext[data-v-61995be9] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-bottom: 1rem;
}
.twogrid[data-v-61995be9] {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.0625rem;
}
.upload[data-v-61995be9] {
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 3.4375rem;
  overflow: hidden;
}
.dark .upload[data-v-61995be9] {
  background-color: #464f6d;
}
.upload img[data-v-61995be9] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.smlabel[data-v-61995be9] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.4375rem;
  padding-left: 0.5rem;
}
.smlabel svg[data-v-61995be9],
.smlabel img[data-v-61995be9] {
  margin-right: 0.625rem;
}
.smlabel img[data-v-61995be9] {
  height: 1.125rem;
  width: 1.125rem;
}
.mb-13[data-v-61995be9] {
  margin-bottom: 0.8125rem;
}
.rect[data-v-61995be9] {
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  width: 100%;
  height: 100%;
  margin-bottom: 1.25rem;
  max-height: 8.4375rem;
}
.rect img[data-v-61995be9] {
  object-fit: cover;
}
.equal[data-v-61995be9] {
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.box[data-v-f6f63e41] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  padding: 1.25rem;
}
.dark .box[data-v-f6f63e41] {
  background-color: #3b435e;
}
.smbox[data-v-f6f63e41] {
  max-height: 13.9375rem;
  height: 100%;
}
.label[data-v-f6f63e41] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.5625rem;
  margin-bottom: 0.75rem;
}
.lighttext[data-v-f6f63e41] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-bottom: 1rem;
}
.twogrid[data-v-f6f63e41] {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.0625rem;
}
.upload[data-v-f6f63e41] {
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 3.4375rem;
  overflow: hidden;
}
.dark .upload[data-v-f6f63e41] {
  background-color: #464f6d;
}
.upload img[data-v-f6f63e41] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.smlabel[data-v-f6f63e41] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.4375rem;
  padding-left: 0.5rem;
}
.smlabel svg[data-v-f6f63e41],
.smlabel img[data-v-f6f63e41] {
  margin-right: 0.625rem;
}
.smlabel img[data-v-f6f63e41] {
  height: 1.125rem;
  width: 1.125rem;
}
.mb-13[data-v-f6f63e41] {
  margin-bottom: 0.8125rem;
}
.rect[data-v-f6f63e41] {
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  width: 100%;
  height: 100%;
  margin-bottom: 1.25rem;
  max-height: 8.4375rem;
}
.rect img[data-v-f6f63e41] {
  object-fit: cover;
}
.equal[data-v-f6f63e41] {
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.box[data-v-c128b475] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  padding: 1.25rem;
}
.dark .box[data-v-c128b475] {
  background-color: #3b435e;
}
.smbox[data-v-c128b475] {
  max-height: 13.9375rem;
  height: 100%;
}
.label[data-v-c128b475] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.5625rem;
  margin-bottom: 0.75rem;
}
.lighttext[data-v-c128b475] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-bottom: 1rem;
}
.twogrid[data-v-c128b475] {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.0625rem;
}
.upload[data-v-c128b475] {
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 3.4375rem;
  overflow: hidden;
}
.dark .upload[data-v-c128b475] {
  background-color: #464f6d;
}
.upload img[data-v-c128b475] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.smlabel[data-v-c128b475] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.4375rem;
  padding-left: 0.5rem;
}
.smlabel svg[data-v-c128b475],
.smlabel img[data-v-c128b475] {
  margin-right: 0.625rem;
}
.smlabel img[data-v-c128b475] {
  height: 1.125rem;
  width: 1.125rem;
}
.mb-13[data-v-c128b475] {
  margin-bottom: 0.8125rem;
}
.rect[data-v-c128b475] {
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  width: 100%;
  height: 100%;
  margin-bottom: 1.25rem;
  max-height: 8.4375rem;
}
.rect img[data-v-c128b475] {
  object-fit: cover;
}
.equal[data-v-c128b475] {
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.page-container[data-v-c128b475] {
  display: flex;
  justify-content: center;
}
.preview[data-v-c128b475] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.bill-container[data-v-c128b475] {
  max-width: 21.5625rem;
  margin: auto;
  width: 100%;
}
.bill[data-v-c128b475] {
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 1.25rem 1.5625rem;
  padding-bottom: 0.625rem;
}
.dark .bill[data-v-c128b475] {
  background-color: #3b435e;
}
.logo img[data-v-c128b475] {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: none;
}
.logo[data-v-c128b475] {
  display: flex;
  justify-content: center;
  height: 8rem;
  width: 8rem;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  margin-bottom: 0.9375rem;
  background: #4b4e93;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.storename[data-v-c128b475] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  margin-bottom: 0.625rem;
}
.twogrid[data-v-c128b475] {
  display: flex;
  justify-content: space-between;
}
.twogrid > div[data-v-c128b475] {
  max-width: 50%;
}
.right[data-v-c128b475] {
  text-align: right;
}
.staffid[data-v-c128b475] {
  margin-top: 1.875rem;
  margin-bottom: 0.9375rem;
}
.twogrid[data-v-c128b475] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.smheader[data-v-c128b475] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
  margin-bottom: 0.625rem;
}
.border[data-v-c128b475] {
  height: 1px;
  width: 100%;
  margin-bottom: 0.9375rem;
  margin-top: 0.9375rem;
  background-image: linear-gradient(to right, transparent 50%, #474747 50%);
  background-size: 10px 100%;
}
.dark .border[data-v-c128b475] {
  background-image: linear-gradient(to right, transparent 50%, #f6f7ff 50%);
}
.mt-10[data-v-c128b475] {
  margin-top: 0.625rem;
}
.type[data-v-c128b475] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 400;
}
.items .twogrid[data-v-c128b475] {
  margin-bottom: 0.625rem;
  align-items: flex-end;
}
.items .right[data-v-c128b475] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.pricing .twogrid[data-v-c128b475] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.pricing .twogrid[data-v-c128b475]:not(:last-child) {
  margin-bottom: 0.3125rem;
}
.thankyou[data-v-c128b475] {
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  max-width: 145px;
  margin: auto;
  text-align: center;
}
.site[data-v-c128b475],
.link[data-v-c128b475] {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}
.site[data-v-c128b475] {
  margin-bottom: 0.4375rem;
}
.site img[data-v-c128b475],
.link svg[data-v-c128b475] {
  margin-right: 0.3125rem;
  height: 1.125rem;
  width: 1.125rem;
}
.jc-sb[data-v-c128b475] {
  justify-content: space-between;
}
.flex[data-v-c128b475] {
  display: flex;
}
.al-ce[data-v-c128b475] {
  align-self: center;
}
.jc-sb[data-v-c128b475] {
  justify-content: space-between;
}
.custom[data-v-c128b475] {
  max-width: 345px;
}

.receipt-container[data-v-b20c084b] {
  display: grid;
  grid-template-columns: 26.6875rem 26.6875rem 21.5625rem 1fr;
  grid-gap: 1.875rem;
  padding: 0.625rem 1.25rem;
}
.qr-container[data-v-b20c084b] {
  display: grid;
  grid-template-columns: 0.85fr 1fr 1fr;
  grid-gap: 1.875rem;
  padding: 0.625rem 1.25rem;
}
.wrap[data-v-b20c084b] {
  grid-gap: 1.5625rem 3rem;
  display: grid;
  grid-template-columns: repeat(5, 14.0625rem);
  padding: 0 1.25rem;
}
.label[data-v-b20c084b] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.5625rem;
  margin-bottom: 0.75rem;
}
.btncontainer[data-v-b20c084b] {
  grid-column: span 5;
  margin-top: 1.875rem;
  justify-content: flex-end;
}
.mt-custom[data-v-b20c084b] {
  margin-top: 1.25rem;
}
.mr-20[data-v-b20c084b] {
  margin-right: 1.25rem;
}
.color-container[data-v-b20c084b] {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.2rem;
  padding-right: 0.9375rem;
  max-width: 14.0625rem;
  width: 100%;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border: none;
  outline: none;
  height: 3.1875rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  position: relative;
}
.color-container input[data-v-b20c084b] {
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.dark .color-container[data-v-b20c084b] {
  background-color: #3b435e;
}

.text[data-v-646fe802] {
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.6875rem;
}

.headersection[data-v-bd850504] {
  position: relative;
  padding: 0 1rem;
}
.headersection[data-v-bd850504]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-bd850504]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.smtext[data-v-bd850504] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.px-25[data-v-bd850504] {
  padding-left: 1.25rem;
  margin-top: 1.5625rem;
}
.label[data-v-bd850504] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.75rem;
}
.wrap[data-v-bd850504] {
  grid-gap: 1.5625rem 3rem;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(4, 14.0625rem);
}
.select-container[data-v-bd850504] {
  max-width: 14.0625rem;
  width: 100%;
  flex-shrink: 0;
}
.twogrid[data-v-bd850504] {
  display: grid;
  grid-template-columns: 1fr;
}
.two-grid[data-v-bd850504] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
}
.end[data-v-bd850504] {
  justify-content: flex-end;
}
@media (max-width: 1700px) {
.wrap[data-v-bd850504] {
    grid-gap: 1.5rem 2.125rem;
}
}
.btncontainer[data-v-bd850504] {
  grid-column: span 4;
  margin-top: 1.875rem;
  justify-content: flex-end;
}
.w-100[data-v-bd850504] {
  width: 100%;
}
.abs[data-v-bd850504] {
  position: absolute;
  right: 2rem;
  top: -3rem;
}
.mt-custom[data-v-bd850504] {
  margin-top: 7rem;
}
.rel[data-v-bd850504] {
  position: relative;
}
.cp[data-v-bd850504] {
  cursor: pointer;
}
.visible[data-v-bd850504] {
  -webkit-transform-origin: center;
          transform-origin: center;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.px-15[data-v-bd850504] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgb(0 0 0 / 15%);
  border-radius: 0.9375rem;
  padding: 0.8125rem 0;
  padding-right: 1rem;
}
.settings-container[data-v-bd850504] {
  max-height: 85vh;
  overflow-y: auto;
  padding-bottom: 1rem;
}
.dark .px-15[data-v-bd850504] {
  background: #353c53;
}
.pr-15[data-v-bd850504] {
  padding-right: 1.5rem;
}
.pad-5[data-v-bd850504] {
  margin-right: 15px;
}

.box[data-v-ee615f23] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  padding: 1.25rem;
}
.dark .box[data-v-ee615f23] {
  background-color: #3b435e;
}
.smbox[data-v-ee615f23] {
  max-height: 13.9375rem;
  height: 100%;
}
.label[data-v-ee615f23] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.5625rem;
  margin-bottom: 0.75rem;
}
.lighttext[data-v-ee615f23] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-bottom: 1rem;
}
.twogrid[data-v-ee615f23] {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.0625rem;
}
.upload[data-v-ee615f23] {
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 3.4375rem;
  overflow: hidden;
}
.dark .upload[data-v-ee615f23] {
  background-color: #464f6d;
}
.upload img[data-v-ee615f23] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.smlabel[data-v-ee615f23] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.4375rem;
  padding-left: 0.5rem;
}
.smlabel svg[data-v-ee615f23],
.smlabel img[data-v-ee615f23] {
  margin-right: 0.625rem;
}
.smlabel img[data-v-ee615f23] {
  height: 1.125rem;
  width: 1.125rem;
}
.mb-13[data-v-ee615f23] {
  margin-bottom: 0.8125rem;
}
.rect[data-v-ee615f23] {
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  width: 100%;
  height: 100%;
  margin-bottom: 1.25rem;
  max-height: 8.4375rem;
}
.rect img[data-v-ee615f23] {
  object-fit: cover;
}
.equal[data-v-ee615f23] {
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.grid[data-v-ee615f23] {
  display: grid;
  grid-template-columns: 1fr 1fr 0.8fr;
  grid-gap: 1.875rem;
}
.grid[data-v-ee615f23] {
  padding-right: 1.5rem;
  margin-bottom: 1rem;
}
.full[data-v-ee615f23] {
  grid-column: 1/3;
}
.rect[data-v-ee615f23] {
  height: 8.4375rem;
  margin-bottom: 0;
}
.smbox[data-v-ee615f23] {
  max-height: none;
  grid-gap: 0rem 2rem;
}
.as-fe[data-v-ee615f23] {
  align-self: flex-end;
}
.color-container input[data-v-ee615f23] {
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.color-container[data-v-ee615f23] {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.2rem;
  padding-right: 0.9375rem;
  width: 14.0625rem;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border: none;
  outline: none;
  height: 3.1875rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  position: relative;
}

.header[data-v-bd0039c2] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-bd0039c2] {
  cursor: pointer;
}
.input[data-v-bd0039c2] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-bd0039c2] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-bd0039c2] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-bd0039c2] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-bd0039c2] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-bd0039c2] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-bd0039c2] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-bd0039c2] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-bd0039c2] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-bd0039c2] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-bd0039c2] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-bd0039c2] {
  font-size: 1.125rem;
}
.readOnly[data-v-bd0039c2] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-bd0039c2] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-bd0039c2] {
  grid-column: span 2;
}
.breakup[data-v-bd0039c2] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-bd0039c2] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-bd0039c2] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-bd0039c2] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-bd0039c2] {
  justify-content: space-evenly;
}
.jc-sb[data-v-bd0039c2] {
  justify-content: space-between;
}
.btns[data-v-bd0039c2] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-bd0039c2] {
  padding: 0 1.25rem;
}
.pt-10[data-v-bd0039c2] {
  padding-top: 0.625rem;
}
.table-container[data-v-bd0039c2] {
  margin: 0 0.625rem;
}
.span-2[data-v-bd0039c2] {
  grid-column: 2/4;
}
.green[data-v-bd0039c2] {
  color: #12c519;
}
.mb-20[data-v-bd0039c2] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-bd0039c2] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-bd0039c2] {
  padding: 0 1.25rem;
}
.lg-header[data-v-bd0039c2] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-bd0039c2] {
  cursor: pointer;
}
.search-input-cm[data-v-bd0039c2] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-bd0039c2] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-bd0039c2],
.sm-item-grid[data-v-bd0039c2] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-bd0039c2] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-bd0039c2] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-bd0039c2] {
  background: #3b435e;
}
.sm-items[data-v-bd0039c2] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-bd0039c2] {
  background: #3b435e;
}
.col-grid[data-v-bd0039c2] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-bd0039c2] {
  font-weight: 500;
}
.list-item[data-v-bd0039c2] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-bd0039c2] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-bd0039c2] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-bd0039c2] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-bd0039c2] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

.header[data-v-8ab75ba1] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-8ab75ba1] {
  cursor: pointer;
}
.input[data-v-8ab75ba1] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-8ab75ba1] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-8ab75ba1] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-8ab75ba1] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-8ab75ba1] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-8ab75ba1] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-8ab75ba1] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-8ab75ba1] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-8ab75ba1] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-8ab75ba1] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-8ab75ba1] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-8ab75ba1] {
  font-size: 1.125rem;
}
.readOnly[data-v-8ab75ba1] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-8ab75ba1] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-8ab75ba1] {
  grid-column: span 2;
}
.breakup[data-v-8ab75ba1] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-8ab75ba1] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-8ab75ba1] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-8ab75ba1] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-8ab75ba1] {
  justify-content: space-evenly;
}
.jc-sb[data-v-8ab75ba1] {
  justify-content: space-between;
}
.btns[data-v-8ab75ba1] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-8ab75ba1] {
  padding: 0 1.25rem;
}
.pt-10[data-v-8ab75ba1] {
  padding-top: 0.625rem;
}
.table-container[data-v-8ab75ba1] {
  margin: 0 0.625rem;
}
.span-2[data-v-8ab75ba1] {
  grid-column: 2/4;
}
.green[data-v-8ab75ba1] {
  color: #12c519;
}
.mb-20[data-v-8ab75ba1] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-8ab75ba1] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-8ab75ba1] {
  padding: 0 1.25rem;
}
.lg-header[data-v-8ab75ba1] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-8ab75ba1] {
  cursor: pointer;
}
.search-input-cm[data-v-8ab75ba1] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-8ab75ba1] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-8ab75ba1],
.sm-item-grid[data-v-8ab75ba1] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-8ab75ba1] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-8ab75ba1] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-8ab75ba1] {
  background: #3b435e;
}
.sm-items[data-v-8ab75ba1] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-8ab75ba1] {
  background: #3b435e;
}
.col-grid[data-v-8ab75ba1] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-8ab75ba1] {
  font-weight: 500;
}
.list-item[data-v-8ab75ba1] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-8ab75ba1] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-8ab75ba1] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-8ab75ba1] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-8ab75ba1] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

.productinfo[data-v-b313514a] {
  text-align: center;
  height: 100%;
  padding: 1.25rem 1.625rem 0 1.25rem;
  border-radius: 0.9375rem;
}
.categories[data-v-b313514a] {
  padding: 1.25rem 1.625rem 0 1.25rem;
}
.headersection[data-v-b313514a] {
  position: relative;
}
.headersection[data-v-b313514a]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: -0.25rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-b313514a]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.blur[data-v-b313514a] {
  -webkit-filter: blur(0.375rem);
          filter: blur(0.375rem);
  -webkit-user-select: none;
          user-select: none;
}
.smtext[data-v-b313514a] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.shoeimg[data-v-b313514a] {
  width: 10.9375rem !important;
  height: 5.9375rem !important;
  object-fit: contain !important;
  border-radius: 0.9375rem !important;
  background-color: #fff;
  margin-top: 0.75rem !important;
  margin-bottom: 0.25rem !important;
  -webkit-border-radius: 0.9375rem !important;
  -moz-border-radius: 0.9375rem !important;
  -ms-border-radius: 0.9375rem !important;
  -o-border-radius: 0.9375rem !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.mb-comparing[data-v-b313514a] {
  margin-bottom: 0.5rem;
}
.bold[data-v-b313514a] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.mt-10[data-v-b313514a] {
  margin-top: 0.625rem;
}
.query[data-v-b313514a] {
  margin-right: 0.3125rem;
}
.large .query[data-v-b313514a],
.large .value[data-v-b313514a] {
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.pricing[data-v-b313514a] {
  text-align: center;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.red[data-v-b313514a],
.green[data-v-b313514a],
.blue[data-v-b313514a] {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.brown[data-v-b313514a] {
  background-color: #d07979;
}
.light-brown[data-v-b313514a] {
  background-color: #d0a879;
}
.light-green[data-v-b313514a] {
  background-color: #aad079;
}
.light-blue[data-v-b313514a] {
  background-color: #79c1d0;
}
.dark-brown[data-v-b313514a] {
  background-color: #b47467;
}
.pink[data-v-b313514a] {
  background-color: #d079c8;
}
.transaction-history .red[data-v-b313514a],
.transaction-history .green[data-v-b313514a] {
  font-weight: 300 !important;
  font-size: 0.9375rem;
}
.site[data-v-b313514a] {
  margin-bottom: 2rem;
}
.brand[data-v-b313514a] {
  width: 6rem;
  height: 3.25rem;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  object-fit: contain;
  padding: 0.1875rem;
}
.bids[data-v-b313514a] {
  margin-top: 1.0625rem;
  margin-bottom: 0.9375rem;
}
.al-st[data-v-b313514a] {
  align-items: flex-start;
}
.new[data-v-b313514a] {
  -webkit-backdrop-filter: blur(0.5625rem);
          backdrop-filter: blur(0.5625rem);
  position: absolute;
  top: -0.3125rem;
  left: -0.3125rem;
  right: -0.3125rem;
  bottom: -0.3125rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  align-items: center;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.comparing[data-v-b313514a] {
  position: relative;
  padding: 1.25rem 1.625rem 0 1.25rem;
  height: 100%;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.b-shadow[data-v-b313514a] {
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
}
.value[data-v-b313514a] {
  font-weight: 300;
  font-size: 0.9375rem;
}
.large .value[data-v-b313514a] {
  font-weight: 400;
}
.categories-grid[data-v-b313514a] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.625rem 0.3125rem;
  text-align: center;
}
.blue[data-v-b313514a] {
  background: #7983d0;
}
.lightblue[data-v-b313514a] {
  background: #79c1d0;
}
.yellow[data-v-b313514a] {
  background: #d0bd79;
}
.grey[data-v-b313514a] {
  background: #abafd1;
}
.smbox[data-v-b313514a] {
  box-shadow: 0rem 0rem 0.375rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  font-weight: 300 !important;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #ffffff !important;
  height: 2.5rem;
}
.text-center[data-v-b313514a] {
  text-align: center;
  margin-bottom: 1rem;
}
.categories-section[data-v-b313514a] {
  margin-top: 2rem;
}
.swiper[data-v-b313514a] {
  width: 100%;
  height: 100%;
}
.swiper-slide[data-v-b313514a] {
  text-align: center;
  font-size: 1.125rem;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.6875rem;
}
.swiper-slide img[data-v-b313514a] {
  display: block;
  width: 100%;
  cursor: pointer;
  height: auto;
  object-fit: contain;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.trending[data-v-b313514a] {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  border-radius: 0.9375rem;
  overflow: hidden;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.pl[data-v-b313514a] {
  margin-left: 1.25rem;
  padding-top: 0.8125rem;
  padding-left: 0 !important  ;
}
.priceheader[data-v-b313514a] {
  margin-left: 1.25rem;
  padding-right: 1.1875rem;
  padding-top: 0.625rem;
  margin-bottom: 1.25rem;
}
.priceheader.price-history[data-v-b313514a] {
  margin-bottom: 2.2rem;
}
.twogrid[data-v-b313514a] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 2.125rem;
}
.twogrid.smgrid[data-v-b313514a] {
  padding: 0 !important;
  grid-gap: 1.25rem;
  margin-top: 1.25rem;
}
.shadow[data-v-b313514a] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding: 0.875rem 1.375rem;
}
.dark .shadow[data-v-b313514a] {
  background: #3b435e;
}
.shadow .bold[data-v-b313514a] {
  margin-bottom: 0.3125rem;
}
.mt-10[data-v-b313514a] {
  margin-top: 0.625rem !important;
}
.details[data-v-b313514a] {
  margin-left: 1.5625rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.type[data-v-b313514a] {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.cond[data-v-b313514a] {
  margin-left: 0.625rem;
}
.headersgrid[data-v-b313514a],
.item[data-v-b313514a] {
  display: grid;
  grid-template-columns: 1fr 1fr 1.1fr 0.8fr;
  align-items: center;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .item[data-v-b313514a] {
  background-color: #3b435e;
}
.headersgrid div[data-v-b313514a],
.item div[data-v-b313514a] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.visible[data-v-b313514a] {
  overflow: visible !important;
}
.transaction-history .item img[data-v-b313514a] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.item[data-v-b313514a] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 14px 17px;
  margin-bottom: 0.3125rem;
  font-weight: 300;
}
.headersgrid[data-v-b313514a] {
  padding: 0 1.0625rem;
  font-weight: 500;
}
.transaction-history .headersgrid[data-v-b313514a] {
  padding: 0 2rem;
}
.transaction-history .item[data-v-b313514a] {
  padding: 0.375rem 0.625rem;
}
.transaction-history .headersgrid[data-v-b313514a],
.transaction-history .item[data-v-b313514a] {
  display: grid;
  grid-template-columns: 3.9375rem 1fr 0.4fr 0.6fr !important;
  grid-gap: 0.625rem !important;
}
.dark .transaction-history .item[data-v-b313514a] {
  background-color: #3b435e;
}
.pricing .blue[data-v-b313514a] {
  background: transparent !important;
}
.items[data-v-b313514a] {
  max-height: 18.85rem;
  overflow-y: auto;
  padding: 0.75rem 1rem;
  margin-right: 0.375rem;
  padding-right: 0.375rem;
}
.transaction-history .items[data-v-b313514a] {
  max-height: 41.35rem;
}
.transaction-history-owner .items[data-v-b313514a] {
  max-height: 20rem !important;
}
.headerscotainer[data-v-b313514a] {
  padding: 0 1rem;
}
.mt-15[data-v-b313514a] {
  margin-bottom: 0.3rem;
}
.mt-0[data-v-b313514a] {
  margin-top: 0 !important;
}
.mt-18[data-v-b313514a] {
  margin-top: 1.125rem !important;
}
.home-categories[data-v-b313514a] {
  padding: 1rem 1.125rem;
}
.home-categories .query[data-v-b313514a],
.home-categories .value[data-v-b313514a] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pr-10[data-v-b313514a] {
  padding-right: 4rem;
}
.staff-overviews .items[data-v-b313514a] {
  max-height: 13rem;
}
.item img[data-v-b313514a] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.total[data-v-b313514a] {
  margin-left: -3.5rem;
  font-weight: 600;
}
.totaltext[data-v-b313514a] {
  color: #abafd1;
}
.dark .totaltext[data-v-b313514a] {
  color: #5a5d74;
}
.admin-items .headersgrid[data-v-b313514a],
.admin-items .item[data-v-b313514a] {
  grid-template-columns: 0.5fr 1fr 0.8fr 0.6fr !important;
}
.admin-items .item img[data-v-b313514a] {
  margin-left: 0.3125rem;
}
.customer-details .items[data-v-b313514a] {
  max-height: 15rem;
}
.customer-details .item[data-v-b313514a] {
  grid-template-columns: 1fr 1fr !important;
}
.twogrid-section[data-v-b313514a] {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.productinfo[data-v-e86fcc47] {
  text-align: center;
  height: 100%;
  padding: 1.25rem 1.625rem 0 1.25rem;
  border-radius: 0.9375rem;
}
.categories[data-v-e86fcc47] {
  padding: 1.25rem 1.625rem 0 1.25rem;
}
.headersection[data-v-e86fcc47] {
  position: relative;
}
.headersection[data-v-e86fcc47]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: -0.25rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-e86fcc47]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.blur[data-v-e86fcc47] {
  -webkit-filter: blur(0.375rem);
          filter: blur(0.375rem);
  -webkit-user-select: none;
          user-select: none;
}
.smtext[data-v-e86fcc47] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.shoeimg[data-v-e86fcc47] {
  width: 10.9375rem !important;
  height: 5.9375rem !important;
  object-fit: contain !important;
  border-radius: 0.9375rem !important;
  background-color: #fff;
  margin-top: 0.75rem !important;
  margin-bottom: 0.25rem !important;
  -webkit-border-radius: 0.9375rem !important;
  -moz-border-radius: 0.9375rem !important;
  -ms-border-radius: 0.9375rem !important;
  -o-border-radius: 0.9375rem !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.mb-comparing[data-v-e86fcc47] {
  margin-bottom: 0.5rem;
}
.bold[data-v-e86fcc47] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.mt-10[data-v-e86fcc47] {
  margin-top: 0.625rem;
}
.query[data-v-e86fcc47] {
  margin-right: 0.3125rem;
}
.large .query[data-v-e86fcc47],
.large .value[data-v-e86fcc47] {
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.pricing[data-v-e86fcc47] {
  text-align: center;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.red[data-v-e86fcc47],
.green[data-v-e86fcc47],
.blue[data-v-e86fcc47] {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.brown[data-v-e86fcc47] {
  background-color: #d07979;
}
.light-brown[data-v-e86fcc47] {
  background-color: #d0a879;
}
.light-green[data-v-e86fcc47] {
  background-color: #aad079;
}
.light-blue[data-v-e86fcc47] {
  background-color: #79c1d0;
}
.dark-brown[data-v-e86fcc47] {
  background-color: #b47467;
}
.pink[data-v-e86fcc47] {
  background-color: #d079c8;
}
.transaction-history .red[data-v-e86fcc47],
.transaction-history .green[data-v-e86fcc47] {
  font-weight: 300 !important;
  font-size: 0.9375rem;
}
.site[data-v-e86fcc47] {
  margin-bottom: 2rem;
}
.brand[data-v-e86fcc47] {
  width: 6rem;
  height: 3.25rem;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  object-fit: contain;
  padding: 0.1875rem;
}
.bids[data-v-e86fcc47] {
  margin-top: 1.0625rem;
  margin-bottom: 0.9375rem;
}
.al-st[data-v-e86fcc47] {
  align-items: flex-start;
}
.new[data-v-e86fcc47] {
  -webkit-backdrop-filter: blur(0.5625rem);
          backdrop-filter: blur(0.5625rem);
  position: absolute;
  top: -0.3125rem;
  left: -0.3125rem;
  right: -0.3125rem;
  bottom: -0.3125rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  align-items: center;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.comparing[data-v-e86fcc47] {
  position: relative;
  padding: 1.25rem 1.625rem 0 1.25rem;
  height: 100%;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.b-shadow[data-v-e86fcc47] {
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
}
.value[data-v-e86fcc47] {
  font-weight: 300;
  font-size: 0.9375rem;
}
.large .value[data-v-e86fcc47] {
  font-weight: 400;
}
.categories-grid[data-v-e86fcc47] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.625rem 0.3125rem;
  text-align: center;
}
.blue[data-v-e86fcc47] {
  background: #7983d0;
}
.lightblue[data-v-e86fcc47] {
  background: #79c1d0;
}
.yellow[data-v-e86fcc47] {
  background: #d0bd79;
}
.grey[data-v-e86fcc47] {
  background: #abafd1;
}
.smbox[data-v-e86fcc47] {
  box-shadow: 0rem 0rem 0.375rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  font-weight: 300 !important;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #ffffff !important;
  height: 2.5rem;
}
.text-center[data-v-e86fcc47] {
  text-align: center;
  margin-bottom: 1rem;
}
.categories-section[data-v-e86fcc47] {
  margin-top: 2rem;
}
.swiper[data-v-e86fcc47] {
  width: 100%;
  height: 100%;
}
.swiper-slide[data-v-e86fcc47] {
  text-align: center;
  font-size: 1.125rem;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.6875rem;
}
.swiper-slide img[data-v-e86fcc47] {
  display: block;
  width: 100%;
  cursor: pointer;
  height: auto;
  object-fit: contain;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.trending[data-v-e86fcc47] {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  border-radius: 0.9375rem;
  overflow: hidden;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.pl[data-v-e86fcc47] {
  margin-left: 1.25rem;
  padding-top: 0.8125rem;
  padding-left: 0 !important  ;
}
.priceheader[data-v-e86fcc47] {
  margin-left: 1.25rem;
  padding-right: 1.1875rem;
  padding-top: 0.625rem;
  margin-bottom: 1.25rem;
}
.priceheader.price-history[data-v-e86fcc47] {
  margin-bottom: 2.2rem;
}
.twogrid[data-v-e86fcc47] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 2.125rem;
}
.twogrid.smgrid[data-v-e86fcc47] {
  padding: 0 !important;
  grid-gap: 1.25rem;
  margin-top: 1.25rem;
}
.shadow[data-v-e86fcc47] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding: 0.875rem 1.375rem;
}
.dark .shadow[data-v-e86fcc47] {
  background: #3b435e;
}
.shadow .bold[data-v-e86fcc47] {
  margin-bottom: 0.3125rem;
}
.mt-10[data-v-e86fcc47] {
  margin-top: 0.625rem !important;
}
.details[data-v-e86fcc47] {
  margin-left: 1.5625rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.type[data-v-e86fcc47] {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.cond[data-v-e86fcc47] {
  margin-left: 0.625rem;
}
.headersgrid[data-v-e86fcc47],
.item[data-v-e86fcc47] {
  display: grid;
  grid-template-columns: 1fr 1fr 1.1fr 0.8fr;
  align-items: center;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .item[data-v-e86fcc47] {
  background-color: #3b435e;
}
.headersgrid div[data-v-e86fcc47],
.item div[data-v-e86fcc47] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.visible[data-v-e86fcc47] {
  overflow: visible !important;
}
.transaction-history .item img[data-v-e86fcc47] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.item[data-v-e86fcc47] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 14px 17px;
  margin-bottom: 0.3125rem;
  font-weight: 300;
}
.headersgrid[data-v-e86fcc47] {
  padding: 0 1.0625rem;
  font-weight: 500;
}
.transaction-history .headersgrid[data-v-e86fcc47] {
  padding: 0 2rem;
}
.transaction-history .item[data-v-e86fcc47] {
  padding: 0.375rem 0.625rem;
}
.transaction-history .headersgrid[data-v-e86fcc47],
.transaction-history .item[data-v-e86fcc47] {
  display: grid;
  grid-template-columns: 3.9375rem 1fr 0.4fr 0.6fr !important;
  grid-gap: 0.625rem !important;
}
.dark .transaction-history .item[data-v-e86fcc47] {
  background-color: #3b435e;
}
.pricing .blue[data-v-e86fcc47] {
  background: transparent !important;
}
.items[data-v-e86fcc47] {
  max-height: 18.85rem;
  overflow-y: auto;
  padding: 0.75rem 1rem;
  margin-right: 0.375rem;
  padding-right: 0.375rem;
}
.transaction-history .items[data-v-e86fcc47] {
  max-height: 41.35rem;
}
.transaction-history-owner .items[data-v-e86fcc47] {
  max-height: 20rem !important;
}
.headerscotainer[data-v-e86fcc47] {
  padding: 0 1rem;
}
.mt-15[data-v-e86fcc47] {
  margin-bottom: 0.3rem;
}
.mt-0[data-v-e86fcc47] {
  margin-top: 0 !important;
}
.mt-18[data-v-e86fcc47] {
  margin-top: 1.125rem !important;
}
.home-categories[data-v-e86fcc47] {
  padding: 1rem 1.125rem;
}
.home-categories .query[data-v-e86fcc47],
.home-categories .value[data-v-e86fcc47] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pr-10[data-v-e86fcc47] {
  padding-right: 4rem;
}
.staff-overviews .items[data-v-e86fcc47] {
  max-height: 13rem;
}
.item img[data-v-e86fcc47] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.total[data-v-e86fcc47] {
  margin-left: -3.5rem;
  font-weight: 600;
}
.totaltext[data-v-e86fcc47] {
  color: #abafd1;
}
.dark .totaltext[data-v-e86fcc47] {
  color: #5a5d74;
}
.admin-items .headersgrid[data-v-e86fcc47],
.admin-items .item[data-v-e86fcc47] {
  grid-template-columns: 0.5fr 1fr 0.8fr 0.6fr !important;
}
.admin-items .item img[data-v-e86fcc47] {
  margin-left: 0.3125rem;
}
.customer-details .items[data-v-e86fcc47] {
  max-height: 15rem;
}
.customer-details .item[data-v-e86fcc47] {
  grid-template-columns: 1fr 1fr !important;
}
.twogrid-section[data-v-e86fcc47] {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.tooltip[data-v-aa1125a7] {
  border-radius: 0.25rem;
  background: #fff;
  padding: 1rem;
  box-shadow: 0.9375rem 1.875rem 2.5rem 0.3125rem rgba(255, 255, 255, 0.25);
  text-align: center;
  color: #26313c;
}
.dark .tooltip[data-v-aa1125a7] {
  background-color: #26313c;
  color: #fff;
  box-shadow: 0.9375rem 1.875rem 2.5rem 0.3125rem rgba(0, 0, 0, 0.25);
}

.items .item[data-v-be2bbf30],
.headersgrid[data-v-be2bbf30] {
  display: grid;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  grid-template-columns: 1fr 1.2fr 1.2fr 1fr 1.2fr 1fr 1fr;
  grid-gap: 0.625rem;
}
.items .item[data-v-be2bbf30] {
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.items .item[data-v-be2bbf30]:hover {
  border-color: #767bfa;
}
.active-item[data-v-be2bbf30] {
  border-color: #767bfa !important;
}
.check-details .item[data-v-be2bbf30],
.check-details .headersgrid[data-v-be2bbf30] {
  grid-template-columns: 1fr 1fr 1fr;
}
.headersgrid[data-v-be2bbf30] {
  padding-left: calc(0.9375rem + 0.75rem);
  padding-right: calc(1.6875rem + 0.75rem);
  font-weight: 500;
}
.items[data-v-be2bbf30] {
  padding-left: 0.9375rem;
  padding-right: 0.6875rem;
  padding-bottom: 0.625rem;
  padding-top: 0.3125rem;
}
.item[data-v-be2bbf30] {
  font-weight: 300;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.8125rem 0.75rem;
  margin-top: 0.3125rem;
}
.dark .item[data-v-be2bbf30] {
  background: #3b435e;
}
.items[data-v-be2bbf30] {
  max-height: 42rem;
  overflow-y: auto;
  margin-right: 1rem;
}
.item div[data-v-be2bbf30],
.headersgrid div[data-v-be2bbf30] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.check-details .items[data-v-be2bbf30] {
  height: 12.5rem;
}
.center[data-v-be2bbf30] {
  text-align: center;
  transform: translateX(-10%);
  -webkit-transform: translateX(-10%);
  -moz-transform: translateX(-10%);
  -ms-transform: translateX(-10%);
  -o-transform: translateX(-10%);
}

.twogrid[data-v-bf621efb] {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
}
.query[data-v-bf621efb] {
  font-weight: 500;
  margin-right: 0.3125rem;
}
.flex[data-v-bf621efb] {
  margin-bottom: 0.625rem;
}
.staff-details[data-v-bf621efb] {
  padding-left: 1.6875rem;
  padding-right: 0.75rem;
  margin-top: 1rem;
}
.value[data-v-bf621efb] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 300;
}
.grid[data-v-bf621efb] {
  display: grid;
  grid-template-columns: 6.5rem 1fr;
  grid-gap: 0.5rem;
  margin-bottom: 0.25rem;
}
.work[data-v-bf621efb] {
  padding-left: 1.6875rem;
  padding-right: 0.75rem;
}
.bold[data-v-bf621efb] {
  font-weight: 500;
}
.yellow[data-v-bf621efb] {
  color: #fcbe85;
}
.red[data-v-bf621efb] {
  color: #fa7676;
}
.three-grid[data-v-bf621efb] {
  display: grid;
  font-weight: 300 !important;
  grid-template-columns: 2.5rem 3rem 3.125rem;
}
.left-grid[data-v-bf621efb] {
  display: grid;
  grid-template-columns: 12rem 3rem;
  margin-right: 1rem;
}
.query[data-v-bf621efb] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}

.items .item[data-v-29f42ef9],
.headersgrid[data-v-29f42ef9] {
  display: grid;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  grid-template-columns: 1fr 1.2fr 1.2fr 1fr 1.2fr 1fr 1fr;
  grid-gap: 0.625rem;
}
.items .item[data-v-29f42ef9] {
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.items .item[data-v-29f42ef9]:hover {
  border-color: #767bfa;
}
.active-item[data-v-29f42ef9] {
  border-color: #767bfa !important;
}
.check-details .item[data-v-29f42ef9],
.check-details .headersgrid[data-v-29f42ef9] {
  grid-template-columns: 1fr 1fr 1fr;
}
.headersgrid[data-v-29f42ef9] {
  padding-left: calc(0.9375rem + 0.75rem);
  padding-right: calc(1.6875rem + 0.75rem);
  font-weight: 500;
}
.items[data-v-29f42ef9] {
  padding-left: 0.9375rem;
  padding-right: 0.6875rem;
  padding-bottom: 0.625rem;
  padding-top: 0.3125rem;
}
.item[data-v-29f42ef9] {
  font-weight: 300;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.8125rem 0.75rem;
  margin-top: 0.3125rem;
}
.dark .item[data-v-29f42ef9] {
  background: #3b435e;
}
.items[data-v-29f42ef9] {
  max-height: 42rem;
  overflow-y: auto;
  margin-right: 1rem;
}
.item div[data-v-29f42ef9],
.headersgrid div[data-v-29f42ef9] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.check-details .items[data-v-29f42ef9] {
  height: 12.5rem;
}
.center[data-v-29f42ef9] {
  text-align: center;
  transform: translateX(-10%);
  -webkit-transform: translateX(-10%);
  -moz-transform: translateX(-10%);
  -ms-transform: translateX(-10%);
  -o-transform: translateX(-10%);
}

.twogrid[data-v-2f3fde32] {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
}
.query[data-v-2f3fde32] {
  font-weight: 500;
  margin-right: 0.3125rem;
}
.flex[data-v-2f3fde32] {
  margin-bottom: 0.625rem;
}
.staff-details[data-v-2f3fde32] {
  padding-left: 1.6875rem;
  padding-right: 0.75rem;
  margin-top: 1rem;
}
.value[data-v-2f3fde32] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 300;
}
.grid[data-v-2f3fde32] {
  display: grid;
  grid-template-columns: 6.5rem 1fr;
  grid-gap: 0.5rem;
  margin-bottom: 0.25rem;
}
.work[data-v-2f3fde32] {
  padding-left: 1.6875rem;
  padding-right: 0.75rem;
}
.bold[data-v-2f3fde32] {
  font-weight: 500;
}
.yellow[data-v-2f3fde32] {
  color: #fcbe85;
}
.red[data-v-2f3fde32] {
  color: #fa7676;
}
.three-grid[data-v-2f3fde32] {
  display: grid;
  font-weight: 300 !important;
  grid-template-columns: 2.5rem 3rem 3.125rem;
}
.left-grid[data-v-2f3fde32] {
  display: grid;
  grid-template-columns: 12rem 3rem;
  margin-right: 1rem;
}
.query[data-v-2f3fde32] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}

.items[data-v-ff5fd616] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(23.6875rem, 1fr));
  grid-gap: 2rem;
}
.item[data-v-ff5fd616] {
  min-height: 24.5625rem;
}
.items-container[data-v-ff5fd616] {
  padding-top: 0.5rem;
  max-height: 85vh;
  overflow-x: auto;
  padding-bottom: 2rem;
}
.threegrid[data-v-ff5fd616] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 19.5625rem 27.5rem;
  grid-gap: 1.6rem;
}
.admingrid.threegrid[data-v-ff5fd616] {
  grid-gap: 1rem;
}
.box[data-v-ff5fd616] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.span-2[data-v-ff5fd616] {
  grid-column: span 2;
}
.twogrid[data-v-ff5fd616] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.left[data-v-ff5fd616] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.8125rem;
  grid-template-rows: 19.5625rem 19.5625rem 26.25rem;
  padding-right: 1rem;
}
.left.admin[data-v-ff5fd616] {
  grid-template-rows: repeat(3, 21.5625rem) !important;
}
.leftcontainer[data-v-ff5fd616] {
  max-height: min(79vh, 49rem);
  overflow-y: auto;
  grid-row: 1/3;
}
.right[data-v-ff5fd616] {
  grid-row: 1/3;
  display: grid;
  grid-gap: 1.8125rem;
  grid-template-rows: 19.5625rem 26.25rem;
}
.pt-10[data-v-ff5fd616] {
  padding-top: 0.625rem;
}
.pb-10[data-v-ff5fd616] {
  padding-bottom: 0.625rem;
}
.mb-0[data-v-ff5fd616] {
  margin-bottom: 0 !important;
}
.col-span[data-v-ff5fd616] {
  grid-row: 1/3;
}
.overflow[data-v-ff5fd616] {
  max-height: 25.75rem;
  overflow-y: auto;
  margin-right: 1rem;
}
.jc-fe[data-v-ff5fd616] {
  justify-content: flex-end;
}
.graph-download[data-v-ff5fd616] {
  cursor: pointer;
  height: 1.375rem;
}
.store-info.threegrid[data-v-ff5fd616] {
  grid-template-rows: 19.5625rem 26.25rem;
}

.headersection[data-v-ff5fd616] {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  padding: 0.8125rem 1.25rem;
  padding-bottom: 0;
}
.headersection[data-v-ff5fd616]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-ff5fd616]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.green[data-v-ff5fd616] {
  color: #12c519;
}
.black[data-v-ff5fd616] {
  margin-right: 0.3125rem;
  color: #323a52 !important;
}
.dark .black[data-v-ff5fd616] {
  color: #f6f7ff !important;
}
.itemssold[data-v-ff5fd616] {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.35rem;
  margin-left: 0.625rem;
}
.al-fe[data-v-ff5fd616] {
  align-items: flex-end;
}
.px-20[data-v-ff5fd616] {
  padding: 0 1.25rem;
}
.headers[data-v-ff5fd616] {
  margin-bottom: 0.875rem;
}
.bold[data-v-ff5fd616] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
}
.transaction-history[data-v-ff5fd616] {
  grid-row: span 2;
}
.type[data-v-ff5fd616]:not(:last-child) {
  margin-right: 1.5625rem;
}
.type[data-v-ff5fd616] {
  color: #abafd1;
  cursor: pointer;
}
.type.active[data-v-ff5fd616] {
  color: #323a52;
}
.dark .type[data-v-ff5fd616] {
  color: #5a5d74;
}
.dark .type.active[data-v-ff5fd616] {
  color: #f6f7ff;
}

.header[data-v-dcc7b0e3] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-dcc7b0e3] {
  cursor: pointer;
}
.row[data-v-dcc7b0e3] {
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.three-grid[data-v-dcc7b0e3] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.two-grid[data-v-dcc7b0e3] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.label[data-v-dcc7b0e3] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.jus-end[data-v-dcc7b0e3] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btns[data-v-dcc7b0e3] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-dcc7b0e3] {
  padding: 0 1.25rem;
}
.table-container[data-v-dcc7b0e3] {
  margin: 0 0.625rem;
}
.img-upload[data-v-dcc7b0e3] {
  background: #f6f7ff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.25rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.dark .img-upload[data-v-dcc7b0e3] {
  background-color: #353c53;
}
.img-upload[data-v-dcc7b0e3] {
  cursor: pointer;
}
.text[data-v-dcc7b0e3] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.grey-text[data-v-dcc7b0e3] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-top: 0.75rem;
}
.ticket[data-v-dcc7b0e3] {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  padding: 0.625rem;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}
.ticket[data-v-dcc7b0e3]:hover {
  background: #dfdfdf;
}
.ticket-img[data-v-dcc7b0e3] {
  max-width: 7rem;
  max-height: 7rem;
  width: 100%;
  overflow: hidden;
  object-fit: fill;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
  margin-right: 0.625rem;
}
.search-results[data-v-dcc7b0e3] {
  margin-top: 0.625rem;
  padding: 0 1.25rem;
  max-height: 40rem;
  overflow-y: auto;
}
.ticket-title[data-v-dcc7b0e3] {
  font-size: 1.125rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-description[data-v-dcc7b0e3] {
  font-size: 0.925rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-date[data-v-dcc7b0e3] {
  font-size: 0.9375rem;
  font-weight: 500;
}
.ticket-container[data-v-dcc7b0e3] {
  height: 100%;
}
.ticket-info[data-v-dcc7b0e3] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
}

.productWrapper[data-v-c558cc1e] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  border-radius: 0.625rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding-bottom: 1.0625rem;
  height: 100%;
}
.dark .productWrapper[data-v-c558cc1e] {
  background: #353c53;
}
.categoryBox[data-v-c558cc1e] {
  background-color: #7983d0;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 2.0625rem;
  text-align: center;
  color: #f6f7ff;
}
.dark .item[data-v-c558cc1e] {
  color: #3b435e;
}
.shoeImgnew[data-v-c558cc1e] {
  margin-top: 0.625rem;
  height: 3.9375rem;
  border-radius: 0.4375rem;
  margin-bottom: 0.625rem;
  -webkit-filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  /* width: 7.375rem;
  object-fit: fill; */
}
.prodTitle1[data-v-c558cc1e],
.heading[data-v-c558cc1e] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
}
.prodTitle1[data-v-c558cc1e] {
  margin-bottom: 0.3125rem;
}
.heading[data-v-c558cc1e] {
  font-weight: 400;
  margin-bottom: 0.625rem;
  text-transform: capitalize;
}
.sideDiv[data-v-c558cc1e] {
  text-align: start;
  width: 100%;

  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  padding: 0.625rem 1.25rem;
  margin-bottom: 2.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.newstyle span[data-v-c558cc1e],
.size span[data-v-c558cc1e],
.consignment span[data-v-c558cc1e] {
  font-weight: 600;
  margin-right: 0.625rem;
}
.newstyle[data-v-c558cc1e],
.size[data-v-c558cc1e],
.consignment[data-v-c558cc1e] {
  margin-bottom: 0.3pc;
}
.whiteBoxWrapnew[data-v-c558cc1e] {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 1.25rem;
}
.barcode[data-v-c558cc1e] {
  width: 100%;
}
.sneakers[data-v-c558cc1e] {
  background-color: #7983d0;  /* blue */
}
.streetwear[data-v-c558cc1e] {
  background-color: #79c1d0;  /* green */
}
.collectibles[data-v-c558cc1e] {
  background-color: #d07979;  /* brown */
}
.arts[data-v-c558cc1e] {
  background-color: #d079c8;  /* pink */
}
.electronics[data-v-c558cc1e] {
  background-color: #d0a879;  /* orange */
}
.watches[data-v-c558cc1e] {
  background-color: #aad079;  /* lightgreen */
}

.productWrapper[data-v-20f8c4c5] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  border-radius: 0.625rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding-bottom: 1.0625rem;
  height: 100%;
}
.dark .productWrapper[data-v-20f8c4c5] {
  background: #353c53;
}
.categoryBox[data-v-20f8c4c5] {
  background-color: #7983d0;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 2.0625rem;
  text-align: center;
  color: #f6f7ff;
}
.dark .item[data-v-20f8c4c5] {
  color: #3b435e;
}
.shoeImgnew[data-v-20f8c4c5] {
  margin-top: 0.625rem;
  height: 3.9375rem;
  border-radius: 0.4375rem;
  margin-bottom: 0.625rem;
  -webkit-filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  /* width: 7.375rem;
  object-fit: fill; */
}
.prodTitle1[data-v-20f8c4c5],
.heading[data-v-20f8c4c5] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
}
.prodTitle1[data-v-20f8c4c5] {
  margin-bottom: 0.3125rem;
}
.heading[data-v-20f8c4c5] {
  font-weight: 400;
  margin-bottom: 0.625rem;
  text-transform: capitalize;
}
.sideDiv[data-v-20f8c4c5] {
  text-align: start;
  width: 100%;

  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  padding: 0.625rem 1.25rem;
  margin-bottom: 2.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.newstyle span[data-v-20f8c4c5],
.size span[data-v-20f8c4c5],
.consignment span[data-v-20f8c4c5] {
  font-weight: 600;
  margin-right: 0.625rem;
}
.newstyle[data-v-20f8c4c5],
.size[data-v-20f8c4c5],
.consignment[data-v-20f8c4c5] {
  margin-bottom: 0.3pc;
}
.whiteBoxWrapnew[data-v-20f8c4c5] {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 1.25rem;
}
.barcode[data-v-20f8c4c5] {
  width: 100%;
}
.sneakers[data-v-20f8c4c5] {
  background-color: #7983d0;  /* blue */
}
.streetwear[data-v-20f8c4c5] {
  background-color: #79c1d0;  /* green */
}
.collectibles[data-v-20f8c4c5] {
  background-color: #d07979;  /* brown */
}
.arts[data-v-20f8c4c5] {
  background-color: #d079c8;  /* pink */
}
.electronics[data-v-20f8c4c5] {
  background-color: #d0a879;  /* orange */
}
.watches[data-v-20f8c4c5] {
  background-color: #aad079;  /* lightgreen */
}

.left-img[data-v-0d5d449f] {
  position: absolute;
  left: 0;
  top: 1rem;
  width: 35vw;
}
.right-img[data-v-0d5d449f] {
  position: absolute;
  bottom: 0;
  right: 1rem;
  width: 35vw;
}
.container[data-v-0d5d449f] {
  position: relative;
  z-index: 10;
  max-width: 32.5rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
}
.login-page[data-v-0d5d449f] {
  min-height: 100vh;
  z-index: 100;
  background-color: #f6f7ff;
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7ff;
}
.dark .login-page[data-v-0d5d449f] {
  background-color: #262c41;
}
.bg[data-v-0d5d449f] {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: #f6f7ff;
}
.dark .bg[data-v-0d5d449f] {
  background-color: #353c53;
}
.label[data-v-0d5d449f] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  margin-bottom: 1.5625rem;
}
.or[data-v-0d5d449f] {
  margin-top: 2.25rem;
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  text-align: center;
}
.mt[data-v-0d5d449f] {
  margin-top: 3.3125rem;
}
.logo[data-v-0d5d449f] {
  max-width: 22.75rem;
  margin: 0 auto;
  margin-bottom: 3.125rem;
}
.smtext[data-v-0d5d449f] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.checkbox[data-v-0d5d449f] {
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
}
.login-btn[data-v-0d5d449f] {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.8125rem;

  cursor: pointer;
  height: 5.25rem;
  width: 16.25rem;
  margin: 3.75rem auto 0 auto;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.dark .login-btn[data-v-0d5d449f] {
  background: #353c53;
}
.login-btn[data-v-0d5d449f]:hover {
  box-shadow: 0rem 0rem 0.875rem rgba(0, 0, 0, 0.25);
}
.login-btn svg[data-v-0d5d449f] {
  margin-right: 0.625rem;
}
.abs-center[data-v-0d5d449f] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.logout-page[data-v-0d5d449f] {
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7ff;
}
.dark .logout-page[data-v-0d5d449f] {
  background-color: #262c41;
}
.back-btn[data-v-0d5d449f] {
  cursor: pointer;
  position: relative;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
  margin-top: 1rem;
  font-size: 1.2rem;
}
.back-btn[data-v-0d5d449f]::after {
  position: absolute;
  bottom: -0.05rem;
  height: 1px;
  width: 100%;
  left: 0;
  content: "";
  background-color: #000;
}
.logged[data-v-0d5d449f] {
  font-size: 2rem;
}
.bold[data-v-0d5d449f] {
  cursor: pointer;
  text-decoration: underline;
}
.dn[data-v-0d5d449f] {
  display: none;
}
.left-img[data-v-69bab301] {
  position: absolute;
  left: 0;
  top: 1rem;
  width: 35vw;
}
.right-img[data-v-69bab301] {
  position: absolute;
  bottom: 0;
  right: 1rem;
  width: 35vw;
}
.container[data-v-69bab301] {
  position: relative;
  z-index: 10;
  max-width: 32.5rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
}
.login-page[data-v-69bab301] {
  min-height: 100vh;
  z-index: 100;
  background-color: #f6f7ff;
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7ff;
}
.dark .login-page[data-v-69bab301] {
  background-color: #262c41;
}
.bg[data-v-69bab301] {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: #f6f7ff;
}
.dark .bg[data-v-69bab301] {
  background-color: #353c53;
}
.label[data-v-69bab301] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  margin-bottom: 1.5625rem;
}
.or[data-v-69bab301] {
  margin-top: 2.25rem;
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  text-align: center;
}
.mt[data-v-69bab301] {
  margin-top: 3.3125rem;
}
.logo[data-v-69bab301] {
  max-width: 22.75rem;
  margin: 0 auto;
  margin-bottom: 3.125rem;
}
.smtext[data-v-69bab301] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.checkbox[data-v-69bab301] {
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
}
.login-btn[data-v-69bab301] {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.8125rem;

  cursor: pointer;
  height: 5.25rem;
  width: 16.25rem;
  margin: 3.75rem auto 0 auto;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.dark .login-btn[data-v-69bab301] {
  background: #353c53;
}
.login-btn[data-v-69bab301]:hover {
  box-shadow: 0rem 0rem 0.875rem rgba(0, 0, 0, 0.25);
}
.login-btn svg[data-v-69bab301] {
  margin-right: 0.625rem;
}
.abs-center[data-v-69bab301] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.logout-page[data-v-69bab301] {
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7ff;
}
.dark .logout-page[data-v-69bab301] {
  background-color: #262c41;
}
.back-btn[data-v-69bab301] {
  cursor: pointer;
  position: relative;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
  margin-top: 1rem;
  font-size: 1.2rem;
}
.back-btn[data-v-69bab301]::after {
  position: absolute;
  bottom: -0.05rem;
  height: 1px;
  width: 100%;
  left: 0;
  content: "";
  background-color: #000;
}
.logged[data-v-69bab301] {
  font-size: 2rem;
}
.bold[data-v-69bab301] {
  cursor: pointer;
  text-decoration: underline;
}
.dn[data-v-69bab301] {
  display: none;
}
.preview[data-v-94e904b3] {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}
.header[data-v-94e904b3] {
  padding: 1.25rem 2.25rem 0 1.25rem;
}
.sm_header[data-v-94e904b3] {
  top: 1.25rem;
  font-weight: 400;
  font-size: 1.125rem;
}
.order_id[data-v-94e904b3] {
  font-size: 1rem;
  font-weight: 300;
}
.cart[data-v-94e904b3] {
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex-basis: 49%;
}
.items[data-v-94e904b3] {
  padding: 0 0 0 1.25rem;
  padding-right: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 0;
  overflow-y: auto;
  padding-top: 0.5rem;
}
.item[data-v-94e904b3] {
  display: grid;
  grid-template-columns: 1fr 5rem;
  align-items: center;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 0.625rem 1.25rem 0.625rem 0.625rem;
  margin-bottom: 0.3125rem;
}
.btns svg[data-v-94e904b3] {
  cursor: pointer;
}
.amt[data-v-94e904b3] {
  margin: 0 1.25rem;
}
.dark .item[data-v-94e904b3] {
  background: #353c53;
}
.info[data-v-94e904b3] {
  font-weight: 300;
  font-size: 0.875rem;
}
.sub_total[data-v-94e904b3] {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  padding: 1rem;
  border-top: 1px solid #abafd1;
}
.bold[data-v-94e904b3] {
  font-weight: 600;
}
.total[data-v-94e904b3] {
  font-size: 1.5rem;
}
.details[data-v-94e904b3] {
  padding: 0 0 0 1.25rem;
}
.purple[data-v-94e904b3] {
  color: #767bfa;
  font-weight: 500;
}
.suggestion[data-v-94e904b3] {
  display: grid;
  flex-basis: 49%;
  grid-template-rows: auto auto 1fr;
}
.horizontal_scroll[data-v-94e904b3] {
  display: flex;
  min-width: 0;
  overflow-x: auto;
  flex-grow: 0;
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-bottom: 1rem;
}
.horizontal_scroll[data-v-94e904b3]::-webkit-scrollbar {
  height: 0.1875rem;
  cursor: pointer;
}
.rec_item[data-v-94e904b3] {
  box-shadow: 0rem 0rem 0.4375rem rgba(0, 0, 0, 0.15);
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  height: 15.625rem;
  min-width: 23.75rem;
  justify-content: space-between;
  padding: 1rem;
  padding-left: 0rem;
}
.rec_item[data-v-94e904b3]:not(:first-child) {
  margin-left: 1.25rem;
}
.dark .rec_item[data-v-94e904b3] {
  background: #353c53;
}
.shoeimg[data-v-94e904b3] {
  height: 9.0625rem;
}
.shoename[data-v-94e904b3] {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.625rem;
  line-height: 1.875rem;
}
.svg[data-v-94e904b3] {
  float: right;
  margin-top: 1rem;
}
.model[data-v-94e904b3] {
  font-size: 1.125rem;
  font-weight: 300;
  max-width: 18ch;
}
.more[data-v-94e904b3] {
  padding-right: 1rem;
}
.more_container[data-v-94e904b3] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 1rem;
}
.dark .more_container[data-v-94e904b3] {
  background: #353c53;
}
.showitem[data-v-94e904b3] {
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
  text-align: center;
}
.item_heaeder[data-v-94e904b3] {
  margin-bottom: 0.75rem;
}
.custom[data-v-94e904b3] {
  padding: 0.5rem 0;
}
.pl-0[data-v-94e904b3] {
  padding-left: 0 !important;
}
.border[data-v-94e904b3] {
  width: 100%;
  border: 0.125rem solid #767bfa;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  margin: 0.75rem 0;
}
.w-100[data-v-94e904b3] {
  width: 100%;
  padding: 0 1rem;
}
.jc-fe[data-v-94e904b3] {
  justify-content: flex-end;
}
.text[data-v-94e904b3] {
  margin-left: 0.625rem;
}

.summary[data-v-8a4c4bce] {
  display: grid;
  grid-gap: 1.875rem 2.5rem;
  grid-template-rows: 19.5625rem 26.25rem;
  grid-template-columns: 1fr 1fr 1fr;
}
.headersection[data-v-8a4c4bce] {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  padding: 0.8125rem 1.25rem;
  padding-bottom: 0;
}
.headersection[data-v-8a4c4bce]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-8a4c4bce]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.box[data-v-8a4c4bce] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
}
.receipt-container[data-v-8a4c4bce] {
  padding: 0.8125rem 0;
}
.dark .box[data-v-8a4c4bce] {
  background: #353c53;
}
.cashout[data-v-8a4c4bce] {
  grid-column: 3/4;
  grid-row: 1/3;
}
.bold[data-v-8a4c4bce] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  color: #abafd1;
}
.value[data-v-8a4c4bce] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;

  margin-left: 0.3125rem;
}
.span2[data-v-8a4c4bce] {
  grid-column: 1/3;
}
.total[data-v-8a4c4bce] {
  padding-right: 0.875rem;
}
.summary-table[data-v-8a4c4bce] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgb(0 0 0 / 15%);
  border-radius: 0.9375rem;
  padding: 0.8125rem 0;
}
.dark .summary-table[data-v-8a4c4bce] {
  background: #353c53;
}
.purple[data-v-8a4c4bce] {
  font-weight: 500;
  color: #767bfa;
}
.query[data-v-8a4c4bce]:not(:last-child) {
  margin-right: 2.5rem;
}
.query[data-v-8a4c4bce] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.smtext[data-v-8a4c4bce] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.queryvalue[data-v-8a4c4bce] {
  font-weight: 300;

  margin-left: 0.625rem;
}
.green[data-v-8a4c4bce] {
  color: #12c519;
}
.product-items[data-v-8a4c4bce] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: 0.625rem;
}
.product-container[data-v-8a4c4bce] {
  padding-top: 0.625rem;
  max-height: calc(100vh - 10rem);
  overflow-y: scroll;
  padding-right: 1.25rem !important;
  margin-right: 1.25rem;
  padding-bottom: 1.25rem;
}
.pos[data-v-8a4c4bce] {
  display: grid;
  grid-template-columns: 1fr 42.375rem;
  min-height: 50rem;
}
.cart[data-v-8a4c4bce] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.dark .cart[data-v-8a4c4bce] {
  background: #353c53;
}
.pt-0[data-v-8a4c4bce] {
  padding-top: 0 !important;
}
.note[data-v-8a4c4bce] {
  font-weight: 300;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  text-align: center;
  margin-left: 0.425rem;
  color: #767bfa;
}
.headersection[data-v-8a4c4bce] {
  display: flex;
}
.headersection > span[data-v-8a4c4bce]:first-child {
  white-space: nowrap;
}
.amt[data-v-8a4c4bce] {
  font-weight: 300;
  font-size: 1.875rem;
  line-height: 2.8125rem;
}
.subText[data-v-8a4c4bce] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.btn[data-v-8a4c4bce] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  color: #12c519;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.125rem rgba(0, 0, 0, 0.35);
  border-radius: 0.3125rem;
  width: -webkit-max-content;
  width: max-content;
  max-width: 12.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  margin: auto;
  margin-top: 1.6875rem;
  height: 3.125rem;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.walletDetails[data-v-8a4c4bce] {
  padding: 0 3rem;
  padding-top: 0.625rem;
}
.btn[data-v-8a4c4bce]:hover {
  background: #e5e6f2;
}
@media (max-width: 115.625rem) {
.pos[data-v-8a4c4bce] {
    grid-template-columns: 1fr 35rem !important;
}
}
.black[data-v-8a4c4bce] {
  margin-right: 0.3125rem;
  color: #323a52 !important;
}
.dark .black[data-v-8a4c4bce] {
  color: #f6f7ff !important;
}
.itemssold[data-v-8a4c4bce] {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.35rem;
  margin-left: 0.625rem;
}
.al-fe[data-v-8a4c4bce] {
  align-items: flex-end;
}
.px-20[data-v-8a4c4bce] {
  padding: 0 1.25rem;
}
.headers[data-v-8a4c4bce] {
  margin-bottom: 0.875rem;
}

.headersgrid[data-v-9912b825],
.item[data-v-9912b825] {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr 1.875rem;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-9912b825] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.cashoutgrid .headersgrid[data-v-9912b825],
.cashoutgrid .item[data-v-9912b825] {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.item[data-v-9912b825] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding: 0.8125rem 0;
  padding-left: 1.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  width: calc(100% - 1.875rem);
  margin: auto;
  margin-bottom: 0.5rem;
}
.dark .item[data-v-9912b825] {
  background: #3b435e;
}
.item[data-v-9912b825]:hover {
  border-color: #767bfa;
}
.item div[data-v-9912b825],
.headersgrid div[data-v-9912b825] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recentActivities .headersgrid[data-v-9912b825],
.recentActivities .item[data-v-9912b825] {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.items[data-v-9912b825] {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
  padding: 0.5rem 0;
}
.walletPage .box[data-v-9912b825],
.cashoutgrid[data-v-9912b825],
.bankAccounts[data-v-9912b825],
.recentActivities[data-v-9912b825] {
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.activitiesImg[data-v-9912b825] {
  margin-left: auto;
  justify-self: flex-end;
  padding-right: 1rem;
}
.activitiesImg img[data-v-9912b825]:first-child {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.headersgrid[data-v-fea4f1ff],
.item[data-v-fea4f1ff] {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr 1.875rem;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-fea4f1ff] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.cashoutgrid .headersgrid[data-v-fea4f1ff],
.cashoutgrid .item[data-v-fea4f1ff] {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.item[data-v-fea4f1ff] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding: 0.8125rem 0;
  padding-left: 1.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  width: calc(100% - 1.875rem);
  margin: auto;
  margin-bottom: 0.5rem;
}
.dark .item[data-v-fea4f1ff] {
  background: #3b435e;
}
.item[data-v-fea4f1ff]:hover {
  border-color: #767bfa;
}
.item div[data-v-fea4f1ff],
.headersgrid div[data-v-fea4f1ff] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recentActivities .headersgrid[data-v-fea4f1ff],
.recentActivities .item[data-v-fea4f1ff] {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.items[data-v-fea4f1ff] {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
  padding: 0.5rem 0;
}
.walletPage .box[data-v-fea4f1ff],
.cashoutgrid[data-v-fea4f1ff],
.bankAccounts[data-v-fea4f1ff],
.recentActivities[data-v-fea4f1ff] {
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.activitiesImg[data-v-fea4f1ff] {
  margin-left: auto;
  justify-self: flex-end;
  padding-right: 1rem;
}
.activitiesImg img[data-v-fea4f1ff]:first-child {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.headersgrid[data-v-a0fc12d6],
.item[data-v-a0fc12d6] {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr 1.875rem;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-a0fc12d6] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.cashoutgrid .headersgrid[data-v-a0fc12d6],
.cashoutgrid .item[data-v-a0fc12d6] {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.item[data-v-a0fc12d6] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding: 0.8125rem 0;
  padding-left: 1.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  width: calc(100% - 1.875rem);
  margin: auto;
  margin-bottom: 0.5rem;
}
.dark .item[data-v-a0fc12d6] {
  background: #3b435e;
}
.item[data-v-a0fc12d6]:hover {
  border-color: #767bfa;
}
.item div[data-v-a0fc12d6],
.headersgrid div[data-v-a0fc12d6] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recentActivities .headersgrid[data-v-a0fc12d6],
.recentActivities .item[data-v-a0fc12d6] {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.items[data-v-a0fc12d6] {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
  padding: 0.5rem 0;
}
.walletPage .box[data-v-a0fc12d6],
.cashoutgrid[data-v-a0fc12d6],
.bankAccounts[data-v-a0fc12d6],
.recentActivities[data-v-a0fc12d6] {
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.activitiesImg[data-v-a0fc12d6] {
  margin-left: auto;
  justify-self: flex-end;
  padding-right: 1rem;
}
.activitiesImg img[data-v-a0fc12d6]:first-child {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.headersgrid[data-v-8a4c4bce],
.item[data-v-8a4c4bce] {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr 1.875rem;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-8a4c4bce] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.cashoutgrid .headersgrid[data-v-8a4c4bce],
.cashoutgrid .item[data-v-8a4c4bce] {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.item[data-v-8a4c4bce] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding: 0.8125rem 0;
  padding-left: 1.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  width: calc(100% - 1.875rem);
  margin: auto;
  margin-bottom: 0.5rem;
}
.dark .item[data-v-8a4c4bce] {
  background: #3b435e;
}
.item[data-v-8a4c4bce]:hover {
  border-color: #767bfa;
}
.item div[data-v-8a4c4bce],
.headersgrid div[data-v-8a4c4bce] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recentActivities .headersgrid[data-v-8a4c4bce],
.recentActivities .item[data-v-8a4c4bce] {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.items[data-v-8a4c4bce] {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
  padding: 0.5rem 0;
}
.walletPage .box[data-v-8a4c4bce],
.cashoutgrid[data-v-8a4c4bce],
.bankAccounts[data-v-8a4c4bce],
.recentActivities[data-v-8a4c4bce] {
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.activitiesImg[data-v-8a4c4bce] {
  margin-left: auto;
  justify-self: flex-end;
  padding-right: 1rem;
}
.activitiesImg img[data-v-8a4c4bce]:first-child {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.header[data-v-112f8414] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-112f8414] {
  cursor: pointer;
}
.input[data-v-112f8414] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-112f8414] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-112f8414] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-112f8414] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-112f8414] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-112f8414] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-112f8414] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-112f8414] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-112f8414] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-112f8414] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-112f8414] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-112f8414] {
  font-size: 1.125rem;
}
.readOnly[data-v-112f8414] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-112f8414] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-112f8414] {
  grid-column: span 2;
}
.breakup[data-v-112f8414] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-112f8414] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-112f8414] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-112f8414] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-112f8414] {
  justify-content: space-evenly;
}
.jc-sb[data-v-112f8414] {
  justify-content: space-between;
}
.btns[data-v-112f8414] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-112f8414] {
  padding: 0 1.25rem;
}
.pt-10[data-v-112f8414] {
  padding-top: 0.625rem;
}
.table-container[data-v-112f8414] {
  margin: 0 0.625rem;
}
.span-2[data-v-112f8414] {
  grid-column: 2/4;
}
.green[data-v-112f8414] {
  color: #12c519;
}
.mb-20[data-v-112f8414] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-112f8414] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-112f8414] {
  padding: 0 1.25rem;
}
.lg-header[data-v-112f8414] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-112f8414] {
  cursor: pointer;
}
.search-input-cm[data-v-112f8414] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-112f8414] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-112f8414],
.sm-item-grid[data-v-112f8414] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-112f8414] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-112f8414] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-112f8414] {
  background: #3b435e;
}
.sm-items[data-v-112f8414] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-112f8414] {
  background: #3b435e;
}
.col-grid[data-v-112f8414] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-112f8414] {
  font-weight: 500;
}
.list-item[data-v-112f8414] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-112f8414] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-112f8414] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-112f8414] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-112f8414] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

.popupbg[data-v-78fea42f] {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup[data-v-78fea42f] {
  background-color: #fff;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
  padding: 1.25rem 1.5rem;
}
.text[data-v-78fea42f] {
  color: #767bfa;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.btn[data-v-78fea42f] {
  background-color: #767bfa;
  color: #fff;
  border: none;
  border-radius: 0.625rem;
  padding: 0.425rem 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 1.25rem;
  margin-bottom: 0.725rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}

.header[data-v-3484128c] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-3484128c] {
  cursor: pointer;
}
.input[data-v-3484128c] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-3484128c] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-3484128c] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-3484128c] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-3484128c] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-3484128c] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-3484128c] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-3484128c] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-3484128c] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.saved-alert[data-v-3484128c] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-3484128c] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-3484128c] {
  font-size: 1.125rem;
}
.readOnly[data-v-3484128c] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-3484128c] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-3484128c] {
  grid-column: span 2;
}
.breakup[data-v-3484128c] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-3484128c] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-3484128c] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-3484128c] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-3484128c] {
  justify-content: space-evenly;
}
.jc-sb[data-v-3484128c] {
  justify-content: space-between;
}
.btns[data-v-3484128c] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-3484128c] {
  padding: 0 1.25rem;
}
.pt-10[data-v-3484128c] {
  padding-top: 0.625rem;
}
.table-container[data-v-3484128c] {
  margin: 0 0.625rem;
}
.span-2[data-v-3484128c] {
  grid-column: 2/4;
}
.green[data-v-3484128c] {
  color: #12c519;
}
.mb-20[data-v-3484128c] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-3484128c] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-3484128c] {
  padding: 0 1.25rem;
}
.lg-header[data-v-3484128c] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-3484128c] {
  cursor: pointer;
}
.search-input-cm[data-v-3484128c] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-3484128c] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-3484128c],
.sm-item-grid[data-v-3484128c] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-3484128c] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-3484128c] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-3484128c] {
  background: #3b435e;
}
.sm-items[data-v-3484128c] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-3484128c] {
  background: #3b435e;
}
.col-grid[data-v-3484128c] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-3484128c] {
  font-weight: 500;
}
.list-item[data-v-3484128c] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-3484128c] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-3484128c] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.btn-container[data-v-3484128c] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-3484128c] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

