.grid[data-v-ee615f23] {
  display: grid;
  grid-template-columns: 1fr 1fr 0.8fr;
  grid-gap: 1.875rem;
}
.grid[data-v-ee615f23] {
  padding-right: 1.5rem;
  margin-bottom: 1rem;
}
.full[data-v-ee615f23] {
  grid-column: 1/3;
}
.rect[data-v-ee615f23] {
  height: 8.4375rem;
  margin-bottom: 0;
}
.smbox[data-v-ee615f23] {
  max-height: none;
  grid-gap: 0rem 2rem;
}
.as-fe[data-v-ee615f23] {
  align-self: flex-end;
}
.color-container input[data-v-ee615f23] {
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.color-container[data-v-ee615f23] {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.2rem;
  padding-right: 0.9375rem;
  width: 14.0625rem;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border: none;
  outline: none;
  height: 3.1875rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  position: relative;
}
