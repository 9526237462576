.items[data-v-b002efe3] {
  padding: 0 1rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
}
.items-container[data-v-b002efe3] {
  overflow-y: scroll;
  margin-right: 0.1875rem;
  max-height: 14rem;
  padding-bottom: 0.5rem;
}
.headersgrid[data-v-b002efe3],
.item[data-v-b002efe3] {
  display: grid;
  grid-template-columns: 1fr 1.2fr 2fr;
  align-items: center;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-b002efe3] {
  display: grid;
  grid-template-columns: 1fr 1.2fr 2.05fr;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-b002efe3] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.item[data-v-b002efe3] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding: 0.8125rem 0;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
}
.dark .item[data-v-b002efe3] {
  background: #3b435e;
}
.item[data-v-b002efe3]:hover {
  border-color: #767bfa;
}
.item div[data-v-b002efe3],
.headersgrid div[data-v-b002efe3] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.consignment[data-v-b002efe3] {
  grid-template-columns: 1.5fr 1fr 1fr !important;
  grid-gap: 0.375rem;
}
.headersgrid.consignment[data-v-b002efe3] {
  grid-template-columns: 1.5fr 1fr 1.1fr !important;
  grid-gap: 0.375rem;
}
.type[data-v-b002efe3]::after {
  position: absolute;
  border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  content: "";
  top: 0%;
  left: 0%;
  height: 100%;
  display: grid;
  max-width: 8.125rem;
  width: 100%;
  z-index: -1;
  -webkit-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  -moz-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  -ms-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
  -o-border-radius: 0.4375rem 0rem 0rem 0.4375rem;
}
.type[data-v-b002efe3] {
  position: relative;
  color: #fff;
  z-index: 1;
  height: 100%;
  align-items: center;
  display: flex;
  max-width: 8.125rem;
  text-align: center;
}
.bold[data-v-b002efe3] {
  font-weight: 600;
}
.consignment.item[data-v-b002efe3] {
  padding: 0 0.625rem 0 0rem !important;
  height: 3.125rem;
  margin-bottom: 0;
}
.item span[data-v-b002efe3] {
  width: 100%;
  text-align: center;
}
.blue.type[data-v-b002efe3]::after {
  background-color: #7983d0;
}
.green.type[data-v-b002efe3]::after {
  background-color: #79c1d0;
}
.orange.type[data-v-b002efe3]::after {
  background-color: #d0a879;
}
.pink.type[data-v-b002efe3]::after {
  background-color: #d079c8;
}
.lightbrown[data-v-b002efe3]::after {
  background-color: #d0bd79;
}
.brown[data-v-b002efe3]::after {
  background-color: #d07979;
}
.lightblue[data-v-b002efe3]::after {
  background-color: #79c1d0;
}
.lightgreen[data-v-b002efe3]::after {
  background-color: #aad079;
}
.headersgrid.consignment.categories[data-v-b002efe3] {
  grid-template-columns: 1.35fr 1fr 1.1fr !important;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}
