.topbar[data-v-cc629abd] {
  display: flex;
  justify-content: space-between;
  padding: 1.4375rem 0;
  position: relative;
}
.pagename[data-v-cc629abd] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  display: flex;
  align-items: center;
}
.user[data-v-cc629abd] {
  display: flex;
  align-items: center;
}
.userimg[data-v-cc629abd] {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.userimg img[data-v-cc629abd] {
  width: 100%;
  height: 100%;
}
.username[data-v-cc629abd] {
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-left: 0.625rem;
}
.sm-link[data-v-cc629abd] {
  font-weight: 600;
  font-size: 0.9375rem;
  display: inline-block;
  cursor: pointer;
  line-height: 1.375rem;
  color: #abafd1;
}
.dark .sm-link[data-v-cc629abd] {
  color: #5a5d74;
}
.sm-link[data-v-cc629abd]:not(:last-child) {
  margin-right: 1.25rem;
}
.sm-links[data-v-cc629abd] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.active-link[data-v-cc629abd] {
  color: #323a52;
}
.dark .active-link[data-v-cc629abd] {
  color: #f6f7ff;
}
.logoutbtn[data-v-cc629abd] {
  /* padding: 1.5625rem 2.525rem; */
}
.circle[data-v-cc629abd] {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #4b4e93;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
